import { USER_ROLES } from "./consts.generated";
// import {
//     ILoginPayload, ITokenReloadPayload,
//     MSG_TYPES, publish, subscribe, publishAndWait
// } from "./pub_sub";
import { is_admin, is_power, is_short, is_logged_in, username, token, title, user_data } from "./stores";
const STORAGE_JWT = "JwtToken";
export class AuthService {
    constructor(backend) {
        this.backend = backend;
        username.set("");
        title.set("");
        is_admin.set(false);
        is_power.set(false);
        is_short.set(false);
        is_logged_in.set(false);
        this.checkAutoLogin()
            .catch(err => console.log(err));
    }
    async impersonate(user_uuid) {
        try {
            console.log("Impersonation for user " + user_uuid);
            const res = await this.backend.admin().impersonateUser({ user_uuid });
            console.log("Impersonation successful, logging out...");
            this.logout();
            console.log("Impersonation successful, setting data of impersonated user...");
            localStorage.setItem(STORAGE_JWT, res.token);
            token.set(res.token);
            is_admin.set(res.is_admin);
            is_short.set(res.roles.includes(USER_ROLES.Short));
            is_power.set(res.roles.includes(USER_ROLES.Power));
            title.set(res.title);
            username.set(res.username);
            is_logged_in.set(true);
            const res2 = await this.backend.users().getCurrentUserData({});
            user_data.set(res2);
            return null;
        }
        catch (e) {
            console.warn("Error during impersonation", e);
            return e.message;
        }
    }
    async login(u, p) {
        try {
            console.log("Login in progress for user " + u);
            const res = await this.backend.users().loginUP({ u, p });
            console.log("Login successful, setting data...");
            localStorage.setItem(STORAGE_JWT, res.token);
            token.set(res.token);
            is_admin.set(res.is_admin);
            is_short.set(res.roles.includes(USER_ROLES.Short));
            is_power.set(res.roles.includes(USER_ROLES.Power));
            title.set(res.title);
            username.set(res.username);
            is_logged_in.set(true);
            const res2 = await this.backend.users().getCurrentUserData({});
            user_data.set(res2);
            return null;
        }
        catch (e) {
            console.warn("Error during login", e);
            return e.message;
        }
    }
    logout() {
        is_logged_in.set(false);
        is_admin.set(false);
        is_power.set(false);
        is_short.set(false);
        title.set("");
        username.set("");
        user_data.set(null);
        localStorage.removeItem(STORAGE_JWT);
    }
    async checkAutoLogin() {
        const curr_hash = window.location.hash;
        const token_string = localStorage.getItem(STORAGE_JWT);
        if (token_string != null) {
            try {
                console.log("Testing existing token...");
                const res = await this.backend.users().validateToken({ token: token_string });
                if (res.success) {
                    console.log("Existing token ok.");
                    token.set(token_string);
                    const res2 = await this.backend.users().getCurrentUserData({});
                    is_admin.set(res2.is_admin);
                    is_short.set(res2.roles.includes(USER_ROLES.Short));
                    is_power.set(res2.roles.includes(USER_ROLES.Power));
                    title.set(res2.title);
                    username.set(res2.username);
                    user_data.set(res2);
                    is_logged_in.set(true);
                    localStorage.setItem(STORAGE_JWT, token_string);
                    // redirect back to originating page
                    window.location.hash = curr_hash;
                }
                else {
                    console.log("Existing token invalid.");
                    localStorage.removeItem(STORAGE_JWT);
                }
            }
            catch (err) {
                console.error("Existing token NOT invalid.");
                console.error(err);
                this.logout();
            }
        }
        else {
            console.log("No existing token.");
        }
    }
}
