<script lang="ts">
    import { onMount, beforeUpdate } from "svelte";
    import type { IServices } from "./code/services";

    export let services: IServices;
    export let uuid: string | null;
    export let title: string = "";

    let visible: boolean = false;
    let status: string = "";
    let last_uuid: string = "";

    onMount(async () => {});
    beforeUpdate(async () => {
        if (last_uuid != uuid) {
            last_uuid = uuid;
            singleLoop().catch((err) => console.error(err));
        }
    });

    async function singleLoop() {
        const res = await services.backend.admin().getJobStatus({ uuid });
        if (res.found) {
            visible = res.status != "finished";
            status = res.msg;
            if (res.status == "running") {
                // schedule another loop
                setTimeout(() => {
                    singleLoop().catch((err) => console.error(err));
                }, 1000);
            }
        } else {
            visible = false;
        }
    }
</script>

{#if visible}
    <div class="w3-panel w3-blue">
        <h2>{title}</h2>
        <p>{status}</p>
    </div>
{/if}
