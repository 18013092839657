<script lang="ts">
    import { onMount, beforeUpdate } from "svelte";
    import type { IServices } from "./code/services";

    import { UrlHelper, validateEmail } from "./code/utils";
    import Loader from "./Loader.svelte";
    import type {
        IRoomData,
        IGetRegistrationByTokenRes,
        IUpdateRegistrationByTokenReq,
        IGetCaptchaRes,
    } from "./code/backend/nonauthenticated.generated.types";

    export let services: IServices;
    export const page_params: any = {};

    let token: string = "";
    let orig_data: IGetRegistrationByTokenRes | null = null;
    let user: IUpdateRegistrationByTokenReq = {
        addr: "",
        captcha_token: "",
        captcha_value: "",
        email: "",
        gsm: "",
        room_ids: [],
        tax_id: "",
        title: "",
        token: "",
        town: "",
        zip: "",
    };
    let rooms: IRoomData[] = [];
    let captcha: IGetCaptchaRes | null = null;

    let is_loading: boolean = false;

    let is_error: boolean = false;
    let error_msg: string = "";
    let is_success: boolean = false;

    async function load() {
        try {
            is_error = false;
            const offices = await services.backend
                .nonauthenticated()
                .getOffices({});
            rooms = offices.rooms;

            orig_data = await services.backend
                .nonauthenticated()
                .getRegistrationByToken({ token });
            captcha = await services.backend.nonauthenticated().getCaptcha({});

            user.addr = orig_data.addr;
            user.email = orig_data.email;
            user.gsm = orig_data.gsm;
            user.tax_id = orig_data.tax_id;
            user.title = orig_data.title;
            user.token = token;
            user.town = orig_data.town;
            user.zip = orig_data.zip;
            user.captcha_token = captcha.token;
        } catch (err) {
            is_error = true;
            error_msg = err.message;
        }
    }
    onMount(async () => {
        token = UrlHelper.getUrlParams().token ?? "NOTSET";
        await load();
    });
    beforeUpdate(async () => {
        // do nothing
    });
    async function saveData(): Promise<void> {
        try {
            is_error = false;
            error_msg = "";
            if (!validateData()) {
                return;
            }
            await services.backend
                .nonauthenticated()
                .updateRegistrationByToken(user);
            is_success = true;
            await load();
        } catch (err) {
            is_error = true;
            error_msg = err.message;
        }
    }
    function validateData(): boolean {
        const reportError = (msg: string) => {
            error_msg = msg;
            is_error = true;
            return false;
        };
        user.title == (user.title ?? "").trim();
        user.email == (user.email ?? "").trim();
        user.addr == (user.addr ?? "").trim();
        user.town == (user.town ?? "").trim();
        user.zip == (user.zip ?? "").trim();
        user.gsm == (user.gsm ?? "").trim();
        user.tax_id == (user.tax_id ?? "").trim();

        if (user.title.length == 0) {
            return reportError("Naziv ne sme biti prazen.");
        }
        if (!validateEmail(user.email)) {
            return reportError("Email ni v pravi obliki: " + user.email);
        }

        return true;
    }
</script>

<div class="w3-container">
    <div class="w3-row">
        {#if is_success}
            <div class="w3-panel w3-pale-green w3-border">
                <h3>Vaši podatki so bili uspešno shranjeni</h3>
                <p>
                    Na elektronski naslov <b>{user.email}</b> je bilo poslano sporočilo
                    z navodili za potrditev vaše prijave. S tem korakom preverjamo
                    pravilnost vnešenega elektronskega naslova.
                </p>
                <p>
                    Po uspešni potrditvi vas bomo vnesli v naš sistem in vam
                    poslali pogodbo za najem naših poslovnih prostorov.
                </p>
                <p>
                    Zahvaljujemo se vam za zaupanje in se veselimo bodočega
                    sodelovanja.
                </p>
                <p>
                    <a href={UrlHelper.getPageUrl("home")}>Nazaj</a>
                </p>
            </div>
        {/if}
        {#if orig_data == null}
            {#if is_error}
                <div class="w3-panel w3-pale-yellow w3-border">
                    <h3>Napaka:</h3>
                    <p>{error_msg}</p>
                </div>
            {/if}
        {/if}
        {#if !is_success && orig_data != null}
            {#if is_error}
                <div class="w3-panel w3-pale-yellow w3-border">
                    <h3>Opozorilo:</h3>
                    <p>{error_msg}</p>
                </div>
            {/if}
            <div class="w3-center">
                <Loader visible={!is_error && is_loading} />
            </div>
            {#if !is_error && !is_loading}
                <div class="w3-panel w3-border w3-round">
                    <h3>Registracija</h3>
                    <p>
                        Izpolnite podatke na spodnjem obrazcu. Po uspešni
                        potrditvi vam poslali pogodbo ter ostale napotke za
                        najem naših poslovnih prostorov.
                    </p>
                </div>
                <form class="w3-container">
                    <div class="w3-panel">
                        <label for="inTitle">Naziv</label>
                        <input
                            id="inTitle"
                            class="w3-input w3-border w3-round"
                            type="text"
                            bind:value={user.title}
                        />
                    </div>

                    <div class="w3-panel">
                        <label for="inEmail">Email</label>
                        <input
                            id="inEmail"
                            class="w3-input w3-border w3-round"
                            type="email"
                            bind:value={user.email}
                        />
                    </div>

                    <div class="w3-panel">
                        <label for="inEmail">GSM</label>
                        <input
                            id="inEmail"
                            class="w3-input w3-border w3-round"
                            type="email"
                            bind:value={user.gsm}
                        />
                    </div>

                    <div class="w3-panel">
                        <label for="inTaxId"
                            >Davčna št. (samo za pravne osebe)</label
                        >
                        <input
                            id="inTaxId"
                            class="w3-input w3-border w3-round"
                            type="text"
                            bind:value={user.tax_id}
                        />
                    </div>

                    <div class="w3-panel">
                        <label for="inAddress">Ulica</label>
                        <input
                            id="inAddress"
                            class="w3-input w3-border w3-round"
                            type="text"
                            bind:value={user.addr}
                        />
                    </div>

                    <div class="w3-panel">
                        <label for="inZip">Pošta</label>
                        <input
                            id="inZip"
                            class="w3-input w3-border w3-round"
                            type="text"
                            bind:value={user.zip}
                        />
                    </div>

                    <div class="w3-panel">
                        <label for="inTown">Mesto</label>
                        <input
                            id="inTown"
                            class="w3-input w3-border w3-round"
                            type="text"
                            bind:value={user.town}
                        />
                    </div>

                    <div class="w3-panel">
                        <h3>Dostop do pisarn</h3>
                    </div>

                    <!-- <div class="w3-panel">
                        <label for="inOffice"
                            >Izberite pisarno, ki želite najemati</label
                        >

                        <select
                            id="inOffice"
                            class="w3-select w3-border w3-round"
                            bind:value={user.room_id}
                        >
                            {#each rooms as room}
                                <option value={room.code}>{room.title}</option>
                            {/each}
                        </select>
                    </div> -->

                    <div class="w3-panel">
                        <div>
                            <img
                                class="generated-captcha"
                                src={captcha?.image ?? ""}
                                alt="captcha"
                            />
                        </div>
                        <label for="inCaptha"
                            >Prepišite besedilo iz zgornje slike:</label
                        >
                        <input
                            id="inCaptha"
                            class="w3-input w3-border w3-round"
                            type="text"
                            bind:value={user.captcha_value}
                        />
                    </div>
                </form>
                <div class="w3-panel w3-right-align">
                    <button
                        class="w3-btn w3-theme-action w3-round-xlarge"
                        on:click={() => saveData()}>Shrani podatke</button
                    >
                </div>
            {/if}
        {/if}
    </div>
</div>
