import { writable } from "svelte/store";
export const is_admin = writable(false);
export const is_power = writable(false);
export const is_short = writable(false);
export const is_logged_in = writable(false);
export const token = writable("");
export const username = writable("");
export const title = writable("");
export const user_data = writable(null);
/** Flag if wide-screens should be used */
export const enable_wide_gui = writable(false);
const LOCAL_STORAGE_ENABLE_WIDE = "gui.enable_wide_display";
export function initStores() {
    // read initial value from local storage
    const initial_value = (localStorage.getItem(LOCAL_STORAGE_ENABLE_WIDE) == "true");
    enable_wide_gui.set(initial_value);
    // set listener that will store the value back
    enable_wide_gui.subscribe(val => {
        localStorage.setItem(LOCAL_STORAGE_ENABLE_WIDE, "" + val);
    });
}
