<script lang="ts">
    import { onMount } from "svelte";
    import type { IServices } from "./code/services";
    import { niceDateTime, UrlHelper, validateEmail } from "./code/utils";

    import type { IRegistrationRec } from "./code/backend/admin.generated.types";
    import Loader from "./Loader.svelte";

    export let services: IServices;
    export const page_params: any = {};

    let registrations: IRegistrationRec[] = [];
    let is_loading: boolean = false;
    let only_active: boolean = true;
    let registrations_filtered: IRegistrationRec[] = [];

    let is_inserting: boolean = false;
    let new_title: string = "";
    let new_email: string = "";
    let new_gsm: string = "";

    async function load() {
        is_loading = true;
        registrations = (
            await services.backend.admin().searchRegistrations({})
        ).registrations.sort((a, b) => a.date_created - b.date_created);
        is_loading = false;
        filter();
    }
    function filter(): void {
        let res = registrations;
        if (only_active) {
            res = res.filter((x) => x.is_active);
        }
        registrations_filtered = res;
    }
    onMount(async () => {
        await load();
    });
    function getStatusClass(status: string): string {
        status = status.trim();
        if (status == "waiting_confirmation") return "w3-blue-lite";
        if (status == "waiting_data") return "w3-orange-lite";
        return "w3-green-lite";
    }
    function getStatusText(status: string): string {
        status = status.trim();
        if (status == "waiting_confirmation") return "Čaka dokumente";
        if (status == "waiting_data") return "Čaka podatke";
        return "Končano";
    }
    function startInserting(): void {
        is_inserting = true;
    }
    function cancelInserting(): void {
        is_inserting = false;
    }
    async function finishInserting(): Promise<void> {
        if (!validateEmail(new_email)) {
            alert("Email ni v pravi obliki");
            return;
        }
        is_loading = false;
        try {
            await services.backend.admin().newRegistration({
                email: new_email,
                gsm: new_gsm,
                title: new_title,
            });
        } finally {
            is_loading = false;
        }

        new_title = "";
        new_email = "";
        new_gsm = "";
        is_inserting = false;

        await load();
    }
</script>

<div class="w3-container">
    <div class="w3-row">
        <button class="w3-button-link" on:click={() => startInserting()}>
            Nov kandidat
        </button>
        <label class="switch w3-hide-small">
            <input
                type="checkbox"
                bind:checked={only_active}
                on:change={() => filter()}
            />
            <span class="slider round" />
        </label>
        <span class="w3-hide-small">Samo aktivni</span>
    </div>
    {#if is_inserting}
        <div class="w3-row">
            <form class="w3-container">
                <div class="w3-panel">
                    <label for="inTitle">Naziv</label>
                    <input
                        id="inTitle"
                        class="w3-input w3-border w3-round"
                        type="text"
                        bind:value={new_title}
                    />
                </div>

                <div class="w3-panel">
                    <label for="inEmail">Email</label>
                    <input
                        id="inEmail"
                        class="w3-input w3-border w3-round"
                        type="email"
                        bind:value={new_email}
                    />
                </div>

                <div class="w3-panel">
                    <label for="inEmail">GSM</label>
                    <input
                        id="inEmail"
                        class="w3-input w3-border w3-round"
                        type="email"
                        bind:value={new_gsm}
                    />
                </div>
            </form>
            <div class="w3-panel w3-right-align">
                <button
                    class="w3-btn w3-theme-action w3-round-xlarge"
                    on:click={() => finishInserting()}>Shrani</button
                >
                <button
                    class="w3-btn w3-round-xlarge"
                    on:click={() => cancelInserting()}>Prekliči</button
                >
            </div>
        </div>
    {/if}
    <div class="w3-row">
        <div class="w3-col m12 l10 w3-padding-large w3-center">
            <div class="w3-center">
                <Loader visible={is_loading} />
            </div>
            {#if !is_loading}
                <table class="w3-table-all w3-hoverable">
                    <tr>
                        <th>Id</th>
                        <th>Status</th>
                        <th>Ime</th>
                        <th>Naziv</th>
                        <th>Email</th>
                        <th>Gsm</th>
                        <th>Davčna št.</th>
                        <th>Naslov</th>
                        <th>Pošta</th>
                        <th>Mesto</th>
                        <th>Vnešen</th>
                    </tr>
                    {#each registrations_filtered as user}
                        <tr>
                            <td>
                                {user.registration_id}
                            </td>
                            <td class={getStatusClass(user.status)}>
                                {getStatusText(user.status)}
                            </td>
                            <td>
                                {user.username}
                            </td>
                            <td>
                                {user.title}
                            </td>
                            <td>
                                <a
                                    href={UrlHelper.getRegistrationUrl(
                                        user.registration_id
                                    )}
                                >
                                    {user.email}
                                </a>
                            </td>
                            <td>
                                {user.gsm}
                            </td>
                            <td>
                                {user.tax_id}
                            </td>
                            <td>
                                {user.addr}
                            </td>
                            <td>
                                {user.zip}
                            </td>
                            <td>
                                {user.town}
                            </td>
                            <td>
                                {niceDateTime(user.date_created)}
                            </td>
                        </tr>
                    {/each}
                </table>
            {/if}
        </div>
    </div>
</div>
