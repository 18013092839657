import { token } from "./stores";
class ServerBase {
    constructor(host) {
        this.host = host;
        this.headers = {
            "Content-Type": "application/json"
        };
        token.subscribe(value => {
            if (value != null && value.length > 0) {
                this.headers["Authorization"] = "Bearer " + value;
            }
            else {
                this.headers["Authorization"] = null;
            }
        });
    }
    async get(path, data) {
        return await this.httpRequest("get", path, data);
    }
    async delete(path, data) {
        return await this.httpRequest("delete", path, data);
    }
    async post(path, data) {
        return await this.httpRequest("post", path, data);
    }
    async put(path, data) {
        return await this.httpRequest("put", path, data);
    }
    async httpRequest(httpverb, urlpath, data) {
        try {
            let url = this.host + urlpath;
            console.log(httpverb, url);
            const options = {
                method: httpverb.toUpperCase(),
                headers: this.headers
            };
            if (httpverb == "put" || httpverb == "post") {
                options.body = JSON.stringify(data);
            }
            else {
                let u = new URLSearchParams(data).toString();
                if (u.length > 0) {
                    url += "?" + u;
                    console.log(httpverb, url);
                }
            }
            const res = await fetch(url, options);
            if (res.status < 200 || res.status >= 300) {
                throw new Error(await res.text());
            }
            return res.json();
        }
        catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                throw new Error(error.response.data);
            }
            else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
                throw new Error("Napaka pri komunikaciji");
            }
            else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
                throw new Error(error.message);
            }
        }
    }
}
/** Factory function for REST client. Uses the Axios library internally.
 * This way we expose Axios interfaces but leave the implementation open to mocking.
 */
export function create() {
    return new ServerBase("");
}
