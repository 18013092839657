<script lang="ts">
    import { onMount } from "svelte";
    import SvelteMarkdown from "svelte-markdown";

    import type { IServices } from "./code/services";
    import { niceDate, niceDateTimeNoSec } from "./code/utils";

    import type {
        IMessageRec,
        IGetSingleMessageRes,
    } from "./code/backend/admin.generated.types";
    import Loader from "./Loader.svelte";

    export let services: IServices;
    export const page_params: any = {};

    let messages: IMessageRec[] = [];
    let selected_data: IGetSingleMessageRes | null = null;
    let is_loading: boolean = false;

    let is_inserting: boolean = false;

    let title: string = "";
    let content: string = "";
    let date_from: string = new Date().toLocaleDateString("sl-SI").slice(0, 10);
    let date_to: string = new Date().toLocaleDateString("sl-SI").slice(0, 10);

    async function load() {
        is_loading = true;
        selected_data = null;
        messages = (
            await services.backend.admin().getMessages({})
        ).messages.sort((a, b) => a.date_from - b.date_from);
        is_loading = false;
    }
    async function loadSingle(id: number) {
        selected_data = await services.backend.admin().getSingleMessage({ id });
        date_from = new Date(selected_data.message.date_from)
            .toISOString()
            .slice(0, 10);
        date_to = new Date(selected_data.message.date_to)
            .toISOString()
            .slice(0, 10);
        title = selected_data.message.title;
        content = selected_data.message.content;
    }
    function startInsert() {
        date_from = new Date().toISOString().slice(0, 10);
        date_to = new Date().toISOString().slice(0, 10);
        title = "";
        content = "";
        is_inserting = true;
    }
    function cancelInsertOrEdit() {
        selected_data = null;
        is_inserting = false;
    }
    async function saveData() {
        if (is_inserting) {
            await services.backend.admin().insertNewMessage({
                content,
                date_from: new Date(date_from).getTime(),
                date_to: new Date(date_to).getTime(),
                title
            });
        } else if (selected_data != null) {
            await services.backend.admin().updateSingleMessage({
                content,
                date_from: new Date(date_from).getTime(),
                date_to: new Date(date_to).getTime(),
                id: selected_data!.message.message_id,
                title
            });
        }

        selected_data = null;
        is_inserting = false;
        await load();
    }
    onMount(async () => {
        await load();
    });
</script>

<div class="w3-container">
    <div class="w3-row">
        <div class="w3-col m12 l10 w3-padding-large w3-center">
            <div class="w3-center">
                <Loader visible={is_loading} />
            </div>
            {#if !is_loading}
                {#if selected_data == null && !is_inserting}
                    <div class="w3-left">
                        <button
                            class="w3-button-link"
                            on:click={() => startInsert()}
                        >
                            Novo sporočilo
                        </button>
                    </div>
                    <table class="w3-table-all w3-hoverable">
                        <tr>
                            <th>Od</th>
                            <th>Do</th>
                            <th>Naslov</th>
                            <th>Besedilo</th>
                            <th>&nbsp;</th>
                        </tr>
                        {#each messages as message}
                            <tr>
                                <td>
                                    {niceDate(message.date_from)}
                                </td>
                                <td>
                                    {niceDate(message.date_to)}
                                </td>
                                <td>
                                    {message.title}
                                </td>
                                <td>
                                    <SvelteMarkdown source={message.content} />
                                </td>
                                <td>
                                    <button
                                        class="w3-button-link"
                                        on:click={() =>
                                            loadSingle(message.message_id)}
                                        >Odpri</button
                                    >
                                </td>
                            </tr>
                        {/each}
                    </table>
                {/if}
                {#if selected_data != null || is_inserting}
                    <div class="w3-left">
                        <button
                            class="w3-button-link"
                            on:click={() => cancelInsertOrEdit()}
                        >
                            &lt; Nazaj
                        </button>
                    </div>
                    <form class="w3-container">
                        <div class="w3-panel">
                            <label for="inDate">Od</label>
                            <input
                                id="inDate"
                                class="w3-input w3-border w3-round"
                                type="date"
                                bind:value={date_from}
                            />
                        </div>
                        <div class="w3-panel">
                            <label for="inDate">Do</label>
                            <input
                                id="inDate"
                                class="w3-input w3-border w3-round"
                                type="date"
                                bind:value={date_to}
                            />
                        </div>
                        <div class="w3-panel">
                            <label for="inDesc">Naslov</label>
                            <input
                                id="inDesc"
                                class="w3-input w3-border w3-round"
                                type="text"
                                bind:value={title}
                            />
                        </div>
                        <div class="w3-panel">
                            <label for="inDesc">Besedilo</label>
                            <textarea
                                id="inDesc"
                                rows="5"
                                class="w3-input w3-border w3-round"
                                bind:value={content}
                            />
                            Legenda: <b>**bold**</b> <i>*italics*</i> [To je link](https://google.com)
                        </div>
                    </form>
                    <div class="w3-right-align w3-container w3-padding">
                        <button
                            class="w3-btn w3-theme-action w3-round-xlarge"
                            on:click={() => saveData()}>Shrani</button
                        >
                        <button
                            class="w3-btn w3-theme-light w3-round-xlarge"
                            on:click={() => cancelInsertOrEdit()}
                            >Prekliči</button
                        >
                    </div>
                    <h3>Predogled</h3>
                    <div class="w3-container w3-border w3-left-align">
                        <SvelteMarkdown source={content} />
                    </div>
                    {#if !is_inserting}
                        <h3>Ogledi</h3>
                        <table class="w3-table-all w3-hoverable">
                            <tr>
                                <th>Kdaj</th>
                                <th>Kdo</th>
                            </tr>
                            {#each selected_data.views as view}
                                <tr>
                                    <td>
                                        {niceDateTimeNoSec(view.read_at)}
                                    </td>
                                    <td>
                                        {view.username}
                                    </td>
                                </tr>
                            {/each}
                        </table>
                    {/if}
                {/if}
            {/if}
        </div>
    </div>
</div>
