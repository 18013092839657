<script lang="ts">
    import { onMount } from "svelte";
    import type { IServices } from "./code/services";
    import { CssHelpers, UrlHelper } from "./code/utils";
    import type {
        IGetLatestChangesReq,
        IReservationHistoryRec,
    } from "./code/backend/admin.generated.types";
    import { niceDateTime, niceNumber } from "./code/utils";
    import BarChart from "./BarChart.svelte";
    import Loader from "./Loader.svelte";

    export let services: IServices;
    export const page_params: any = {};

    let new_recs: IReservationHistoryRec[] = [];
    let deleted_recs: IReservationHistoryRec[] = [];
    let is_loading: boolean = false;
    let data: number[][] = [];
    let labels: string[] = [];

    onMount(async () => {
        await load();
    });

    async function load(): Promise<void> {
        is_loading = true;
        let req: IGetLatestChangesReq = {};
        const res = await services.backend.admin().getLatestChanges(req);
        new_recs = res.inserted;
        deleted_recs = res.deleted;
        is_loading = false;
        data = [[], []];
        labels = [];
        for (const r of res.stats.sort((a, b) =>
            a.date.localeCompare(b.date)
        )) {
            data[0].push(r.entered);
            data[1].push(-r.deleted);
            labels.push(r.date.slice(4 + 1 + 2 + 1));
        }
    }
</script>

<div class="w3-container">
    <div class="w3-row">
        <div class="w3-col m12 l12 w3-padding-large w3-center">
            <div class="w3-center">
                <Loader visible={is_loading} />
            </div>
            {#if !is_loading}
                <BarChart values={data} {labels} />
                <h2>Novi vnosi</h2>
                <table class="w3-table-all w3-hoverable">
                    <tr>
                        <th>Id</th>
                        <th>Lokacija</th>
                        <th>Datum</th>
                        <th>Trajanje</th>
                        <th>Cena</th>
                        <th>Uporabnik</th>
                        <th>Opis</th>
                        <th>Skupina</th>
                        <th>Vnešeno</th>
                        <th>Brisano</th>
                    </tr>
                    {#each new_recs as rec}
                        <tr>
                            <td>
                                {#if !rec.is_deleted}
                                    <a
                                        href={UrlHelper.getEditReservationUrl(
                                            rec.id
                                        )}
                                    >
                                        {rec.id}
                                    </a>
                                {/if}
                                {#if rec.is_deleted}
                                    {rec.id}
                                    <span class="w3-tag w3-round w3-small"
                                        >Brisano</span
                                    >
                                {/if}
                            </td>
                            <td class="{CssHelpers.getCssClassForRoomId(rec.room_id)}"> {rec.room_title} </td>
                            <td class="w3-right-align"> {niceDateTime(rec.start)} </td>
                            <td class="w3-right-align"> {niceNumber(rec.len, 2)} </td>
                            <td class="w3-right-align"> {niceNumber(rec.ex_price, 2)} </td>
                            <td>
                                <a
                                    href={UrlHelper.getOpenUserUrl(
                                        "" + rec.user_id
                                    )}>{rec.username}</a
                                ></td
                            >
                            <td> {rec.text} </td>
                            <td> {rec.is_group ? "Skupina" : ""} </td>
                            <td> {niceDateTime(rec.created)} </td>
                            <td>
                                {rec.is_deleted
                                    ? niceDateTime(rec.deleted)
                                    : ""}
                            </td>
                        </tr>
                    {/each}
                </table>
                <h2>Brisani vnosi</h2>
                <table class="w3-table-all w3-hoverable">
                    <tr>
                        <th>Id</th>
                        <th>Lokacija</th>
                        <th>Datum</th>
                        <th>Trajanje</th>
                        <th>Cena</th>
                        <th>Uporabnik</th>
                        <th>Opis</th>
                        <th>Skupina</th>
                        <th>Vnešeno</th>
                        <th>Brisano</th>
                    </tr>
                    {#each deleted_recs as rec}
                        <tr>
                            <td> {rec.id} </td>
                            <td class="{CssHelpers.getCssClassForRoomId(rec.room_id)}"> {rec.room_title} </td>
                            <td> {niceDateTime(rec.start)} </td>
                            <td> {niceNumber(rec.len)} </td>
                            <td> {niceNumber(rec.ex_price)} </td>
                            <td>
                                <a
                                    href={UrlHelper.getOpenUserUrl(
                                        "" + rec.user_id
                                    )}
                                >
                                    {rec.username}
                                </a>
                            </td>
                            <td> {rec.text} </td>
                            <td> {rec.is_group ? "Skupina" : ""} </td>
                            <td> {niceDateTime(rec.created)} </td>
                            <td> {niceDateTime(rec.deleted)} </td>
                        </tr>
                    {/each}
                </table>
            {/if}
        </div>
    </div>
</div>
