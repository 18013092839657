<script lang="ts">
    import { CssHelpers, niceDateTime, niceNumber, UrlHelper } from "./code/utils";
    import type { IReservationHistoryRec } from "./code/backend/admin.generated.types";

    export let reservations: IReservationHistoryRec[] | null = null;
</script>

<table class="w3-table-all w3-hoverable">
    <tr>
        <th>Id</th>
        <th>Lokacija</th>
        <th>Datum</th>
        <th>Trajanje</th>
        <th>Cena</th>
        <th>Uporabnik</th>
        <th>Opis</th>
        <th>Skupina</th>
        <th>Vnešeno</th>
        <th>Brisano</th>
    </tr>
    {#each reservations as rec}
        <tr>
            <td>
                {#if !rec.is_deleted}
                    <a href="{UrlHelper.getEditReservationUrl(rec.id)}">{rec.id}</a>
                {/if}
                {#if rec.is_deleted}
                    {rec.id}
                    <span class="w3-tag w3-round w3-small">Brisano</span>
                {/if}
            </td>
            <td class="{CssHelpers.getCssClassForRoomId(rec.room_id)}"> {rec.room_title} </td>
            <td> {niceDateTime(rec.start)} </td>
            <td class="w3-right-align"> {niceNumber(rec.len, 2)} </td>
            <td class="w3-right-align"> {niceNumber(rec.ex_price, 2)} </td>
            <td> <a href="{UrlHelper.getOpenUserUrl("" + rec.user_id)}">{rec.username}</a></td>
            <td> {rec.text} </td>
            <td> {rec.is_group ? "Skupina" : ""} </td>
            <td> {niceDateTime(rec.created)} </td>
            <td> {rec.is_deleted ? niceDateTime(rec.deleted) : ""} </td>
        </tr>
    {/each}
</table>
