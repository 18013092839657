// tslint:disable:max-line-length
function enc(s) { return encodeURIComponent("" + s); }
export class Nonauthenticated {
    constructor(client) { this.client = client; }
    /** Start mock job */
    async testMockJob(req) {
        const reqx = req;
        const url = `/api/v1.0/nonauthenticated/test-job`;
        return (await this.client.post(url, reqx));
    }
    /** Gets list of available offices */
    async getOffices(req) {
        const reqx = req;
        const url = `/api/v1.0/nonauthenticated/offices`;
        return (await this.client.get(url, reqx));
    }
    /** Gets week view */
    async getWeek(req) {
        const reqx = req;
        const url = `/api/v1.0/nonauthenticated/week`;
        return (await this.client.get(url, reqx));
    }
    /** Gets multi-week view */
    async getMultiWeek(req) {
        const reqx = req;
        const url = `/api/v1.0/nonauthenticated/multi-week`;
        return (await this.client.get(url, reqx));
    }
    /** Get captcha data to display on registration form */
    async getCaptcha(req) {
        const reqx = req;
        const url = `/api/v1.0/nonauthenticated/registations-get-captcha`;
        return (await this.client.post(url, reqx));
    }
    /** Get data about pending registration */
    async getRegistrationByToken(req) {
        const reqx = req;
        const url = `/api/v1.0/nonauthenticated/registations/${enc(req.token)}`;
        delete reqx.token;
        return (await this.client.get(url, reqx));
    }
    /** Updates pending registration */
    async updateRegistrationByToken(req) {
        const reqx = req;
        const url = `/api/v1.0/nonauthenticated/registations`;
        return (await this.client.post(url, reqx));
    }
}
