<script lang="ts">
    import type { IServices } from "./code/services";
    import { setTimeoutAsync } from "./code/utils";
    import Loader from "./Loader.svelte";
    import { UrlHelper } from "./code/utils";

    export let services: IServices;
    export const page_params: any = {};

    let error_message: string | null = null;
    let name = "";
    let pwd = "";
    let loading: boolean = false;

    async function login() {
        loading = true;
        const res = await services.auth.login(name, pwd);
        loading = false;
        if (res == null) {
            location.hash = "/home";
        } else {
            error_message = res;
        }
        return false;
    }
</script>

<div class="w3-display-container w3-display-middle">
    <div class="w3-container">
        <div class="w3-panel">
            <h2>Prijava</h2>
        </div>
    </div>

    <div class="w3-center">
        <Loader visible={loading} />
    </div>

    {#if loading == false}
        <div class="w3-container">
            <div class="w3-panel">
                <label for="loginU">Uporabniško ime</label>
                <input
                    id="loginU"
                    class="w3-input w3-border"
                    type="text"
                    bind:value={name}
                />
            </div>

            <div class="w3-panel">
                <label for="loginP">Geslo</label>
                <input
                    id="loginP"
                    class="w3-input w3-border"
                    type="password"
                    bind:value={pwd}
                />
            </div>

            <div class="w3-panel w3-right-align">
                <button
                    class="w3-btn w3-theme-action w3-round-xlarge"
                    on:click={() => login()}>Prijava</button
                >
            </div>

            <div class="w3-panel">
                <a href={UrlHelper.getPageUrl("forgot")}>
                    Pozabljeno geslo
                </a>
            </div>
        </div>
    {/if}
    {#if error_message != null}
        <div class="w3-panel w3-pale-yellow">
            <p>{error_message}</p>
        </div>
    {/if}
</div>
