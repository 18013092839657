// tslint:disable:max-line-length
function enc(s) { return encodeURIComponent("" + s); }
export class Reservations {
    constructor(client) { this.client = client; }
    /** Gets week view */
    async getWeek(req) {
        const reqx = req;
        const url = `/api/v1.0/reservations/week`;
        return (await this.client.get(url, reqx));
    }
    /** Insert new reservation */
    async insertReservation(req) {
        const reqx = req;
        const url = `/api/v1.0/reservations/new`;
        return (await this.client.post(url, reqx));
    }
    /** Update reservation */
    async updateReservation(req) {
        const reqx = req;
        const url = `/api/v1.0/reservations/update/${enc(req.id)}`;
        delete reqx.id;
        return (await this.client.put(url, reqx));
    }
    /** Delete reservation */
    async deleteReservation(req) {
        const reqx = req;
        const url = `/api/v1.0/reservations/delete/${enc(req.id)}`;
        delete reqx.id;
        return (await this.client.delete(url, reqx));
    }
    /** Gets my reservations */
    async getMyReservations(req) {
        const reqx = req;
        const url = `/api/v1.0/reservations/my`;
        return (await this.client.get(url, reqx));
    }
    /** Gets single reservation */
    async getSingleReservation(req) {
        const reqx = req;
        const url = `/api/v1.0/reservations/${enc(req.id)}`;
        delete reqx.id;
        return (await this.client.get(url, reqx));
    }
    /** Gets my invoices */
    async getMyInvoices(req) {
        const reqx = req;
        const url = `/api/v1.0/reservations/invoices/my`;
        return (await this.client.get(url, reqx));
    }
    /** Download invoice */
    async downloadInvoice(req) {
        const reqx = req;
        const url = `/api/v1.0/reservations/invoices/${enc(req.id)}/download`;
        delete reqx.id;
        return (await this.client.post(url, reqx));
    }
}
