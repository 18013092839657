<script lang="ts">

    import type { IServices } from "./code/services";

    export const services: IServices | null = null;
    export const page_params: any = {};

    // let error_message: string | null = null;
    // let name = "";
    // let pwd = "";

    // async function login() {
    //     const res = await services.auth.login(name, pwd);
    //     if (res == null) {
    //         // todo redirect home
    //     } else {
    //         error_message = res;
    //     }
    // }
</script>

<h1>Occupancy</h1>
