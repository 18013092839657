<script lang="ts">
    import type { PropertyListItem } from "./code/gui_classes";

    export let items: PropertyListItem[] = [];
</script>

<table>
    <tbody>
        {#each items as val}
            <tr>
                <th>{val.title}</th>
                {#if val.onClick != undefined}
                    <td class={val.cssClass}>
                        <button
                            class="w3-button-link"
                            on:click={() => val.onClick()}
                        >
                            {val.value}
                        </button>
                    </td>
                {:else}
                    <td class={val.cssClass}> {val.value}</td>
                {/if}
            </tr>
        {/each}
    </tbody>
</table>

<style>
    th {
        text-align: right;
        padding: 0.2em 0.2em;
        font-size: 0.95em;
        font-weight: bolder;
    }

    td {
        padding: 0.2em 0.2em;
        font-size: 0.9em;
    }
</style>
