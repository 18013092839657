<script lang="ts">
    import { onMount } from "svelte";

    import type { IServices } from "./code/services";
    import type { IGetCurrentUserDataRes } from "./code/backend/users.generated.types";
    import { enable_wide_gui } from "./code/stores";

    export let services: IServices;
    export const page_params: any = {};

    const DUMMY: IGetCurrentUserDataRes = {
        address: "",
        email: "",
        is_admin: false,
        roles: [],
        status: "",
        tax_id: "",
        title: "",
        town: "",
        user_uuid: "",
        username: "",
        zip: "",
    };

    let pwd_old: string | null = null;
    let pwd_new: string | null = null;
    let pwd_new2: string | null = null;
    let curr_user_data: IGetCurrentUserDataRes = DUMMY;
    let error_message: string | null = null;
    let info_message: string | null = null;
    let show_pwd_change_form: boolean = false;

    let wide_gui: boolean = $enable_wide_gui;
    enable_wide_gui.subscribe((val) => {
        wide_gui = val;
    });

    async function changePwd() {
        error_message = null;
        info_message = null;
        try {
            console.log({ pwd_old, pwd_new, pwd_new2 });
            if (pwd_new != pwd_new2) {
                throw new Error("Novi gesli se ne ujemata");
            }
            await services.backend.users().changePwd({ pwd_old, pwd_new });
            info_message = "Geslo uspešno spremenjeno.";
            show_pwd_change_form = false;
        } catch (err) {
            console.log(err);
            error_message = err.message;
        }
    }

    onMount(async () => {
        curr_user_data = await services.backend.users().getCurrentUserData({});
    });

    function toggleEnableWideGui() {
        enable_wide_gui.set(wide_gui); // wide_gui is already at new value (binding)
    }
</script>

<div class="w3-container">
    <div class="w3-row">
        <div class="w3-col m2 l2">&nbsp;</div>
        <div class="w3-col m8 l8">
            <h1>Podatki uporabnika</h1>
            <p>
                Prijavljeni ste z uporabniškim imenom <strong
                    >{curr_user_data.username}</strong
                >.
            </p>
            <p>
                Vaše ime je <strong>{curr_user_data.title}</strong>.
            </p>
            <p>
                Naslov vaše elektronske pošte je <strong
                    >{curr_user_data.email}</strong
                >.
            </p>
            <p>
                Vaš naslov je <strong>
                    {curr_user_data.address || "?"},
                    {curr_user_data.zip || "?"}
                    {curr_user_data.town || "?"}
                </strong>.
            </p>
            <p>
                Vaša davčna številka je <strong
                    >{curr_user_data.tax_id || "?"}</strong
                >.
            </p>
            <div class="w3-panel w3-padding w3-theme">
                <p>
                    V kolikor zgornji podatki niso pravilni, nas kontaktirajte
                    na info@najempisarne.si, da jih popravimo tudi v naši
                    evidenci.
                </p>
            </div>
            <h2>Nastavitve prikaza</h2>
            <div>
                Uporabljaj široki prikaz:
                <input
                    type="checkbox"
                    bind:checked={wide_gui}
                    on:change={() => toggleEnableWideGui()}
                />
            </div>
            <h2>Nastavitve prijave</h2>

            {#if !show_pwd_change_form}
                <button
                    class="w3-btn w3-theme-action w3-round-xlarge"
                    on:click={() => (show_pwd_change_form = true)}
                    >Spremeni geslo</button
                >
            {/if}

            {#if info_message}
                <div class="w3-panel w3-padding w3-teal">
                    <p>{info_message}</p>
                </div>
            {/if}

            {#if show_pwd_change_form}
                <h2>Spremeni geslo</h2>
                <div class="w3-container">
                    {#if error_message}
                        <div class="w3-panel w3-padding w3-red">
                            <p>{error_message}</p>
                        </div>
                    {/if}

                    <div class="w3-panel">
                        <label for="pwd_old">Staro geslo</label>
                        <input
                            id="pwd_old"
                            class="w3-input w3-border"
                            type="password"
                            bind:value={pwd_old}
                        />
                    </div>

                    <div class="w3-panel">
                        <label for="pwd_new">Novo geslo</label>
                        <input
                            id="pwd_new"
                            class="w3-input w3-border"
                            type="password"
                            bind:value={pwd_new}
                        />
                    </div>
                    <div class="w3-panel">
                        <label for="pwd_new2">Ponovi novo geslo</label>
                        <input
                            id="pwd_new2"
                            class="w3-input w3-border"
                            type="password"
                            bind:value={pwd_new2}
                        />
                    </div>

                    <div class="w3-panel w3-right-align">
                        <button
                            class="w3-btn w3-theme-action w3-round-xlarge"
                            on:click={changePwd}>Spremeni</button
                        >
                    </div>
                </div>
            {/if}
        </div>
        <div class="w3-col m4 l4" />
    </div>
</div>
