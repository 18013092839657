// tslint:disable:max-line-length
function enc(s) { return encodeURIComponent("" + s); }
export class Users {
    constructor(client) { this.client = client; }
    /** Refreshes JWT token for current user */
    async refreshToken(req) {
        const reqx = req;
        const url = `/api/v1.0/users/me/refresh-jwt-token`;
        return (await this.client.post(url, reqx));
    }
    /** Changes password for current user */
    async changePwd(req) {
        const reqx = req;
        const url = `/api/v1.0/users/change-pwd`;
        return (await this.client.post(url, reqx));
    }
    /** Marks message as read by current user */
    async markMessageAsReadByCurrentUser(req) {
        const reqx = req;
        const url = `/api/v1.0/users/mark-msgs-as-read`;
        return (await this.client.post(url, reqx));
    }
    /** Gets messages for current user */
    async getMessagesForCurrentUser(req) {
        const reqx = req;
        const url = `/api/v1.0/users/me/messages`;
        return (await this.client.get(url, reqx));
    }
    /** Gets data for current user */
    async getCurrentUserData(req) {
        const reqx = req;
        const url = `/api/v1.0/users/me`;
        return (await this.client.get(url, reqx));
    }
    /** Log in user with given credentials */
    async loginUP(req) {
        const reqx = req;
        const url = `/api/v1.0/users/login`;
        return (await this.client.post(url, reqx));
    }
    /** Trigger forgotten password sequence */
    async forgotPasswordStart1(req) {
        const reqx = req;
        const url = `/api/v1.0/users/forgot-password-1`;
        return (await this.client.post(url, reqx));
    }
    /** Verify incoming token */
    async forgotPasswordStart2(req) {
        const reqx = req;
        const url = `/api/v1.0/users/forgot-password-2`;
        return (await this.client.post(url, reqx));
    }
    /** Set new password with token */
    async forgotPasswordStart3(req) {
        const reqx = req;
        const url = `/api/v1.0/users/forgot-password-3`;
        return (await this.client.post(url, reqx));
    }
    /** Validates given JWT token */
    async validateToken(req) {
        const reqx = req;
        const url = `/api/v1.0/users/validate-token`;
        return (await this.client.post(url, reqx));
    }
}
