<script lang="ts">
    import { onMount } from "svelte";
    import type { IServices } from "./code/services";
    import { UrlHelper, niceDate, niceNumber } from "./code/utils";

    import type { IUserBalanceInfo } from "./code/backend/admin.generated.types";
    import Loader from "./Loader.svelte";

    export let services: IServices;
    export const page_params: any = {};

    let error_message: string | null = null;
    let is_loading: boolean = false;
    let balances: IUserBalanceInfo[] = [];
    let displayed_balances: IUserBalanceInfo[] = [];
    let only_non_zero: boolean = true;
    let sum: number = 0;

    onMount(async () => {
        await load();
    });

    async function load(): Promise<void> {
        error_message = null;
        is_loading = true;
        balances = (
            await services.backend.admin().getUserBalances({})
        ).balances.sort((a, b) => a.username.localeCompare(b.username));
        is_loading = false;
        sum = balances.reduce((prev, x) => prev + x.balance, 0)
        applyFilter();
    }

    function applyFilter() {
        if (!only_non_zero) {
            displayed_balances = balances;
        } else {
            displayed_balances = balances.filter((x) => x.balance != 0);
        }
        sum = displayed_balances.reduce((prev, x) => prev + x.balance, 0)
    }
    function getCssClass(rec: IUserBalanceInfo): string {
        if (rec.balance < 0) return "w3-red-lite";
        if (rec.balance > 0) return "w3-green-lite";
        return "";
    }
</script>

<div class="w3-container">
    <div class="w3-row">
        <div class="w3-right w3-margin">
            <label class="switch">
                <input
                    type="checkbox"
                    bind:checked={only_non_zero}
                    on:change={() => applyFilter()}
                />
                <span class="slider round" />
            </label>
            <span>Samo različni od 0</span>
        </div>
        {#if error_message != null}
            <div class="w3-panel w3-pale-yellow w3-border">
                <h3>Opozorilo:</h3>
                <p>{error_message}</p>
            </div>
        {/if}

        <div class="w3-center">
            <Loader visible={is_loading} />
        </div>
        {#if !is_loading}
            <div>
                Skupaj: {niceNumber(sum, 2)}
            </div>
            <table class="w3-table-all w3-hoverable">
                <tr>
                    <th>Uporabnik</th>
                    <th>Ime</th>
                    <th>Znesek</th>
                </tr>
                {#each displayed_balances as balance}
                    <tr>
                        <td>
                            <a
                                href={UrlHelper.getOpenUserUrl(
                                    balance.user_uuid
                                )}
                            >
                                {balance.username}
                            </a>
                        </td>
                        <td> {balance.title} </td>
                        <td class={getCssClass(balance)}>
                            {niceNumber(balance.balance, 2)}
                        </td>
                    </tr>
                {/each}
            </table>
        {/if}
    </div>
</div>
