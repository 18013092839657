<script lang="ts">
    import { niceNumber } from "./code/utils";
    import type { IPricelist } from "./code/backend/admin.generated.types";

    export let pricelists: IPricelist[] = [];
    export let selected_id: string;

    let items: string[][] = [];
    $: items = [
        ["-1", "-"],
        ...pricelists
            .sort((a, b) => (a.price_hn - b.price_hn)*10 + (a.price_hb - b.price_hb))
            .map((x) => [x.id + "", niceNumber(x.price_hn, 2) + " - " + niceNumber(x.price_hb, 2)]),
    ];
</script>

<select
    class="w3-select w3-border w3-round w3-margin-top"
    bind:value={selected_id}>
    {#each items as item}
        <option value={item[0]}>
            {item[1]}
        </option>
    {/each}
</select>
