<script lang="ts">
    import { onMount } from "svelte";
    import type { IServices } from "./code/services";
    import {
        UrlHelper,
        monthName,
        niceNumber,
        CssHelpers
    } from "./code/utils";

    import type {
        IGetKpiYearRes,
        IGetMonthRes,
    } from "./code/backend/admin.generated.types";
    import Loader from "./Loader.svelte";
    import Barchart from "./BarChart.svelte";

    export let services: IServices;
    export const page_params: any = {};

    let error_message: string | null = null;
    let is_loading: boolean = false;

    let offset: number = 0;
    let kpis: IGetKpiYearRes | null = null;
    let year_title: string = "";

    onMount(async () => {
        await load();
    });

    async function load(): Promise<void> {
        error_message = null;
        is_loading = true;
        kpis = null;
        try {
            const d = new Date();
            year_title = `${d.getFullYear() + offset}`;
            kpis = await services.backend.admin().getKpiYear({ offset });
            is_loading = false;
        } catch (err) {
            is_loading = false;
            error_message = err.message;
        }
    }
    async function decOffset() {
        offset--;
        await load();
    }
    async function incOffset() {
        offset++;
        await load();
    }
    async function zeroOffset() {
        offset = 0;
        await load();
    }
</script>

<div class="w3-container">
    <div class="w3-row">
        <div class="w3-right">
            {year_title}
            <button
                class="w3-button w3-theme-light w3-hover-theme w3-margin-rightx w3-round-xlarge"
                on:click={() => decOffset()}>&#5130;</button
            >
            <button
                class="w3-button w3-theme-light w3-hover-theme w3-margin-rightx w3-round-xlarge"
                on:click={() => incOffset()}>&#5125;</button
            >
            <button
                class="w3-button w3-theme-light w3-hover-theme w3-margin-rightx w3-round-xlarge"
                on:click={() => zeroOffset()}
            >
                <img src="/img/icons8-home-24.svg" height="20" alt="home" />
            </button>
        </div>
    </div>
    <div class="w3-row">
        {#if error_message != null}
            <div class="w3-panel w3-pale-yellow w3-border">
                <h3>Opozorilo:</h3>
                <p>{error_message}</p>
            </div>
        {/if}
        <div class="w3-center">
            <Loader visible={is_loading} />
        </div>
        {#if kpis == null}
            <h1>KPIs</h1>
            <p>Loading ...</p>
        {/if}
        {#if kpis != null}
            <div class="w3-container">
                <div class="w3-third w3-padding">
                    <div class="w3-panel w3-blue w3-card-4 w3-padding">
                        <h3>Skupaj</h3>
                        <div>&nbsp;</div>
                    </div>
                </div>
                <div class="w3-third w3-padding">
                    <div class="w3-panel w3-card-4 w3-padding">
                        <h3>Letno</h3>
                        <div>{niceNumber(kpis.sum, 2)}</div>
                    </div>
                </div>
                <div class="w3-third w3-padding">
                    <div class="w3-panel w3-card-4 w3-padding">
                        <h3>Povprečno mesečno</h3>
                        <div>{niceNumber(kpis.sum_avg_month, 2)}</div>
                    </div>
                </div>
            </div>
            <div class="w3-container">
                <div class="w3-padding">
                    <Barchart
                        labels={kpis.months.map(
                            (x) => `${Math.floor(x.yyyymm / 100)}-${x.yyyymm % 100}`
                        )}
                        values={[kpis.months.map((x) => x.sum)]}
                    />
                </div>
            </div>

            <!-- <h2>Prihodek po posamezni pisarni</h2> -->
            {#each kpis.rooms as r}
                <div class="w3-container">
                    <div class="w3-third w3-padding">
                        <div
                            class="w3-panel {CssHelpers.getCssClassForRoomTitle(r.room_id)} w3-card-4 w3-padding"
                        >
                            <h3>{r.room_id}</h3>
                            <div>&nbsp;</div>
                        </div>
                    </div>
                    <div class="w3-third w3-padding">
                        <div class="w3-panel w3-card-4 w3-padding">
                            <h3>Skupaj letno</h3>
                            <div>{niceNumber(r.sum, 2)}</div>
                        </div>
                    </div>
                    <div class="w3-third w3-padding">
                        <div class="w3-panel w3-card-4 w3-padding">
                            <h3>Povprečno mesečno</h3>
                            <div>{niceNumber(r.sum_avg_month, 2)}</div>
                        </div>
                    </div>
                </div>
                <div class="w3-container">
                    <div class="w3-padding">
                        <Barchart
                            labels={r.months.map(
                                (x) =>
                                    `${Math.floor(x.yyyymm / 100)}-${
                                        x.yyyymm % 100
                                    }`
                            )}
                            values={[r.months.map((x) => x.sum)]}
                        />
                    </div>
                </div>
            {/each}
        {/if}
    </div>
</div>
