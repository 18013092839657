<script lang="ts">
    import type { IServices } from "./code/services";
    import Loader from "./Loader.svelte";

    export let services: IServices;
    export const page_params: any = {};

    let error_message: string | null = null;
    let name = "";
    let loading: boolean = false;
    let done: boolean = false;

    async function startForgotPasswordProcess() {
        try {
            error_message = null;
            done = false;
            loading = true;
            await services.backend.users().forgotPasswordStart1({ u: name.trim() });
            done = true;
            loading = false;
        } catch (err) {
            error_message = err.message;
        }
    }
</script>

<div class="w3-display-container w3-display-middle">
    <div class="w3-container">
        <div class="w3-panel">
            <h2>Pozabljeno geslo</h2>
        </div>
    </div>

    <div class="w3-center">
        <Loader visible={loading} />
    </div>

    {#if !done}
        <div class="w3-container">
            <div class="w3-panel">
                <p>
                    V kolikor ste pozabili geslo, lahko zahtevate njegovo ponastavitev.
                    V spodnje polje vpišite svoje uporabniško ime ali elektronski naslov
                    in poslali vam bomo nadaljnja navodila za zamenjavo gesla.
                </p>
            </div>
            <div class="w3-panel">
                <label for="loginU">Uporabniško ime ali elektornski nalsov</label>
                <input
                    id="loginU"
                    class="w3-input w3-border"
                    type="text"
                    bind:value={name}
                />
            </div>

            <div class="w3-panel w3-right-align">
                <button
                    class="w3-btn w3-theme-action w3-round-xlarge"
                    on:click={() => startForgotPasswordProcess()}>Pošlji podatke za zamenjavo gesla</button
                >
            </div>
        </div>
    {/if}
    {#if done}
        <div class="w3-panel">
            <p>
                Vaša zahteva je bila sprejeta.
            </p>
            <p>
                V kolikor ste ste vnesli pravilno uporabniško ime oziroma elektronski naslov,
                vam bomo poslali nadaljnja navodila za zamenjavo gesla.
            </p>
        </div>
    {/if}
    {#if error_message != null}
        <div class="w3-panel w3-pale-yellow">
            <p>{error_message}</p>
        </div>
    {/if}
</div>


