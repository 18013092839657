<script lang="ts">
    export let visible: boolean;
</script>

{#if visible == true}
    <div class="lds-dual-ring" />
{/if}

<style>
    .lds-dual-ring {
        display: inline-block;
        width: 20px;
        height: 20px;
    }
    .lds-dual-ring:after {
        content: " ";
        display: block;
        width: 16px;
        height: 16px;
        margin: 2px;
        border-radius: 50%;
        border: 3px solid #aaa;
        border-color: #aaa #aaa transparent transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }
    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
