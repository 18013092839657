<script lang="ts">
    // import { onMount, onDestroy } from "svelte";
    import {
        is_admin as is_admin_store,
        is_power as is_power_store,
        is_logged_in as is_logged_in_store,
        username, user_data
    } from "./code/stores";
    import type { IServices } from "./code/services";
    import { UrlHelper } from "./code/utils";

    export let services: IServices;

    let show_sidebar = false;
    let is_admin = $is_admin_store;
    let is_power = $is_power_store;
    let is_logged_in;

    is_admin_store.subscribe((val) => {
        is_admin = val;
    });
    is_power_store.subscribe((val) => {
        is_power = val;
    });
    is_logged_in_store.subscribe((val) => {
        is_logged_in = val;
    });

    function toggleSidebar(): void {
        show_sidebar = !show_sidebar;
    }

    function logout() {
        services.auth.logout();
        location.hash = "/home";
    }
    function logoutAndToggleSidebar() {
        toggleSidebar();
        logout();
    }
</script>

<!-- Sidebar on small screens -->
{#if show_sidebar}
    <div
        class="w3-sidebar w3-bar-block w3-border-right w3-hide-large w3-hide-medium w3-black w3-large z-index-200"
        style="width:100%;"
        id="main_sidebar"
    >
        <button class="w3-bar-item w3-button w3-large" on:click={toggleSidebar}
            >Zapri &times;</button
        >

        <a
            href={UrlHelper.getPageUrl("home")}
            class="w3-bar-item w3-button w3-padding"
            on:click={toggleSidebar}>Termini</a
        >
        {#if is_logged_in}
            <a
                href={UrlHelper.getPageUrl("my_reservations")}
                class="w3-bar-item w3-button w3-padding"
                on:click={toggleSidebar}>Moje rezervacije</a
            >
        {/if}
        {#if is_admin || is_power}
            <a
                href={UrlHelper.getPageUrl("admin")}
                class="w3-bar-item w3-button w3-padding"
                on:click={toggleSidebar}>Admin</a
            >
        {/if}
        <hr />
        {#if !is_logged_in}
            <a
                href={UrlHelper.getPageUrl("login")}
                class="w3-bar-item w3-button w3-padding"
                on:click={toggleSidebar}>Prijava</a
            >
            <!-- <a
                href={UrlHelper.getPageUrl("registration")}
                class="w3-bar-item w3-button w3-padding"
                on:click={toggleSidebar}>Registracija</a
            > -->
        {/if}
        {#if is_logged_in}
            <a
                href={UrlHelper.getPageUrl("messages")}
                class="w3-bar-item w3-button w3-padding"
                on:click={toggleSidebar}>Arhiv sporočil</a
            >
            <a
                href={UrlHelper.getPageUrl("logout")}
                class="w3-bar-item w3-button w3-padding"
                on:click={logoutAndToggleSidebar}>Odjava</a
            >
        {/if}
    </div>
{/if}

<!-- Navbar on medium and big screens -->
<div class="w3-top w3-theme w3-theme-l2 xheader">
    <div class="w3-bar w3-card w3-left-align">
        <a
            href={UrlHelper.getPageUrl("home")}
            class="w3-bar-item w3-button w3-padding w3-theme w3-hover-theme"
        >
            <img
                src="img/najem_pisarne_si_logo.svg"
                alt="logo"
                style="height: 22px;"
                class="w3-margin-left w3-margin-right"
            />
            {#if ($user_data?.runtime_env ?? "unknown") == "dev"}
                <span class="w3-badge">BETA</span>
            {/if}
        </a>
        <!--<a
      href={UrlHelper.getPageUrl("occupancy")}"
      class="w3-bar-item w3-button w3-hide-small w3-padding w3-hover-theme"
    >
      Zasedenost
    </a>-->
        {#if is_logged_in}
            <a
                href={UrlHelper.getPageUrl("my_reservations")}
                class="w3-bar-item w3-button w3-hide-small w3-padding w3-hover-theme"
            >
                Moje rezervacije
            </a>
        {/if}
        {#if is_admin || is_power}
            <a
                href={UrlHelper.getPageUrl("admin")}
                class="w3-bar-item w3-button w3-hide-small w3-padding w3-hover-theme"
            >
                Admin
            </a>
        {/if}

        {#if !is_logged_in}
            <a
                href={UrlHelper.getPageUrl("login")}
                class="w3-bar-item w3-button w3-hide-small w3-padding w3-right w3-hover-theme"
                >Prijava</a
            >
            <!-- <a
                href={UrlHelper.getPageUrl("registration")}
                class="w3-bar-item w3-button w3-hide-small w3-padding w3-right w3-hover-theme"
                >Registracija</a
            > -->
        {/if}

        {#if is_logged_in}
            <div
                class="w3-dropdown-hover w3-hide-small w3-right w3-hover-theme"
            >
                <button class="w3-button w3-hover-theme w3-hover-theme"
                    >O meni</button
                >
                <div
                    class="w3-dropdown-content w3-bar-block w3-card-4 w3-right"
                    style="right:0"
                >
                    <a
                        href={UrlHelper.getPageUrl("my_data")}
                        class="w3-bar-item w3-button w3-hover-theme"
                        >Moji podatki</a
                    >
                    <a
                        href={UrlHelper.getPageUrl("messages")}
                        class="w3-bar-item w3-button w3-hover-theme"
                        >Arhiv sporočil</a
                    >
                    <button
                        class="w3-bar-item w3-button w3-hover-theme"
                        on:click={logout}
                    >
                        Odjava
                    </button>
                </div>
            </div>
        {/if}
        <div class="w3-dropdown-hover w3-hide-small w3-right w3-hover-theme">
            <button class="w3-button w3-hover-theme w3-hover-theme"
                >Pomoč</button
            >
            <div
                class="w3-dropdown-content w3-bar-block w3-card-4 w3-right"
                style="right:0"
            >
                <a
                    href="http://najempisarne.si/pravila.html"
                    class="w3-bar-item w3-button w3-hover-theme"
                    >Pravila in pogoji najema</a
                >
                <a
                    href="http://najempisarne.si/navodila.html"
                    class="w3-bar-item w3-button w3-hover-theme"
                    >Navodila za uporabo</a
                >
                <a
                    href={UrlHelper.getPageUrl("gdpr")}
                    class="w3-bar-item w3-button w3-hover-theme">GDPR</a
                >
            </div>
        </div>

        <button
            class="w3-bar-item w3-button w3-hide-large w3-hide-medium w3-right w3-padding w3-large"
            on:click={toggleSidebar}
            title="Toggle Navigation Menu"
        >
            &#9776;
        </button>
    </div>
</div>

<!-- to accomodate for top header -->
<div style="height: 50px;" />

<style>
    .xheader {
        z-index: 5000;
    }
</style>
