<script lang="ts">
    import { onMount } from "svelte";
    import type { IServices } from "./code/services";
    import { monthName, niceNumber } from "./code/utils";

    import type { IUsersDeletionRatio } from "./code/backend/admin.generated.types";
    import Loader from "./Loader.svelte";

    export let services: IServices;
    export const page_params: any = {};

    let users: IUsersDeletionRatio[] = [];
    let is_loading: boolean = false;
    let month_offset = 0;
    let month_title: string = "";

    async function load() {
        is_loading = true;
        const d = new Date();
        d.setDate(1);
        d.setHours(0, 0, 0, 0);
        d.setMonth(d.getMonth() + month_offset);
        month_title = `${monthName(d.getMonth())} ${d.getFullYear()}`;
        users = (
            await services.backend
                .admin()
                .getUsersDeletionRatio({ month_offset })
        ).items;
        is_loading = false;
    }
    onMount(async () => {
        await load();
    });
    async function decOffset() {
        month_offset--;
        await load();
    }
    async function incOffset() {
        month_offset++;
        await load();
    }
    async function zeroOffset() {
        month_offset = 0;
        await load();
    }
</script>

<div class="w3-container">
    <div class="w3-row">
        <div class="w3-right">
            {month_title}
            <button
                class="w3-button w3-theme-light w3-hover-theme w3-margin-rightx w3-round-xlarge"
                on:click={() => decOffset()}>&#5130;</button
            >
            <button
                class="w3-button w3-theme-light w3-hover-theme w3-margin-rightx w3-round-xlarge"
                on:click={() => incOffset()}>&#5125;</button
            >
            <button
                class="w3-button w3-theme-light w3-hover-theme w3-margin-rightx w3-round-xlarge"
                on:click={() => zeroOffset()}
            >
                <img src="/img/icons8-home-24.svg" height="20" alt="home" />
            </button>
        </div>
    </div>
    <div class="w3-row">
        <div class="w3-col m12 l12 w3-padding-large w3-center">
            <div class="w3-center">
                <Loader visible={is_loading} />
            </div>
            {#if !is_loading}
                <table class="w3-table-all w3-hoverable">
                    <tr>
                        <th>User</th>
                        <th>Vnešeno</th>
                        <th>Brisano</th>
                        <th>Brisano pozno</th>
                        <th>% brisanj</th>
                        <th>% poznih brisanj od vseh</th>
                        <th>% poznih brisanj od brisanih</th>
                    </tr>
                    {#each users as user}
                        <tr>
                            <td>
                                {user.username}
                            </td>
                            <td class="w3-right-align">
                                {niceNumber(user.total_entered, 2)}
                            </td>
                            <td class="w3-right-align">
                                {niceNumber(user.total_deleted, 2)}
                            </td>
                            <td class="w3-right-align">
                                {niceNumber(user.total_deleted_bad, 2)}
                            </td>
                            <td class="w3-right-align">
                                {niceNumber(100 * user.total_deleted_perc, 2)}
                            </td>
                            <td class="w3-right-align">
                                {niceNumber(100 * user.total_deleted_bad_perc, 2)}
                            </td>
                            <td class="w3-right-align">
                                {niceNumber(100 * user.total_deleted_bad_perc2, 2)}
                            </td>
                        </tr>
                    {/each}
                </table>
            {/if}
        </div>
    </div>
</div>
