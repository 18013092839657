/////////////////////////////////////////////////////////////////////
// This code is copied to frontnd, so it has to be plain typescript
/////////////////////////////////////////////////////////////////////
/** All possible user roles */
export const USER_ROLES = {
    "Admin": "Admin",
    // "GroupManualNotify": "GroupManualNotify",
    "Normal": "Normal",
    "Power": "Power",
    "RoomLedina": "RoomLedina",
    "RoomTKS": "RoomTKS",
    "RoomTrubarjeva": "RoomTrubarjeva",
    "Short": "Short"
};
export const GlEntrySourceEnum = {
    /** This entry comes from invoices */
    Invoice: "Invoice",
    /** This entry comes from payments */
    Payment: "Payment",
    /** This entry was entered manually */
    Manual: "Manual"
};
export const GlAccountEnum = {
    /** Net income */
    NetIncome: "NetIncome",
    /** Taxes, payed directly by clients */
    Tax: "Tax"
};
export const UserBalanceWarningStatus = {
    Ok: "Ok",
    Warning: "Warning",
    NoInsert: "NoInsert"
};
// export const VAT_RATE = 0.22;
