<script lang="ts">
    import { onMount } from "svelte";
    import type { IServices } from "./code/services";
    import Loader from "./Loader.svelte";
    import UserDashboardTable from "./UserDashboardTable.svelte";

    import type {
        IReservationHistoryRec,
        IGetReservationsReq, IUserBasicData, IGetReservationsHistoryReq
    } from "./code/backend/admin.generated.types";
    import type { IRoomData } from "./code/backend/nonauthenticated.generated.types";
    import ReservationHistoryTable from "./ReservationHistoryTable.svelte";

    export let services: IServices | null = null;
    export const page_params: any = {};

    let username: string = "";
    let ts_from: string = "";
    let ts_to: string = "";
    let room: string = "-";
    let deleted_filter: "all" | "non-deleted" | "deleted" = "non-deleted";
    let search_type: "simple" | "detailed" = "simple";

    let reservations: IReservationHistoryRec[] = [];

    type Rec = {
        user: IUserBasicData;
        str: string;
    };

    let error_message: string | null = null;
    let is_loading: boolean = false;
    let rooms: IRoomData[] = [];
    let users_orig: IUserBasicData[] = [];
    let users: Rec[] = [];
    let filtered_users: IUserBasicData[] = [];

    onMount(async () => {
        await load();
    });

    async function load() {
        rooms = await services.cache.getRooms();
        users_orig = (await services.backend.admin().getUsers({})).users;
        users = users_orig.map<Rec>(x => ({
            str: (x.email + "#" + x.title + "#" + x.username).toLowerCase(),
            user: x
        }));
        filtered_users = [];
    }

    function changeUserFilter(): void {
        if (username.length < 3) {
            filtered_users = [];
        } else {
            filtered_users = users
                .filter(x => x.str.indexOf(username.trim()) >=0)
                .map(x => x.user);
        }
    }

    function selectUser(u: string): void {
        username = u;
        changeUserFilter();
    }

    function undefinedIfEmpty(s: string): string | undefined {
        if (s == null || s.length == 0) {
            return undefined;
        }
        return s;
    }

    async function doSearchSimple() {
        const req: IGetReservationsReq = {
            include_deleted: deleted_filter != "non-deleted",
            only_deleted: deleted_filter == "deleted",
        };
        if (room != null && room.length > 0 && room != "-") {
            req.room_id = parseInt(room, 10);
        }
        if (username != null && username.length > 0) {
            req.username = username;
        }
        if (ts_from != "") {
            req.from = new Date(ts_from).getTime();
        }
        if (ts_to != "") {
            req.to = new Date(ts_to).getTime();
        }
        const res = await services.backend.admin().getReservations(req);
        reservations = res.reservations;
    }
    async function doSearchDeatiled() {
        const req: IGetReservationsHistoryReq = {
            include_deleted: true,
            only_deleted: false,
        };
        if (room != null && room.length > 0 && room != "-") {
            req.room_id = parseInt(room, 10);
        }
        if (username != null && username.length > 0) {
            req.username = username;
        }
        if (ts_from != "") {
            req.from = new Date(ts_from).getTime();
        }
        if (ts_to != "") {
            req.to = new Date(ts_to).getTime();
        }
        const res = await services.backend.admin().getReservationsHistory(req);
        reservations = res.reservations_history;
    }

    async function doSearch() {
        error_message = null;
        is_loading = true;
        try {
            if (services == null) {
                error_message = "Services not initialized";
                return;
            }
            if (search_type == "simple") {
                await doSearchSimple();
            } else {
                await doSearchDeatiled();
            }
        } finally {
            is_loading = false;
        }
    }
</script>

<div class="w3-container">
    <div class="w3-row">
        <div class="w3-panel w3-third">
            <label for="inDateFrom">Datum od</label>
            <input
                id="inDateFrom"
                class="w3-input w3-border w3-round"
                type="date"
                bind:value={ts_from}
            />
        </div>
        <div class="w3-panel w3-third">
            <label for="inDateTo">Datum do</label>
            <input
                id="inDateTo"
                class="w3-input w3-border w3-round"
                type="date"
                bind:value={ts_to}
            />
        </div>

        <div class="w3-panel w3-third">
            <label for="InSearchType">Način iskanja</label>
            <select
                id="InSearchType"
                class="w3-select w3-border w3-round"
                bind:value={search_type}
            >
                <option value="simple">Enostavno</option>
                <option value="detailed">Podrobne spremembe</option>
            </select>
        </div>
    </div>
    <div class="w3-row">
        <div class="w3-panel w3-third">
            <label for="inRoom">Pisarna</label>
            <select
                id="inRoom"
                class="w3-select w3-border w3-round"
                bind:value={room}
            >
                <option value="-">Vse</option>
                {#each rooms as r}
                    <option value={"" + r.id}>
                        {r.title}
                    </option>
                {/each}
            </select>
        </div>

        <div class="w3-panel w3-third">
            <label for="inUser">Uporabnik</label>
            <input
                id="inUser"
                class="w3-input w3-border w3-round"
                type="text"
                on:change={changeUserFilter}
                on:keyup={changeUserFilter}
                bind:value={username}
            />
            <div>
                {#each filtered_users as user}
                <span
                    class="w3-tag w3-small w3-round w3-theme-action cursor-pointer"
                    on:click={() => selectUser(user.username)}>
                    {user.title} {user.username}
                </span>
                {/each}
            </div>
        </div>

        <div class="w3-panel w3-third">
            {#if search_type == "simple"}
            <label for="inDeleted">Vključi brisane</label>
            <select
                id="inDeleted"
                class="w3-select w3-border w3-round"
                bind:value={deleted_filter}
            >
                <option value="all">Vsi</option>
                <option value="non-deleted">Nebrisani</option>
                <option value="deleted">Samo brisani</option>
            </select>
            {/if}
        </div>
    </div>
    <div class="w3-row">
        <div class="w3-panel">
            <button
                class="w3-btn w3-theme-action w3-round-xlarge"
                on:click={() => doSearch()}>Išči</button
            >
        </div>
        {#if error_message != null}
            <div class="w3-panel w3-pale-yellow w3-border">
                <h3>Opozorilo:</h3>
                <p>{error_message}</p>
            </div>
        {/if}
        <div class="w3-center">
            <Loader visible={is_loading} />
        </div>
        {#if !is_loading}
            {#if search_type == "simple"}
                <UserDashboardTable {reservations} />
            {/if}
            {#if search_type == "detailed"}
                <ReservationHistoryTable {reservations} />
            {/if}
        {/if}
    </div>
</div>
