// tslint:disable:max-line-length
function enc(s) { return encodeURIComponent("" + s); }
export class Admin {
    constructor(client) { this.client = client; }
    /** Get single dummy invoice file. This endpoint is used for testing and should not be called otherwise. */
    async getDummyInvoice(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/invoice/dummy`;
        return (await this.client.get(url, reqx));
    }
    /** Render single invoice again */
    async rerenderSingleInvoice(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/invoice/${enc(req.id)}/re-render`;
        delete reqx.id;
        return (await this.client.post(url, reqx));
    }
    /** Delete invoice record for specified invoice id */
    async deleteInvoice(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/invoice/${enc(req.id)}`;
        delete reqx.id;
        return (await this.client.delete(url, reqx));
    }
    /** Get next invoice number for given year */
    async getNextInvoiceNumberForYear(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/invoices/next-number`;
        return (await this.client.get(url, reqx));
    }
    /** Issues new manual invoice */
    async issueManualInvoice(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/invoices/issue-manual`;
        return (await this.client.post(url, reqx));
    }
    /** Prepares new invoices */
    async prepareNewInvoices(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/invoices/prepare-new`;
        return (await this.client.post(url, reqx));
    }
    /** Renders missing invoices */
    async renderMissingInvoices(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/invoices/render-missing`;
        return (await this.client.post(url, reqx));
    }
    /** Finalizes invoices */
    async finalizeInvoices(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/invoices/finalize`;
        return (await this.client.post(url, reqx));
    }
    /** Downloads a batch of rendered invoices */
    async downloadBatchOfInvoices(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/invoices/download-batch-docs`;
        return (await this.client.post(url, reqx));
    }
    /** Downloads a csv for a batch of invoices */
    async downloadCsvOfInvoices(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/invoices/download-batch-csv`;
        return (await this.client.post(url, reqx));
    }
    /** Get available invoice types */
    async getInvoiceTypes(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/invoice-types`;
        return (await this.client.get(url, reqx));
    }
    /** Search invoices with optional filter criteria such as date range, user or room. */
    async getInvoices(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/invoices`;
        return (await this.client.get(url, reqx));
    }
    /** Get single message for specified id */
    async getSingleMessage(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/messages/${enc(req.id)}`;
        delete reqx.id;
        return (await this.client.get(url, reqx));
    }
    /** Update single message for specified id */
    async updateSingleMessage(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/messages/${enc(req.id)}`;
        delete reqx.id;
        return (await this.client.put(url, reqx));
    }
    /** Get all message */
    async getMessages(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/messages`;
        return (await this.client.get(url, reqx));
    }
    /** Insert new message */
    async insertNewMessage(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/messages`;
        return (await this.client.post(url, reqx));
    }
    /** Insert new payment */
    async insertNewPayment(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/payments/new`;
        return (await this.client.post(url, reqx));
    }
    /** Cancel payment */
    async cancelPayment(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/payments/cancel/${enc(req.id)}`;
        delete reqx.id;
        return (await this.client.post(url, reqx));
    }
    /** Search payments with optional filter criteria, such as user or date range */
    async getPayments(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/payments`;
        return (await this.client.get(url, reqx));
    }
    /** Get the list of latest changes in reservations, such as new additions and deletions */
    async getLatestChanges(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/latest_changes`;
        return (await this.client.get(url, reqx));
    }
    /** Get status of the given background job */
    async getJobStatus(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/jobs/${enc(req.uuid)}`;
        delete reqx.uuid;
        return (await this.client.get(url, reqx));
    }
    /** Get balances for all active users */
    async getUserBalances(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/users/balances`;
        return (await this.client.get(url, reqx));
    }
    /** Get monthly report such as cumulative income, total number of reservations, reservations by room and by user etc. */
    async getMonth(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/month`;
        return (await this.client.get(url, reqx));
    }
    /** Search reservations with criteria such as user, date range or room */
    async getReservations(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/reservations`;
        return (await this.client.get(url, reqx));
    }
    /** Search history of reservation changes. Only used for diagnostics. */
    async getReservationsHistory(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/reservations-history`;
        return (await this.client.get(url, reqx));
    }
    /** Get list of all users */
    async getUsers(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/users`;
        return (await this.client.get(url, reqx));
    }
    /** Insert single new user */
    async insertSingleUser(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/users`;
        return (await this.client.post(url, reqx));
    }
    /** Get deletion ratio for given month for all users */
    async getUsersDeletionRatio(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/users/deletion-ratio`;
        return (await this.client.get(url, reqx));
    }
    /** Recalculates statuses for all users */
    async recalculateAllUserStatuses(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/users/recalc-status`;
        return (await this.client.post(url, reqx));
    }
    /** Gets all available price-lists */
    async getPricelists(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/pricelists`;
        return (await this.client.get(url, reqx));
    }
    /** Get yearly KPI */
    async getKpiYear(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/kpi/year`;
        return (await this.client.get(url, reqx));
    }
    /** Get general ledger content for single user */
    async getSingleUserGl(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/users/${enc(req.user_uuid)}/gl`;
        delete reqx.user_uuid;
        return (await this.client.get(url, reqx));
    }
    /** Get special prices for single user */
    async getSingleUserSpecialPrices(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/users/${enc(req.user_uuid)}/special-prices`;
        delete reqx.user_uuid;
        return (await this.client.get(url, reqx));
    }
    /** Get realization-ratio for single user */
    async getSingleUserRealizationRatio(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/users/${enc(req.user_uuid)}/realization-ratio`;
        delete reqx.user_uuid;
        return (await this.client.get(url, reqx));
    }
    /** Add special price for single user */
    async addSingleUserSpecialPrice(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/users/${enc(req.user_uuid)}/special-prices-add`;
        delete reqx.user_uuid;
        return (await this.client.post(url, reqx));
    }
    /** send JWT token that can be used for impersonation */
    async impersonateUser(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/users/${enc(req.user_uuid)}/impersonate`;
        delete reqx.user_uuid;
        return (await this.client.post(url, reqx));
    }
    /** Remove special price for single user */
    async removeSingleUserSpecialPrice(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/users/${enc(req.user_uuid)}/special-prices-remove`;
        delete reqx.user_uuid;
        return (await this.client.post(url, reqx));
    }
    /** Get password for single user */
    async setUserPwd(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/users/${enc(req.user_uuid)}/set-pwd`;
        delete reqx.user_uuid;
        return (await this.client.post(url, reqx));
    }
    /** Recalculates status for single user */
    async recalculateSingleUserStatus(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/users/${enc(req.user_uuid)}/recalc-status`;
        delete reqx.user_uuid;
        return (await this.client.post(url, reqx));
    }
    /** Get basic information about a single user */
    async getSingleUser(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/users/${enc(req.user_uuid)}`;
        delete reqx.user_uuid;
        return (await this.client.get(url, reqx));
    }
    /** Update single user */
    async updateSingleUser(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/users/${enc(req.user_uuid)}`;
        delete reqx.user_uuid;
        return (await this.client.put(url, reqx));
    }
    /** Convert registration to full user */
    async convertRegistration(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/registrations/${enc(req.id)}/convert`;
        delete reqx.id;
        return (await this.client.post(url, reqx));
    }
    /** Deactivate registration */
    async deactivateRegistration(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/registrations/${enc(req.id)}/deactivate`;
        delete reqx.id;
        return (await this.client.post(url, reqx));
    }
    /** Search registrations */
    async searchRegistrations(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/registrations`;
        return (await this.client.get(url, reqx));
    }
    /** Insert new registration */
    async newRegistration(req) {
        const reqx = req;
        const url = `/api/v1.0/admin/registrations`;
        return (await this.client.post(url, reqx));
    }
}
