<script lang="ts">
    const year = new Date().getFullYear();
    import {
        is_admin as is_admin_store,
        is_logged_in as is_logged_in_store,
        username, user_data
    } from "./code/stores";

</script>

<div style="height: 65px;" />

<footer class="w3-bottom w3-theme-l2 w3-small">
    <p class="w3-center">
        © {year} - NajemPisarne.si - Icons made by <a target="_blank" href="https://icons8.com">Icons8</a>
        {#if $is_admin_store}
        - {($user_data?.runtime_env ?? "unknown") == "dev" ? "beta" : ""}
        {/if}
    </p>
</footer>

<style>
    footer {
        z-index: 5000;
    }

    @media screen and (max-height: 600px) {
        footer {
            display: none;
        }
    }
</style>
