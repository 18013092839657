/** Contants for time-ranges in milliseconds */
export const TIME_RANGES = {
    DAY: 24 * 60 * 60 * 1000,
    HOUR: 60 * 60 * 1000,
    MINUTE: 60 * 1000,
    SECOND: 1000,
    WEEK: 7 * 24 * 60 * 60 * 1000
};
/** This function removes/replaces non-alphanumeric characters */
export function onlyAlphaNum(s, replacement) {
    replacement = replacement || "";
    return s.replace(/[^a-zA-Z0-9]/g, replacement);
}
/** This simple function is await-able wrapper for setTimeout function */
export function setTimeoutAsync(delay) {
    return new Promise(resolve => { setTimeout(resolve, delay); });
}
/** Utility function to convert date to string in format YYYYMMDDHH */
export function date2tag(dd_obj) {
    let tag = dd_obj.getUTCFullYear();
    tag = tag * 100 + (1 + dd_obj.getUTCMonth());
    tag = tag * 100 + dd_obj.getUTCDate();
    tag = tag * 100 + dd_obj.getUTCHours();
    return "" + tag;
}
export function minMaxNormalisation(min, max, val) {
    return (max - val) / (max - min);
}
/** Utility function for rendering numbers */
function pad(num) {
    const norm = Math.floor(Math.abs(num));
    return (norm < 10 ? "0" : "") + norm;
}
const userLang = navigator.language;
export function niceNumber(x, decimals) {
    if (decimals == undefined || decimals < 0) {
        decimals = 0;
    }
    return x.toLocaleString(userLang, { maximumFractionDigits: decimals, minimumFractionDigits: decimals });
    // return new Intl.NumberFormat("sl", { maximumFractionDigits: decimals }).format(x);
    // return Number(Math.round(Number(x + "e" + decimals)) + "e-" + decimals).toFixed(decimals);
}
/** Utility function for rendering date and time to ISO format (in local timezone) */
export function niceDateTime(date) {
    if (typeof date === "number") {
        date = new Date(date);
    }
    const date_str = date.getFullYear() +
        "-" + pad(date.getMonth() + 1) +
        "-" + pad(date.getDate()) +
        " " + pad(date.getHours()) +
        ":" + pad(date.getMinutes()) +
        ":" + pad(date.getSeconds());
    return date_str;
}
/** Utility function for rendering date and time to ISO format (in local timezone), without seconds */
export function niceDateTimeNoSec(date) {
    if (typeof date === "number") {
        date = new Date(date);
    }
    const date_str = date.getFullYear() +
        "-" + pad(date.getMonth() + 1) +
        "-" + pad(date.getDate()) +
        " " + pad(date.getHours()) +
        ":" + pad(date.getMinutes());
    return date_str;
}
/** Utility function for rendering date to ISO format (in local timezone) */
export function niceDate(date) {
    if (typeof date === "number") {
        date = new Date(date);
    }
    // const date_str = date.getFullYear() +
    //     "-" + pad(date.getMonth() + 1) +
    //     "-" + pad(date.getDate());
    const date_str = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    return date_str;
}
/** Utility function for rendering date and time to ISO format (in local timezone) */
export function niceTime(date) {
    if (typeof date === "number") {
        date = new Date(date);
    }
    const date_str = pad(date.getHours()) +
        ":" + pad(date.getMinutes()) +
        ":" + pad(date.getSeconds());
    return date_str;
}
/** Utility function for rendering date and time to ISO format (in local timezone), without seconds */
export function niceTimeNoSec(date) {
    if (typeof date === "number") {
        date = new Date(date);
    }
    const date_str = pad(date.getHours()) +
        ":" + pad(date.getMinutes());
    return date_str;
}
export function toLocalDateTimeString(date) {
    const date_str = date.getFullYear() +
        "-" + pad(date.getMonth() + 1) +
        "-" + pad(date.getDate()) +
        " " + pad(date.getHours()) +
        ":" + pad(date.getMinutes()) +
        ":" + pad(date.getSeconds());
    return date_str;
}
export function toLocalDateString(date) {
    const datetime = toLocalDateTimeString(date);
    return datetime.split(" ")[0];
}
export function stripTimePart(d) {
    const res = new Date(d.getTime());
    res.setMilliseconds(0);
    res.setSeconds(0);
    res.setMinutes(0);
    res.setHours(0);
    return res;
}
/** Creates hourly timestamps */
export function createHourTimestamp(now, n_hours) {
    const d = new Date(now - n_hours * TIME_RANGES.HOUR);
    d.setMinutes(0, 0, 0);
    let dd = d.getTime();
    const timestamps = [];
    while (dd < now) {
        const dd_obj = new Date(dd);
        const tag = date2tag(dd_obj);
        timestamps.push({ ts: dd, tag, val: 0 });
        dd += TIME_RANGES.HOUR;
    }
    return { d, timestamps };
}
export function getStartOfWeek(anchor, week_offset) {
    const date = new Date(anchor.getTime());
    const nDay = (date.getDay() + 6) % 7;
    date.setDate(date.getDate() - nDay /*+ 3*/ + week_offset * 7);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}
export function getStartOfMonth(anchor) {
    const date = new Date(anchor.getTime());
    date.setDate(1);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}
export function addMonth(anchor, offset) {
    const date = new Date(anchor.getTime());
    date.setMonth(date.getMonth() + offset);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}
/** Get week of year */
export function getWeek(dateIn) {
    const date = (dateIn instanceof Date) ? new Date(dateIn.valueOf()) : new Date();
    // ISO week date weeks start on Monday, so correct the day number
    const nDay = (date.getDay() + 6) % 7;
    // ISO 8601 states that week 1 is the week with the first Thursday of that year
    // Set the target date to the Thursday in the target week
    date.setDate(date.getDate() - nDay + 3);
    // Store the millisecond value of the target date
    const n1stThursday = date.valueOf();
    // Set the target to the first Thursday of the year
    // First, set the target to January 1st
    date.setMonth(0, 1);
    // Not a Thursday? Correct the date to the next Thursday
    if (date.getDay() !== 4) {
        date.setMonth(0, 1 + ((4 - date.getDay()) + 7) % 7);
    }
    // The week number is the number of weeks between the first Thursday of the year
    // and the Thursday in the target week (604800000 = 7 * 24 * 3600 * 1000)
    return 1 + Math.ceil((n1stThursday - date.valueOf()) / TIME_RANGES.WEEK);
}
/** Returns monday of the input date week */
export function getMonday(date, hour = 0, minute = 0, second = 0, millisecond = 0) {
    const day = date.getDay() || 7;
    const newdate = new Date(date.getTime());
    newdate.setDate(date.getDate() - day + 1);
    newdate.setHours(hour);
    newdate.setMinutes(minute);
    newdate.setSeconds(second);
    newdate.setMilliseconds(millisecond);
    return newdate;
}
export function weekYear(date) {
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    return date.getFullYear();
}
export function weekNumber(dt) {
    const tdt = new Date(dt.valueOf());
    const dayn = (dt.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    const firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
        tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
    }
    return 1 + Math.ceil((Number(firstThursday) - tdt.getTime()) / TIME_RANGES.WEEK);
}
export function dayString(dayn) {
    switch (dayn) {
        case 0:
            return "Ponedeljek";
        case 1:
            return "Torek";
        case 2:
            return "Sreda";
        case 3:
            return "Četrtek";
        case 4:
            return "Petek";
        case 5:
            return "Sobota";
        case 6:
            return "Nedelja";
        default:
            return "";
    }
}
export function getDateOfISOWeek(week, year) {
    const simple = new Date(year, 0, 1 + (week - 1) * 7);
    const dow = simple.getDay();
    const isoWeekStart = simple;
    if (dow <= 4) {
        isoWeekStart.setDate(simple.getDate() - simple.getDay() + 1);
    }
    else {
        isoWeekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    return isoWeekStart;
}
const thresholds = {
    M: 11,
    d: 26,
    h: 22,
    m: 45,
    s: 45,
    ss: 44 // a few seconds to seconds
};
export function createDurationString(duration_in_msec) {
    const seconds = Math.round(duration_in_msec / 1000);
    const minutes = Math.round(seconds / 60);
    const hours = Math.round(minutes / 60);
    const days = Math.round(hours / 24);
    const months = Math.round(days / 30);
    const years = Math.round(months / 365);
    const a = (seconds <= thresholds.ss && ["s", seconds, "sec"]) ||
        (seconds < thresholds.s && ["ss", seconds, "sec"]) ||
        (minutes <= 1 && ["m", 1, "min"]) ||
        (minutes < thresholds.m && ["mm", minutes, "min"]) ||
        (hours <= 1 && ["h", 1, "h"]) ||
        (hours < thresholds.h && ["hh", hours, "h"]) ||
        (days <= 1 && ["d", 1, "day"]) ||
        (days < thresholds.d && ["dd", days, "days"]) ||
        (months <= 1 && ["M", 1, "month"]) ||
        (months < thresholds.M && ["MM", months, "months"]) ||
        (years <= 1 && ["y", 1, "year"]) ||
        ["yy", years, "years"];
    return a[1] + " " + a[2];
}
/** Make first character upper case. */
export function capitalize(word) {
    if (!word) {
        return word;
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
}
/** Simple and fast function that replaces <b> and <i> HTML tags from string */
export function stripKnownHtmlTags(s) {
    if (!s) {
        return s;
    }
    return s
        .replace(/<(\/)?b>/gi, "")
        .replace(/<(\/)?i>/gi, "")
        .replace(/<(\/)?strong>/gi, "")
        .replace(/<(\/)?span>/gi, "")
        .replace(/\&nbsp\;/gi, "");
}
/** Utility class for combining two values */
export class Pair {
}
/** Utility class for combining three values */
export class Triplet {
}
/**
 * Creates a pseudo-random value generator. The seed must be an integer.
 *
 * Uses an optimized version of the Park-Miller PRNG.
 * http://www.firstpr.com.au/dsp/rand31/
 */
export class PseudoRandom {
    constructor(seed) {
        this._seed = seed % 2147483647;
        if (this._seed <= 0) {
            this._seed += 2147483646;
        }
    }
    /** Returns a pseudo-random value between 1 and 2^32 - 2. */
    next() {
        return this._seed = this._seed * 16807 % 2147483647;
    }
    /** Returns a pseudo-random floating point number in range [0, 1). */
    nextFloat() {
        // We know that result of next() will be 1 to 2147483646 (inclusive).
        return (this.next() - 1) / 2147483646;
    }
}
/** This method ensures all given fields are present (non-null) on given object and copies them into destination */
export function ensureAllFieldsPresentAndCopy(obj, destination, fields) {
    const matched_fields = fields
        .filter(field => !!obj[field]) // which target fields exist in obj
        .length;
    if (matched_fields != fields.length) {
        // not all fields are present
        return false;
    }
    // transfer data to destination
    fields.forEach(field => destination[field] = obj[field]);
    return true;
}
/** Recursively freezes an object, while checking freeze status to prevent endless loops.
 *  Kudos: https://stackoverflow.com/questions/34776846/how-to-freeze-nested-objects-in-javascript
 */
export function deepFreeze(obj) {
    Object.freeze(obj);
    if (obj === undefined) {
        return obj;
    }
    Object.getOwnPropertyNames(obj).forEach(prop => {
        if (obj[prop] !== null
            && (typeof obj[prop] === "object" || typeof obj[prop] === "function")
            && !Object.isFrozen(obj[prop])) {
            deepFreeze(obj[prop]);
        }
    });
    return obj;
}
export function unique(data) {
    return [...new Set(data)];
}
export function sum(data) {
    let sum = 0;
    for (const x of data) {
        sum += x;
    }
    return sum;
}
export function hasTag(tags, tag_list) {
    for (const tag of tag_list) {
        if (tags[tag.name] === tag.value) {
            return true;
        }
    }
    return false;
}
///////////////////////////////////////////////////////////////////////////
//
export class UrlHelper {
    static getUrlParams() {
        const hash = window.location.hash.substring(1);
        return hash.split('&').reduce((res, item) => {
            var parts = item.split("=");
            res[parts[0]] = decodeURIComponent(parts[1]);
            return res;
        }, {});
    }
    static createUrlParamsString(data) {
        const p = new URLSearchParams();
        for (const key of Object.keys(data)) {
            if (data[key] == undefined) {
                continue;
            }
            p.set(key, data[key]);
        }
        return p.toString();
    }
    static setUrlParams(data) {
        window.location.hash = UrlHelper.createUrlParamsString(data);
    }
    static getPageUrl(page) {
        return "/#" + UrlHelper.createUrlParamsString({ page });
    }
    static getOpenUserUrlInner(user_uuid) {
        return UrlHelper.createUrlParamsString({ page: "user", user_uuid });
    }
    static getOpenUserUrl(user_uuid) {
        return "/#" + UrlHelper.getOpenUserUrlInner(user_uuid);
    }
    static getRegistrationUrl(id) {
        return "/#" + UrlHelper.createUrlParamsString({ page: "registration", id: "" + id });
    }
    static getAdminSubpageUrlInner(subpage) {
        return UrlHelper.createUrlParamsString({ page: "admin", subpage });
    }
    static getAdminSubpageUrl(subpage) {
        return "/#" + UrlHelper.getAdminSubpageUrlInner(subpage);
    }
    static openAdminSubpage(subpage) {
        window.location.hash = UrlHelper.getAdminSubpageUrlInner(subpage);
    }
    static openMyReservationsSubpage(subpage) {
        window.location.hash = UrlHelper.createUrlParamsString({ page: "my_reservations", subpage });
    }
    static openUser(user_uuid) {
        window.location.hash = UrlHelper.getOpenUserUrlInner(user_uuid);
    }
    static openUserEdit(user_uuid) {
        window.location.hash = UrlHelper.createUrlParamsString({ page: "user-edit", user_uuid });
    }
    static openUserNew() {
        window.location.hash = UrlHelper.createUrlParamsString({ page: "user-edit", user_uuid: "new", create_new: true });
    }
    static openFileForToken(token) {
        window.open(`/api/v1.0/nonauthenticated/dl?id=${encodeURIComponent(token)}`);
    }
    static createInvoiceUrl(id) {
        return `/api/v1.0/invoices/${id}/raw`;
    }
    static newReservation(current_office, week_offset, day_offset, hour) {
        const data = {
            current_office,
            page: "edit_reservation",
            week_offset
        };
        if (day_offset != undefined) {
            data.day_offset = day_offset;
        }
        if (hour != undefined) {
            data.hour = hour;
        }
        window.location.hash = UrlHelper.createUrlParamsString(data);
    }
    static getEditReservationUrlInner(id) {
        return UrlHelper.createUrlParamsString({
            id,
            page: "edit_reservation"
        });
    }
    static getEditReservationUrl(id) {
        return "/#" + UrlHelper.getEditReservationUrlInner(id);
    }
    static editReservation(id) {
        window.location.hash = UrlHelper.getEditReservationUrlInner(id);
    }
    static openHome(current_office, week_offset) {
        window.location.hash = UrlHelper.createUrlParamsString({
            current_office,
            page: "home",
            week_offset
        });
    }
}
;
export class CssHelpers {
    static getCssClassForRoomId(id) {
        switch (id) {
            case 1: return "w3-pale-purple";
            case 2: return "w3-pale-yellow";
            case 4: return "w3-pale-green";
            default: return "w3-sand";
        }
    }
    static getCssClassForRoomTitle(title) {
        switch (title) {
            case "Ledina": return CssHelpers.getCssClassForRoomId(1);
            case "Trubarjeva": return CssHelpers.getCssClassForRoomId(2);
            case "TKS": return CssHelpers.getCssClassForRoomId(4);
            default: return CssHelpers.getCssClassForRoomId(-1);
        }
    }
    static getCssClassForUserStatus(status) {
        status = status.trim().toLowerCase();
        if (status == "ok")
            return "w3-green";
        if (status == "warning")
            return "w3-orange";
        return "w3-red";
    }
    static getCssClassForUserActive(is_active) {
        return is_active ? "w3-green" : "w3-theme-l2";
    }
    static getCssClassForBalance(balance) {
        return balance >= 0 ? "w3-green-lite" : "w3-red-lite";
    }
    static getCssClassForUserVat(is_vat) {
        return is_vat ? "w3-orange" : "w3-theme-l2";
    }
    static getCssClassForInvoice(finalized, external_id2) {
        if (!finalized) {
            return "w3-blue-lite";
        }
        if (external_id2 == null) {
            return "w3-orange-lite";
        }
        return "w3-green-lite";
    }
    static getTagTitleForInvoice(finalized, external_id2) {
        if (!finalized) {
            return "V pripravi";
        }
        if (external_id2 == null) {
            return "Neizdana";
        }
        return "Izdana";
    }
}
///////////////////////////////////////////////////////////////////////////
export function scaleLinear(input_min, input_max, output_min, output_max) {
    const in_range = (input_max - input_min);
    const out_range = (output_max - output_min);
    return (x) => {
        const rel = (x - input_min) / in_range;
        return output_min + rel * out_range;
    };
}
export class NumberParser {
    constructor(locale) {
        const format = new Intl.NumberFormat(locale);
        const parts = format.formatToParts(12345.6);
        const numerals = Array.from({ length: 10 }).map((_, i) => format.format(i));
        const index = new Map(numerals.map((d, i) => [d, i]));
        this._group = new RegExp(`[${parts.find(d => d.type === "group").value}]`, "g");
        this._decimal = new RegExp(`[${parts.find(d => d.type === "decimal").value}]`);
        this._numeral = new RegExp(`[${numerals.join("")}]`, "g");
        this._index = d => "" + index.get(d);
    }
    parse(s) {
        return (s = s.trim()
            .replace(this._group, "")
            .replace(this._decimal, ".")
            .replace(this._numeral, this._index)) ? +s : NaN;
    }
}
export class NumberParserSi {
    parse(s) {
        return (s = s.trim()
            .replace(/\./g, "")
            .replace(/\,/g, ".")) ? +s : NaN;
    }
}
export function monthName(month_zero_based) {
    return [
        "januar",
        "februar",
        "marec",
        "april",
        "maj",
        "junij",
        "julij",
        "avgust",
        "september",
        "oktober",
        "november",
        "december"
    ][month_zero_based];
}
/** Utility function to group elements of array by some custom key that is derived from each item.
 * Inspired by https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/groupBy
 *
 * @param data Array to be partitioned
 * @param map Mapping function that extracts key to group by entirely from array-item itself
 */
export function groupBy(data, map) {
    var _a;
    const res = new Map();
    for (const x of data) {
        const key = map(x);
        const existing = (_a = res.get(key)) !== null && _a !== void 0 ? _a : [];
        existing.push(x);
        res.set(key, existing);
    }
    return res;
}
/** Utility function to create a map from given array, using a mapping function to create key from the item
 *
 * @param data Array to be transformed into map
 * @param map Mapping function that extracts key for map entirely from array-item itself
 */
export function createMap(data, map) {
    const res = new Map();
    for (const x of data) {
        const key = map(x);
        res.set(key, x);
    }
    return res;
}
/** Regex for email validation */
const email_regex = /^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/gm;
/** Utility function that validates if email is in correct format */
export function validateEmail(s) {
    return email_regex.test(s);
}
