import { USER_ROLES } from "./consts.generated";
export class CacheService {
    constructor(backend) {
        this.backend = backend;
        this.cache_loaded = false;
        this.rooms = [];
    }
    async getRooms() {
        if (!this.cache_loaded) {
            this.rooms = (await this.backend.nonauthenticated().getOffices({})).rooms;
            this.cache_loaded = true;
        }
        return this.rooms;
    }
    async getAllowedRooms(roles) {
        let res = await this.getRooms();
        // admin can see everything
        if (!roles.includes(USER_ROLES.Admin)) {
            res = res.filter(x => roles.includes(x.role_name));
        }
        return res;
    }
}
