<script lang="ts">
    import { onMount } from "svelte";
    import SvelteMarkdown from "svelte-markdown";

    import type { IServices } from "./code/services";
    import type { IMessageRec } from "./code/backend/users.generated.types";
    import { niceDate, niceDateTime, niceDateTimeNoSec, niceTimeNoSec } from "./code/utils";

    export let services: IServices;
    export const page_params: any = {};

    let msgs: IMessageRec[] = [];

    onMount(async () => {
        await load();
    });

    async function load() {
        const { messages } = await services.backend.users().getMessagesForCurrentUser({
            include_read: true
        });
        msgs = messages;
    }

    async function setMessageAsRead(id: string) {
        await services.backend.users().markMessageAsReadByCurrentUser({ message_id: id });
        await load();
    }

</script>

<div class="w3-container">
    <div class="w3-row">
        <div class="w3-col l3 m2 s12">&nbsp;</div>
        <div class="w3-col l6 m8 s12 w3-panel w3-round">
            <h1>Arhiv sporočil</h1>
            {#each msgs as msg, i}
                <div class="w3-panel w3-light-gray w3-padding w3-round">
                    <h3>{msg.title}</h3>
                    <div class="small-gray">Objavljeno: {niceDate(msg.created_at)}</div>
                    {#if msg.read_at != null}
                        <div class="small-gray">Prebrano: {niceDate(msg.read_at)} {niceTimeNoSec(msg.read_at)}</div>
                    {/if}
                    <SvelteMarkdown source={msg.text} />
                    {#if msg.read_at == null}
                        <div class="w3-center">
                            <button
                                class="w3-button w3-round-large w3-theme-action right-margin-small"
                                on:click={() => setMessageAsRead(msg.id)}
                            >
                                Označi kot prebrano
                            </button>
                        </div>
                    {/if}
                </div>
            {/each}
        </div>
    </div>
</div>
