<script lang="ts">
    import { onMount } from "svelte";
    import { scaleLinear } from "./code/utils";

    export let labels: string[];
    // export let series: string[];
    export let values: number[][];

    const HEIGHT = 200;
    const paddings = {
        bar_lr: 2
    };

    const margins = { top: 10, bottom: 10, left: 10, right: 10 };

    class Rect {
        x: number;
        y: number;
        w: number;
        h: number;
        c: number;
        v: number;
        cls: string;
    }

    let rects: Rect[] = [];
    let zero_y: number = 0;
    let y_ticks: number[] = [];
    let yy_ticks: number[] = [];

    onMount(async () => {
        load();
    });

    function calculateTicks(
        min: number,
        max: number,
        tickCount: number
    ): number[] {
        var span = max - min,
            step = Math.pow(
                10,
                Math.floor(Math.log(span / tickCount) / Math.LN10)
            ),
            err = (tickCount / span) * step;

        // Filter ticks to get closer to the desired count.
        if (err <= 0.15) step *= 10;
        else if (err <= 0.35) step *= 5;
        else if (err <= 0.75) step *= 2;

        // Round start and stop values to step interval.
        var tstart = Math.ceil(min / step) * step,
            tstop = Math.floor(max / step) * step + step * 0.5,
            ticks = [];

        // now generate ticks
        for (let i = tstart; i < tstop; i += step) {
            ticks.push(i);
        }
        return ticks;
    }

    function load(): void {
        if (values.length == 0) {
            rects = [];
            return;
        }
        const max = Math.max(1, ...values.map((x) => Math.max(...x)));
        const min = Math.min(-1, ...values.map((x) => Math.min(...x)));

        const scl = scaleLinear(
            min,
            max,
            0,
            HEIGHT - margins.top - margins.bottom
        );
        const zero = scl(0);
        const map = (y: number) => HEIGHT - margins.bottom - y;
        zero_y = map(zero);
        const w1 = 100 / values[0].length;
        let xrects: Rect[] = [];
        for (let c = 0; c < values.length; c++) {
            const v1 = values[c];
            for (let i = 0; i < v1.length; i++) {
                const v = v1[i];
                const sclv = scl(v);
                let y = 0;
                const h = Math.abs(v) * scl(min + 1);
                if (v > 0) {
                    y = map(sclv);
                } else {
                    y = zero_y;
                }
                xrects.push({
                    c,
                    w: w1 * 0.9,
                    x: i * w1,
                    y,
                    h,
                    v,
                    cls: "bc-series" + c,
                });
            }
        }
        rects = xrects;
        y_ticks = calculateTicks(min, max, 5);
        yy_ticks = y_ticks.map((x) => map(scl(x)));
    }
</script>

<svg width="100%" height="{HEIGHT}px">
    {#each y_ticks as y_tick, i}
        <line
            x1="0"
            y1={yy_ticks[i]}
            x2="100%"
            y2={yy_ticks[i]}
            style="stroke:rgb(220,220,220);stroke-width:1"
        />
    {/each}
    {#each rects as rect}
        <rect
            class={rect.cls}
            width="{rect.w}%"
            height={rect.h}
            x="{rect.x}%"
            y={rect.y}
        >
            <title>{rect.v}</title>
        </rect>
    {/each}
    {#each labels ?? [] as label, i}
        <text
            text-anchor="middle"
            font-size="10"
            dy=".9em"
            y={zero_y}
            x="{(100 * (i + 0.5)) / labels.length}%"
            style="fill:rgb(200,200,200);"
        >
            {label}
        </text>
    {/each}
    <line
        x1="0"
        y1={margins.top}
        x2="0"
        y2={HEIGHT - margins.bottom}
        style="stroke:rgb(0,0,0);stroke-width:1"
    />
    <line
        x1="0"
        y1={zero_y}
        x2="100%"
        y2={zero_y}
        style="stroke:rgb(0,0,0);stroke-width:1"
    />
    {#each y_ticks as y_tick, i}
        <text
            text-anchor="middle"
            font-size="10"
            dx="1.5em"
            y={yy_ticks[i]}
            x="0"
            style="fill:rgb(200,200,200);"
        >
            {y_tick}
        </text>
        <line
            x1="0"
            y1={yy_ticks[i]}
            x2="5"
            y2={yy_ticks[i]}
            style="stroke:rgb(220,220,220);stroke-width:1"
        />
    {/each}
</svg>
