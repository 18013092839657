<script lang="ts">
    import { onMount, beforeUpdate } from "svelte";
    import type { IServices } from "./code/services";
  import { UrlHelper } from "./code/utils";
    import Loader from "./Loader.svelte";

    export let services: IServices;
    export const page_params: any = {};

    let error_message: string | null = null;
    let token = "";
    let loading: boolean = false;
    let done: boolean = false;
    let p: string = "";
    let p2: string = "";

    onMount(async () => {
        error_message = null;
        done = false;
        token = UrlHelper.getUrlParams().token ?? "";
        try {
            await services.backend.users().forgotPasswordStart2({token});
        }catch(err) {
            error_message = err.message;
        }
    });

    async function resetPasswords() {
        try {
            error_message = null;
            done = false;
            loading = true;
            await services.backend.users().forgotPasswordStart3({p, token});
            done = true;
            loading = false;
        } catch (err) {
            error_message = err.message;
        }
    }
</script>

<div class="w3-display-container w3-display-middle">
    <div class="w3-container">
        <div class="w3-panel">
            <h2>Ponastavitev gesla</h2>
        </div>
    </div>

    <div class="w3-center">
        <Loader visible={loading} />
    </div>

    {#if !done && error_message == null}
        <div class="w3-container">
            <div class="w3-panel">
                <p>
                    Vnesite vaše novo geslo, ga novoite v drugi okvirček
                    ter nato kliknite "Naprej".
                </p>
            </div>

            <div class="w3-panel">
                <label for="loginP">Geslo</label>
                <input
                    id="loginP"
                    class="w3-input w3-border"
                    type="password"
                    bind:value={p}
                />
            </div>

            <div class="w3-panel">
                <label for="loginP2">Ponovite geslo</label>
                <input
                    id="loginP2"
                    class="w3-input w3-border"
                    type="password"
                    bind:value={p2}
                />
            </div>

            <div class="w3-panel w3-right-align">
                <button
                    class="w3-btn w3-theme-action w3-round-xlarge"
                    on:click={() => resetPasswords()}>Nastavi geslo</button
                >
            </div>
        </div>
    {/if}
    {#if done && error_message == null}
        <div class="w3-panel">
            <p>
                Vaše geslo je bilo uspešno spremenjeno.
            </p>
            <p>
                Prosimo, uporabite ga za vpis na
                <a href={UrlHelper.getPageUrl("login")}>vpisni strani</a>.
            </p>
        </div>
    {/if}
    {#if error_message != null}
        <div class="w3-panel w3-pale-yellow">
            <p>{error_message}</p>
        </div>
    {/if}
</div>


