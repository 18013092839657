<script lang="ts">
    export let target_sort_col: string = "";
    export let sort_col: string = "";
    export let sort_asc: boolean = true;
    export let onClick: () => void = ()=>{};
</script>
<span on:click={onClick} class="cursor-pointer">
    {#if target_sort_col != sort_col} &varr; {/if}
    {#if target_sort_col == sort_col && sort_asc} &darr; {/if}
    {#if target_sort_col == sort_col && !sort_asc} &uarr; {/if}
</span>
