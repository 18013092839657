import { Nonauthenticated } from "./backend/nonauthenticated.generated.api";
import { Users } from "./backend/users.generated.api";
import { create } from "./rest";
import { Reservations } from "./backend/reservations.generated.api";
import { Admin } from "./backend/admin.generated.api";
export class BackendService {
    constructor() {
        this.rest_client = create();
        this.internal_nonauthenticated = new Nonauthenticated(this.rest_client);
        this.internal_users = new Users(this.rest_client);
        this.internal_reservations = new Reservations(this.rest_client);
        this.internal_admin = new Admin(this.rest_client);
    }
    nonauthenticated() {
        return this.internal_nonauthenticated;
    }
    users() {
        return this.internal_users;
    }
    reservations() {
        return this.internal_reservations;
    }
    admin() {
        return this.internal_admin;
    }
}
