<script lang="ts">
    import { onMount } from "svelte";
    import type { IServices } from "./code/services";
    import { UrlHelper, monthName, niceNumber, CssHelpers } from "./code/utils";

    import type {
        IGetMonthReq, IGetMonthRes
     } from "./code/backend/admin.generated.types";
    import Loader from "./Loader.svelte";
    import BarChart from "./BarChart.svelte";
    import SortIcon from "./SortIcon.svelte";

    export let services: IServices;
    export const page_params: any = {};
    let error_message: string | null = null;
    let is_loading: boolean = false;
    let offset: number = 0;
    let data: IGetMonthRes | null = null;
    let month_title: string = "";
    let chart_data: number[][] = [];
    let labels: string[] = [];
    let room_filter_id: number | null = null;
    let room_filter_text: string | null = null;

    type SortColumn =  "username" | "title" | "amt";
    let sort_column : SortColumn = "username";
    let sort_asc: boolean = true;

    onMount(async () => {
        await load();
    });

    async function load(): Promise<void> {
        error_message = null;
        is_loading = true;
        data = null;
        try {
            const d = new Date();
            d.setDate(1);
            d.setHours(0, 0, 0, 0);
            d.setMonth(d.getMonth() + offset);
            month_title = `${monthName(d.getMonth())} ${d.getFullYear()}`;
            const req: IGetMonthReq = {offset};
            if (room_filter_id != null) {
                req.room_id = room_filter_id;
            }
            data = await services.backend.admin().getMonth(req);
            is_loading = false;
            chart_data = [[], []];
            labels = [];
            const today_idx =
                offset > 0 ? 0 : offset < 0 ? 100 : new Date().getDate();
            for (const r of data.by_day) {
                chart_data[r.day >= today_idx ? 1 : 0].push(0);
                chart_data[r.day < today_idx ? 1 : 0].push(r.amt);
                labels.push("" + r.day);
            }
        } catch (err) {
            is_loading = false;
            error_message = err.message;
        }
    }
    async function decOffset() {
        offset--;
        await load();
    }
    async function incOffset() {
        offset++;
        await load();
    }
    async function zeroOffset() {
        offset = 0;
        await load();
    }
    async function setRoomFilter(id: number, text: string) {
        room_filter_id = id;
        room_filter_text = text;
        await load();
    }
    async function clearRoomFilter() {
        room_filter_id = null;
        await load();
    }
    function sortClick(col: SortColumn): void {
        if (col == sort_column) {
            sort_asc = !sort_asc;
        } else {
            sort_column = col;
            sort_asc = true;
        }
        const sort_factor = sort_asc ? 1 : -1;
        if (sort_column == "username") {
            data.users = data.users.sort((a,b) => sort_factor * a.username.localeCompare(b.username));
        } else if (sort_column == "title") {
            data.users = data.users.sort((a,b) => sort_factor * a.title.localeCompare(b.title));
        } else if (sort_column == "amt") {
            data.users = data.users.sort((a,b) => sort_factor * (a.amt - b.amt));
        }
    }
</script>

<div class="w3-container">
    <div class="w3-row">
        <div class="w3-right">
            {month_title}
            <button
                class="w3-button w3-theme-light w3-hover-theme w3-margin-rightx w3-round-xlarge"
                on:click={() => decOffset()}>&#5130;</button
            >
            <button
                class="w3-button w3-theme-light w3-hover-theme w3-margin-rightx w3-round-xlarge"
                on:click={() => incOffset()}>&#5125;</button
            >
            <button
                class="w3-button w3-theme-light w3-hover-theme w3-margin-rightx w3-round-xlarge"
                on:click={() => zeroOffset()}>
                <img src="/img/icons8-home-24.svg" height="20" alt="home" />
            </button>
        </div>
        {#if error_message != null}
            <div class="w3-panel w3-pale-yellow w3-border">
                <h3>Opozorilo:</h3>
                <p>{error_message}</p>
            </div>
        {/if}
        <div class="w3-center">
            <Loader visible={is_loading} />
        </div>
        {#if !is_loading && data != null}
            <div class="w3-container">
                <div class="w3-third w3-padding">
                    <div class="w3-panel w3-card-4 w3-padding">
                        <h3>Prihodki</h3>
                        <div>
                            {niceNumber(data.aggr.amt, 2)}
                        </div>
                    </div>
                </div>
                <div class="w3-third w3-padding">
                    <div class="w3-panel w3-card-4 w3-padding">
                        <h3>Realizirano</h3>
                        <div>
                            {niceNumber(data.aggr.amt_realized, 2)}
                        </div>
                    </div>
                </div>
                <div class="w3-third w3-padding">
                    <div class="w3-panel {data.aggr.amt >= data.aggr.amt_costs ? "w3-green": "w3-red"} w3-card-4 w3-padding">
                        <h3>Profit</h3>
                        <div>
                            {niceNumber(
                                data.aggr.amt - data.aggr.amt_costs,
                                2
                            )}
                        </div>
                    </div>
                </div>
                <!--
                <div class="w3-third w3-padding">
                    <div class="w3-panel w3-card-4 w3-padding">
                        <h3>Brez popusta</h3>
                        <div>{niceNumber(data.aggr.amt_no_discount, 2)}</div>
                    </div>
                </div>
                -->
                <!-- <div class="w3-third w3-padding">
                    <div class="w3-panel w3-card-4 w3-padding">
                        <h3>Popust</h3>
                        <div>
                            {niceNumber(
                                data.aggr.amt_no_discount - data.aggr.amt,
                                2
                            )}
                            ({niceNumber(
                                (100 *
                                    (data.aggr.amt_no_discount -
                                        data.aggr.amt)) /
                                    data.aggr.amt_no_discount,
                                2
                            )} %)
                        </div>
                    </div>
                </div> -->
                <!--
                <div class="w3-third w3-padding">
                    <div class="w3-panel w3-card-4 w3-padding">
                        <h3>Popust %</h3>
                        <div>
                            {niceNumber(
                                (100 *
                                    (data.aggr.amt_no_discount -
                                        data.aggr.amt)) /
                                    data.aggr.amt_no_discount,
                                2
                            )} %
                        </div>
                    </div>
                </div>
                <div class="w3-third w3-padding">
                    <div class="w3-panel w3-card-4 w3-padding">
                        <h3>Realizirano</h3>
                        <div>{niceNumber(data.aggr.amt_realized, 2)}</div>
                    </div>
                </div>
                -->
            </div>
            <!--
            <table
                style="border: solid 1px #eee; padding: 10px; font-size: 1.2em; width: 100%; amax-width:700px;"
            >
                <tbody
                    ><tr style="background-color: #eee">
                        <th>Prihodki</th>
                        <th>Profit</th>
                        <th>Brez popusta</th>
                        <th>Popust</th>
                        <th>Popust %</th>
                        <th>Prihodki <br /> realizirani</th>
                    </tr>
                    <tr>
                        <td class="w3-right-align">
                            {niceNumber(data.aggr.amt, 2)}
                        </td>
                        <td class="w3-right-align">
                            {niceNumber(
                                data.aggr.amt - data.aggr.amt_costs,
                                2
                            )}
                        </td>
                        <td class="w3-right-align">
                            {niceNumber(data.aggr.amt_no_discount, 2)}
                        </td>
                        <td class="w3-right-align">
                            {niceNumber(
                                data.aggr.amt_no_discount - data.aggr.amt,
                                2
                            )}
                        </td>
                        <td class="w3-right-align">
                            {niceNumber(
                                (100 *
                                    (data.aggr.amt_no_discount -
                                        data.aggr.amt)) /
                                    data.aggr.amt_no_discount,
                                2
                            )} %
                        </td>
                        <td class="w3-right-align">
                            {niceNumber(data.aggr.amt_realized, 2)}
                        </td>
                    </tr>
                </tbody>
            </table>
            -->
            <BarChart values={chart_data} {labels} />
            <h2>Pisarne</h2>
            {#if room_filter_id != null}
            <div>
                Filter: {room_filter_text} <button class="w3-button-link" on:click={() => clearRoomFilter() }>Pobriši</button>
            </div>
            {/if}
            <table class="w3-table-all w3-hoverable">
                <tr>
                    <th>Pisarna</th>
                    <th class="w3-right-align">Znesek</th>
                    <th class="w3-right-align">Brez popusta</th>
                    <th class="w3-right-align">Popust</th>
                    <th class="w3-right-align">Popust %</th>
                </tr>
                {#each data.rooms as room}
                    <tr>
                        <td
                            on:click={() => setRoomFilter(room.id, room.title) }
                            class="{CssHelpers.getCssClassForRoomId(room.id)}">
                            {room.title}
                        </td>
                        <td class="w3-right-align">
                            {niceNumber(room.amt, 2)}
                        </td>
                        <td class="w3-right-align">
                            {niceNumber(room.amt_no_discount, 2)}
                        </td>
                        <td class="w3-right-align">
                            {niceNumber(room.amt_no_discount - room.amt, 2)}
                        </td>
                        <td class="w3-right-align">
                            {niceNumber(
                                (100 * (room.amt_no_discount - room.amt)) /
                                    (room.amt_no_discount || 1),
                                2
                            )} %
                        </td>
                    </tr>
                {/each}
            </table>
            <h2>Uporabniki</h2>
            <table class="w3-table-all w3-hoverable">
                <tr>
                    <th>
                        Uporabnik
                        <SortIcon
                            target_sort_col={"username"}
                            sort_col={sort_column} sort_asc={sort_asc}
                            onClick={() => sortClick("username") }
                            />
                    </th>
                    <th>
                        Ime
                        <SortIcon
                            target_sort_col={"title"}
                            sort_col={sort_column} sort_asc={sort_asc}
                            onClick={() => sortClick("title") }
                            />
                    </th>
                    <th>
                        Znesek
                        <SortIcon
                            target_sort_col={"amt"}
                            sort_col={sort_column} sort_asc={sort_asc}
                            onClick={() => sortClick("amt") }
                            />
                    </th>
                    <th>Brez popusta</th>
                    <th>Popust</th>
                    <th>Popust %</th>
                    <th>Brisano</th>
                    <th>Realizirano</th>
                </tr>
                {#each data.users as user}
                    <tr>
                        <td>
                            <a href={UrlHelper.getOpenUserUrl(user.user_uuid)}>
                                {user.username}
                            </a>
                        </td>
                        <td> {user.title} </td>
                        <td class="w3-right-align">
                            {niceNumber(user.amt, 2)}
                        </td>
                        <td class="w3-right-align">
                            {niceNumber(user.amt_no_discount, 2)}
                        </td>
                        <td class="w3-right-align">
                            {niceNumber(user.amt_no_discount - user.amt, 2)}
                        </td>
                        <td class="w3-right-align">
                            {niceNumber(100 * (user.amt_no_discount - user.amt) / user.amt_no_discount, 2)}
                        </td>
                        <td class="w3-right-align">
                            {niceNumber(user.amt_deleted, 2)}
                        </td>
                        <td class="w3-right-align">
                            {niceNumber(user.amt_realized, 2)}
                        </td>
                    </tr>
                {/each}
            </table>
        {/if}
    </div>
</div>
