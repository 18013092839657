<script lang="ts">
    import { onMount, beforeUpdate } from "svelte";
    import type { IServices } from "./code/services";
    import { CssHelpers, niceDate, niceNumber, UrlHelper } from "./code/utils";
    import { user_data } from "./code/stores";

    import PropertyList from "./PropertyList.svelte";
    import type { PropertyListItem } from "./code/gui_classes";
    import UserDashboardTable from "./UserDashboardTable.svelte";
    import Loader from "./Loader.svelte";
    import Barchart from "./BarChart.svelte";

    import type {
        IUserBasicData,
        IReservationHistoryRec,
        IGlRec,
        ISingleUserSpecialPrice,
        IGetKpiYearRes,
        IGetSingleUserRealizationRatioRes,
        IPaymentData,
        IInvoiceData,
        IInvoiceType,
    } from "./code/backend/admin.generated.types";
    import {
        UserBalanceWarningStatus,
        USER_ROLES,
    } from "./code/consts.generated";
    import LoaderSmall from "./LoaderSmall.svelte";

    export let services: IServices;
    export const page_params: any = {};

    const DIV_MODAL_PWD = "div_user_set_pwd";
    const DIV_MODAL_STATUS = "div_user_recalc_status";
    const DIV_MODAL_MANUAL_INVOICE = "div_manual_invoice";

    let user_uuid: string = "";
    let user: IUserBasicData | null = null;
    let is_loading: boolean = false;

    let is_error: boolean = false;
    let error_msg: string = "";
    let is_success: boolean = false;
    let is_recalc_status: boolean = false;
    let is_issuing_invoice: boolean = false;
    let is_issuing_invoice_backend: boolean = false;
    let is_rerendering_invoice: boolean = false;
    let is_set_pwd: boolean = false;
    let new_pwd: string = "";
    let new_pwd2: string = "";
    let pwd_err: string = "";
    let pwd_status: string = "";
    let recalc_status_status: string = "";
    let recalc_status_err: string = "";
    let props: PropertyListItem[] = [];
    // let next_invoice_number: string = "";

    let roles_all: string[] = Object.keys(USER_ROLES)
        .sort()
        .filter((x) => x != USER_ROLES.Admin);
    let roles_rooms: string[] = roles_all.filter((x) => x.startsWith("Room"));
    let roles_system: string[] = roles_all.filter((x) => !x.startsWith("Room"));

    let role_membership: string[] = [];
    let reservations_future: IReservationHistoryRec[] | null = null;
    let reservations_latest: IReservationHistoryRec[] | null = null;
    let payments: IPaymentData[] | null = null;
    let invoices: IInvoiceData[] | null = null;
    let gl: IGlRec[] | null = null;
    let gl_balance: number = 0;
    let special_prices: ISingleUserSpecialPrice[] = [];
    let kpis: IGetKpiYearRes | null = null;
    let realization: IGetSingleUserRealizationRatioRes | null = null;
    let include_deleted_reservations: boolean = false;

    let invoice_types: IInvoiceType[] = [];
    let selected_manual_invoice_type: string = "";
    let invoice_amt_final: number = 0;
    let invoice_amt_orig: number = 0;
    let invoice_amt_payed: number = 0;
    let invoice_date_yyyymmdd: string = new Date().toISOString().slice(0, 10);
    let invoice_description: string = "";
    let invoice_quantity: number = 1;
    let invoice_num: number = 1;

    type ActivePanelEnum =
        | "info"
        | "kpi"
        | "latest"
        | "future"
        | "account"
        | "payments"
        | "invoices";
    let active_panel: ActivePanelEnum = "info";

    async function load() {
        try {
            is_error = false;
            console.log("-", $user_data);
            const is_admin = $user_data.is_admin;
            if (user_uuid.length == 0) {
                // TODO
            } else {
                is_loading = true;
                const { data } = await services.backend
                    .admin()
                    .getSingleUser({ user_uuid });
                user = data;
                is_loading = false;
                role_membership = data.roles;

                props = [
                    { title: "Ime", value: user.title },
                    { title: "Uporabniško ime", value: user.username },
                    { title: "ID", value: user.user_uuid },
                    { title: "Email", value: user.email },
                    { title: "GSM", value: user.gsm ?? "-" },
                    { title: "Davčna št.", value: user.tax_id ?? "-" },
                    {
                        title: "Davčni zavezanec",
                        value: user.is_vat ? "DA" : "NE",
                        cssClass:
                            "w3-center " +
                            CssHelpers.getCssClassForUserVat(user.is_vat),
                    },
                    { title: "Naslov", value: user.address ?? "-" },
                    { title: "Pošta", value: user.zip ?? "-" },
                    { title: "Mesto", value: user.town ?? "-" },
                    {
                        title: "Faktor popusta",
                        value: niceNumber(user.discount_factor, 3),
                    },
                    { title: "Admin", value: user.is_admin ? "Da" : "Ne" },
                    {
                        title: "Status",
                        value: user.status,
                        cssClass:
                            "w3-center " +
                            CssHelpers.getCssClassForUserStatus(
                                user.status.trim(),
                            ),
                    },
                    {
                        title: "Aktiven",
                        value: user.active ? "Aktiven" : "Neaktiven",
                        cssClass:
                            "w3-center " +
                            CssHelpers.getCssClassForUserActive(user.active),
                    },
                ];
                const res = await services.backend
                    .admin()
                    .getSingleUserSpecialPrices({ user_uuid });
                special_prices = res.special_prices;
                activatePanel("info");

                if (is_admin) {
                    const res2 = await services.backend.admin().getInvoiceTypes({});
                    invoice_types = res2.types;
                }
            }
        } catch (err) {
            is_error = true;
            error_msg = err.message;
        }
    }
    onMount(async () => {
        user_uuid = UrlHelper.getUrlParams().user_uuid ?? "";
        await load();
    });
    beforeUpdate(async () => {
        const tmp_user_uuid = UrlHelper.getUrlParams().user_uuid ?? "";
        if (user_uuid != tmp_user_uuid) {
            user_uuid = tmp_user_uuid;
            await load();
        }
    });
    function openEdit(): void {
        UrlHelper.openUserEdit(user_uuid);
    }
    function openSetPwd(): void {
        is_set_pwd = true;
    }
    function closeSetPwd(): void {
        is_set_pwd = false;
    }
    async function openRecalcStatus(): Promise<void> {
        is_recalc_status = true;
        recalc_status_status = "Preračunavam...";
        recalc_status_err = "";
        try {
            const res = await services.backend
                .admin()
                .recalculateSingleUserStatus({
                    user_uuid: user?.user_uuid,
                });
            recalc_status_status = "Novi status: " + res.status;
            if (res.status != user?.status) {
                await load();
            }
        } catch (e) {
            recalc_status_err = e.message;
            recalc_status_status = "";
        }
    }
    function closeRecalcStatus(): void {
        is_recalc_status = false;
    }
    async function setNewPwd(): Promise<void> {
        pwd_err = "";
        if (new_pwd != new_pwd2) {
            pwd_err = "Gesli se ne ujemata";
            return;
        }
        await services.backend.admin().setUserPwd({
            pwd: new_pwd,
            user_uuid,
        });
        pwd_status = "Geslo uspešno nastavljeno.";
    }

    async function reloadReservations() {
        reservations_future = null;
        reservations_latest = null;
        await activatePanel(active_panel);
    }

    async function impersonate() {
        await services.auth.impersonate(user_uuid);
        location.hash = "/home";
    }

    async function refreshData() {
        // set all data to null so they will be reloaded
        payments = null;
        invoices = null;
        gl = null;
        reservations_future = null;
        reservations_latest = null;
        kpis = null;
        // reload active panel and its data
        await activatePanel(active_panel);
    }

    async function openIssueManualInvoice() {
        // const res = await services.backend
        //   .admin()
        //   .getNextInvoiceNumberForYear({ year: new Date().getFullYear() });
        // next_invoice_number = res.next_invoice_number;

        alert(
            "Ročno izdajanje računov ni možno. Izdati jih je potrebno iz Čebelice",
        );
        // is_issuing_invoice = true;
    }

    function changeInvoiceType() {
        const type = invoice_types.find(
            (x) => x.code == selected_manual_invoice_type,
        );
        if (type == undefined) {
            return;
        }
        invoice_amt_orig = type.amt;
        invoice_amt_final = type.amt;
        invoice_quantity = 1;
        invoice_description = type.item_title;
    }

    async function issueManualInvoice() {
        if (Date.now() > new Date(2024, 1, 1)) {
            alert(
                "Ročno izdajanje računov ni več možno v tem sistemu. Uporabi Čebelico.",
            );
            return;
        }
        const type = invoice_types.find(
            (x) => x.code == selected_manual_invoice_type,
        );
        if (type == undefined) {
            return;
        }
        is_issuing_invoice_backend = true;
        try {
            await services.backend.admin().issueManualInvoice({
                username: user.username,
                amt_final: invoice_amt_final,
                amt_orig: invoice_amt_orig,
                amt_payed: invoice_amt_payed,
                date_yyyymmdd: invoice_date_yyyymmdd,
                description: invoice_description,
                quantity: invoice_quantity,
                num: invoice_num,
            });
            await refreshData();
            is_issuing_invoice_backend = false;
            is_issuing_invoice = false;
        } catch (e) {
            is_issuing_invoice_backend = false;
            alert(e.message);
        }
    }

    async function openInvoice(external_id1: string): Promise<void> {
        const invoice = await services.backend
            .reservations()
            .downloadInvoice({ id: external_id1 });
        UrlHelper.openFileForToken(invoice.token);
    }

    async function rerenderInvoice(invoice: IInvoiceData): Promise<void> {
        is_rerendering_invoice = true;
        try {
            const already_payed = confirm("Ali je račun že plačan?");
            await services.backend
                .admin()
                .rerenderSingleInvoice({ id: invoice.uuid, already_payed });
            await refreshData();
            is_rerendering_invoice = false;
        } catch (e) {
            is_rerendering_invoice = false;
            alert(e.message);
        }
    }

    async function activatePanel(panel: ActivePanelEnum) {
        active_panel = panel;
        const is_admin = $user_data.is_admin;
        if (panel == "account") {
            if (gl == null) {
                const res = await services.backend
                    .admin()
                    .getSingleUserGl({ user_uuid: user_uuid });
                gl = res.entries.sort((a, b) => b.dat - a.dat); // sort descending
                gl_balance = gl.reduce(
                    (prev, x) => prev + x.amt_d - x.amt_c,
                    0,
                );
            }
        }
        if (panel == "future") {
            if (reservations_future == null) {
                const res = await services.backend.admin().getReservations({
                    from: Date.now(),
                    descending: false,
                    username: user.username,
                    include_deleted: include_deleted_reservations,
                });
                reservations_future = res.reservations;
            }
        }
        if (is_admin && panel == "kpi") {
            if (kpis == null) {
                const res = await services.backend.admin().getKpiYear({
                    username: user.username,
                });
                kpis = res;

                realization = await services.backend
                    .admin()
                    .getSingleUserRealizationRatio({ user_uuid });
            }
        }
        if (panel == "latest") {
            if (reservations_latest == null) {
                const res = await services.backend.admin().getReservations({
                    to: Date.now(),
                    descending: true,
                    username: user.username,
                    limit: 100,
                    include_deleted: include_deleted_reservations,
                });
                reservations_latest = res.reservations;
            }
        }
        if (is_admin && panel == "payments") {
            if (payments == null) {
                const res = await services.backend.admin().getPayments({
                    username: user.username,
                });
                payments = res.payments;
            }
        }
        if (is_admin && panel == "invoices") {
            if (invoices == null) {
                const res = await services.backend.admin().getInvoices({
                    user: user.username,
                });
                invoices = res.invoices;
            }
        }
    }
</script>

<!-- *********************************************** -->

<div
    id={DIV_MODAL_PWD}
    class="w3-modal"
    style="display: {is_set_pwd ? 'block' : 'none'}"
>
    <div class="w3-modal-content">
        <div class="w3-container">
            <span
                on:click={() => closeSetPwd()}
                class="w3-button w3-display-topright">&times;</span
            >
            <p>Vnesi novo geslo za uporabnika:</p>
            <p>
                <input
                    class="w3-input w3-border w3-round"
                    type="password"
                    bind:value={new_pwd}
                />
            </p>
            <p>
                <input
                    class="w3-input w3-border w3-round"
                    type="password"
                    bind:value={new_pwd2}
                />
            </p>
            <p>
                <button
                    class="w3-btn w3-theme-action w3-round-xlarge"
                    on:click={() => setNewPwd()}>Shrani</button
                >
            </p>
            <p>
                {pwd_err}
                {pwd_status}
            </p>
        </div>
    </div>
</div>

<div
    id={DIV_MODAL_STATUS}
    class="w3-modal"
    style="display: {is_recalc_status ? 'block' : 'none'}"
>
    <div class="w3-modal-content">
        <div class="w3-container">
            <span
                on:click={() => closeRecalcStatus()}
                class="w3-button w3-display-topright">&times;</span
            >
            <p>
                {recalc_status_err}
                {recalc_status_status}
            </p>
            <p>
                <button
                    class="w3-btn w3-theme-action w3-round-xlarge"
                    on:click={() => closeRecalcStatus()}>Zapri</button
                >
            </p>
        </div>
    </div>
</div>

<div
    id={DIV_MODAL_MANUAL_INVOICE}
    class="w3-modal"
    style="display: {is_issuing_invoice ? 'block' : 'none'}"
>
    <div class="w3-modal-content">
        <div class="w3-container">
            <span
                on:click={() => {
                    is_issuing_invoice = false;
                }}
                class="w3-button w3-display-topright">&times;</span
            >

            <div class="w3-panel">
                <label for="invoiceNum"
                    >Številka novega računa (samo številka, brez letnice)</label
                >
                <input
                    id="invoiceNum"
                    class="w3-input w3-border"
                    type="number"
                    bind:value={invoice_num}
                />
            </div>
            <div class="w3-panel">
                <p>Izberi vrsto izdanega računa:</p>
                <select
                    class="w3-select w3-border w3-round"
                    bind:value={selected_manual_invoice_type}
                    on:change={changeInvoiceType}
                >
                    {#each invoice_types as type}
                        <option value={type.code}>{type.title}</option>
                    {/each}
                </select>
            </div>

            <div class="w3-panel">
                <label for="invoiceAmtOrig">Originalni znesek</label>
                <input
                    id="invoiceAmtOrig"
                    class="w3-input w3-border"
                    type="number"
                    bind:value={invoice_amt_orig}
                />
            </div>

            <div class="w3-panel">
                <label for="invoiceAmtFinal">Končni znesek (s popusti)</label>
                <input
                    id="invoiceAmtFinal"
                    class="w3-input w3-border"
                    type="number"
                    bind:value={invoice_amt_final}
                />
            </div>

            <div class="w3-panel">
                <label for="invoiceDate">Datum izdaje računa</label>
                <input
                    id="invoiceDate"
                    class="w3-input w3-border"
                    type="date"
                    bind:value={invoice_date_yyyymmdd}
                />
            </div>

            <div class="w3-panel">
                <label for="invoiceAmtPayed">Že plačani znesek</label>
                <input
                    id="invoiceAmtPayed"
                    class="w3-input w3-border"
                    type="number"
                    bind:value={invoice_amt_payed}
                />
            </div>

            <div class="w3-panel">
                <label for="invoiceDesc">Opis stroška</label>
                <input
                    id="invoiceDesc"
                    class="w3-input w3-border"
                    type="text"
                    bind:value={invoice_description}
                />
            </div>

            <div class="w3-panel">
                <label for="invoiceQuantity">Količina</label>
                <input
                    id="invoiceQuantity"
                    class="w3-input w3-border"
                    type="number"
                    bind:value={invoice_quantity}
                />
            </div>

            <p>
                <button
                    disabled={true || is_issuing_invoice_backend}
                    class="w3-btn w3-theme-action w3-round-xlarge"
                    on:click={() => issueManualInvoice()}>Izdaj račun</button
                >
                <LoaderSmall visible={is_issuing_invoice_backend} />
            </p>
        </div>
    </div>
</div>
<!-- *********************************************** -->

<div class="w3-row">
    <div class="w3-col s12 m3 l2">
        <div class="w3-panel">
            {#if user != null}
                <h3>{user.title}</h3>
                <h4>({user.username})</h4>
            {/if}
        </div>
        <div class="w3-padding w3-hide-large w3-hide-medium">
            <select
                class="w3-select w3-border w3-round"
                bind:value={active_panel}
                on:change={() => activatePanel(active_panel)}
            >
                <!-- "info" | "kpi" | "latest" | "future" | "account" -->
                <option value="info">Info</option>
                {#if $user_data.is_admin}
                <option value="kpi">KPI</option>
                {/if}
                <option value="latest">Zadnji najemi</option>
                <option value="future">Bodoči najemi</option>
                {#if $user_data.is_admin}
                <option value="account">Računovodstvo</option>
                <option value="invoices">Fakture</option>
                <option value="payments">Plačila</option>
                {/if}
            </select>
        </div>
        <div class="w3-hide-small">
            <button
                class="w3-button w3-hover-theme {active_panel === 'info'
                    ? 'w3-theme-d3'
                    : ''} width-100-perc"
                on:click={() => activatePanel("info")}>Info</button
            >
            {#if $user_data.is_admin}
            <button
                class="w3-button w3-hover-theme {active_panel === 'kpi'
                    ? 'w3-theme-d3'
                    : ''} width-100-perc"
                on:click={() => activatePanel("kpi")}>KPI</button
            >
            {/if}
            <button
                class="w3-button w3-hover-theme {active_panel === 'latest'
                    ? 'w3-theme-d3'
                    : ''} width-100-perc"
                on:click={() => activatePanel("latest")}>Zadnji najemi</button
            >
            <button
                class="w3-button w3-hover-theme {active_panel === 'future'
                    ? 'w3-theme-d3'
                    : ''} width-100-perc"
                on:click={() => activatePanel("future")}>Bodoči najemi</button
            >
            {#if $user_data.is_admin}
            <button
                class="w3-button w3-hover-theme {active_panel === 'account'
                    ? 'w3-theme-d3'
                    : ''} width-100-perc"
                on:click={() => activatePanel("account")}>Računovodstvo</button
            >
            <button
                class="w3-button w3-hover-theme {active_panel === 'invoices'
                    ? 'w3-theme-d3'
                    : ''} width-100-perc"
                on:click={() => activatePanel("invoices")}>Fakture</button
            >
            <button
                class="w3-button w3-hover-theme {active_panel === 'payments'
                    ? 'w3-theme-d3'
                    : ''} width-100-perc"
                on:click={() => activatePanel("payments")}>Plačila</button
            >
            {/if}
        </div>
    </div>

    <div class="w3-col s12 m9 l10 w3-padding-large">
        {#if active_panel == "info"}
            {#if user == null}
                <Loader visible={true} />
            {:else}
                {#if $user_data.is_admin}
                <div class="w3-right">
                    <button
                        class="w3-button-link"
                        on:click={() => refreshData()}>Osveži podatke</button
                    >
                </div>
                {/if}
                <div class="w3-panel">
                    {#if !user.is_admin || $user_data.is_admin}
                    <button class="w3-button-link" on:click={() => openEdit()}>
                        Popravi podatke
                    </button>
                    <button
                        class="w3-button-link"
                        on:click={() => openSetPwd()}
                    >
                        Nastavi geslo
                    </button>
                    {/if}
                    {#if $user_data.is_admin}
                    <button
                        class="w3-button-link"
                        on:click={() => openRecalcStatus()}
                    >
                        Preračunaj status
                    </button>
                    <button
                        class="w3-button-link"
                        on:click={() => impersonate()}
                    >
                        Impersoniraj
                    </button>
                    {/if}
                </div>

                <PropertyList items={props} />

                <h3>Dostop do pisarn</h3>

                {#each roles_rooms as role}
                    <span
                        class="w3-tag w3-round w3-margin w3-large w3-padding-small {CssHelpers.getCssClassForUserActive(
                            user?.roles.some((x) => x == role) ?? false,
                        )}">{role.replace("Room", "")}</span
                    >
                {/each}

                <h3>Posebne cene</h3>
                {#each special_prices as special_price}
                    <div>
                        {special_price.room_title}: {niceNumber(
                            special_price.price,
                            2,
                        )}
                    </div>
                {/each}
                {#if special_prices.length == 0}
                    -
                {/if}

                <h3>Ostalo</h3>

                {#each roles_system as role}
                    <span
                        class="w3-tag w3-round w3-margin w3-large w3-padding-small {CssHelpers.getCssClassForUserActive(
                            user?.roles.some((x) => x == role) ?? false,
                        )}">{role}</span
                    >
                {/each}
            {/if}
        {/if}
        {#if active_panel == "kpi"}
            {#if kpis == null}
                <Loader visible={true} />
            {:else}
                <div class="w3-right">
                    <button
                        class="w3-button-link"
                        on:click={() => refreshData()}>Osveži podatke</button
                    >
                </div>
                <div class="w3-container">
                    <div class="w3-third w3-padding">
                        <div class="w3-panel w3-blue w3-card-4 w3-padding">
                            <h3>Skupaj</h3>
                            <div>&nbsp;</div>
                        </div>
                    </div>
                    <div class="w3-third w3-padding">
                        <div class="w3-panel w3-card-4 w3-padding">
                            <h3>Letno</h3>
                            <div>{niceNumber(kpis.sum, 2)}</div>
                        </div>
                    </div>
                    <div class="w3-third w3-padding">
                        <div class="w3-panel w3-card-4 w3-padding">
                            <h3>Povprečno mesečno</h3>
                            <div>{niceNumber(kpis.sum_avg_month, 2)}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <Barchart
                        labels={kpis.months.map(
                            (x) =>
                                `${Math.floor(x.yyyymm / 100)}-${x.yyyymm % 100}`,
                        )}
                        values={[kpis.months.map((x) => x.sum)]}
                    />
                </div>
                {#if realization != null}
                    <div class="w3-container">
                        <h2>Količnik realiziranih</h2>
                        <div class="w3-quarter w3-padding">
                            <div class="w3-panel w3-card-4 w3-padding">
                                <h3>Predprejšnji mesec</h3>
                                <div>
                                    {niceNumber(
                                        realization.prev_prev_month.ratio * 100,
                                        0,
                                    )}% (
                                    {niceNumber(
                                        realization.prev_prev_month.actual,
                                        2,
                                    )}
                                    /
                                    {niceNumber(
                                        realization.prev_prev_month.entered,
                                        2,
                                    )}
                                    )
                                </div>
                            </div>
                        </div>
                        <div class="w3-quarter w3-padding">
                            <div class="w3-panel w3-card-4 w3-padding">
                                <h3>Prejšnji mesec</h3>
                                <div>
                                    {niceNumber(
                                        realization.prev_month.ratio * 100,
                                        0,
                                    )}% (
                                    {niceNumber(
                                        realization.prev_month.actual,
                                        2,
                                    )}
                                    /
                                    {niceNumber(
                                        realization.prev_month.entered,
                                        2,
                                    )}
                                    )
                                </div>
                            </div>
                        </div>
                        <div class="w3-quarter w3-padding">
                            <div class="w3-panel w3-blue w3-card-4 w3-padding">
                                <h3>Trenutni mesec</h3>
                                <div>
                                    {niceNumber(
                                        realization.curr_month.ratio * 100,
                                        0,
                                    )}% (
                                    {niceNumber(
                                        realization.curr_month.actual,
                                        2,
                                    )}
                                    /
                                    {niceNumber(
                                        realization.curr_month.entered,
                                        2,
                                    )}
                                    )
                                </div>
                            </div>
                        </div>
                        <div class="w3-quarter w3-padding">
                            <div class="w3-panel w3-card-4 w3-padding">
                                <h3>Naslednji mesec</h3>
                                <div>
                                    {niceNumber(
                                        realization.next_month.ratio * 100,
                                        0,
                                    )}% (
                                    {niceNumber(
                                        realization.next_month.actual,
                                        2,
                                    )}
                                    /
                                    {niceNumber(
                                        realization.next_month.entered,
                                        2,
                                    )}
                                    )
                                </div>
                            </div>
                        </div>
                    </div>
                {/if}
                <h2>Poraba po posamezni pisarni</h2>
                {#each kpis.rooms as r}
                    <div class="w3-container">
                        <div class="w3-third w3-padding">
                            <div class="w3-panel w3-blue w3-card-4 w3-padding">
                                <h3>{r.room_id}</h3>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                        <div class="w3-third w3-padding">
                            <div class="w3-panel w3-card-4 w3-padding">
                                <h3>Skupaj letno</h3>
                                <div>{niceNumber(r.sum, 2)}</div>
                            </div>
                        </div>
                        <div class="w3-third w3-padding">
                            <div class="w3-panel w3-card-4 w3-padding">
                                <h3>Povprečno mesečno</h3>
                                <div>{niceNumber(r.sum_avg_month, 2)}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Barchart
                            labels={r.months.map(
                                (x) =>
                                    `${Math.floor(x.yyyymm / 100)}-${x.yyyymm % 100}`,
                            )}
                            values={[r.months.map((x) => x.sum)]}
                        />
                    </div>
                {/each}
            {/if}
        {/if}
        {#if active_panel == "latest"}
            {#if reservations_latest == null}
                <Loader visible={true} />
            {:else}
                <div class="w3-right">
                    <button
                        class="w3-button-link"
                        on:click={() => refreshData()}>Osveži podatke</button
                    >
                </div>
                <input
                    type="checkbox"
                    bind:checked={include_deleted_reservations}
                    on:change={() => reloadReservations()}
                />
                <span>Prikaži brisane</span>
                <UserDashboardTable reservations={reservations_latest} />
            {/if}
        {/if}
        {#if active_panel == "future"}
            {#if reservations_future == null}
                <Loader visible={true} />
            {:else}
                <div class="w3-right">
                    <button
                        class="w3-button-link"
                        on:click={() => refreshData()}>Osveži podatke</button
                    >
                </div>
                <input
                    type="checkbox"
                    bind:checked={include_deleted_reservations}
                    on:change={() => reloadReservations()}
                />
                <span>Prikaži brisane</span>
                <UserDashboardTable reservations={reservations_future} />
            {/if}
        {/if}
        {#if active_panel == "account"}
            {#if gl == null}
                <Loader visible={true} />
            {:else}
                <div class="w3-right">
                    <button
                        class="w3-button-link"
                        on:click={() => refreshData()}>Osveži podatke</button
                    >
                </div>
                <h2 class={CssHelpers.getCssClassForBalance(-gl_balance)}>
                    Dolg: {niceNumber(gl_balance, 2)}
                </h2>
                <table class="w3-table-all w3-hoverable">
                    <tr>
                        <th>Datum</th>
                        <th>Konto</th>
                        <th>Debit</th>
                        <th>Kredit</th>
                        <th>Opis</th>
                        <th>Datum vnosa</th>
                        <th>ID vira</th>
                        <th>Tip vira</th>
                    </tr>
                    {#each gl as rec}
                        <tr>
                            <td> {niceDate(rec.dat)} </td>
                            <td> {rec.acc_name} </td>
                            <td
                                class={CssHelpers.getCssClassForBalance(
                                    rec.amt_c - rec.amt_d,
                                )}
                            >
                                {niceNumber(rec.amt_d, 2)}
                            </td>
                            <td
                                class={CssHelpers.getCssClassForBalance(
                                    rec.amt_c - rec.amt_d,
                                )}
                            >
                                {niceNumber(rec.amt_c, 2)}
                            </td>
                            <td> {rec.description} </td>
                            <td> {niceDate(rec.dat_entered)} </td>
                            <td> {rec.src_id} </td>
                            <td> {rec.src_type} </td>
                        </tr>
                    {/each}
                </table>
            {/if}
        {/if}
        {#if active_panel == "invoices"}
            {#if invoices == null}
                <Loader visible={true} />
            {:else}
                <div class="w3-right">
                    <button
                        class="w3-button-link"
                        on:click={() => refreshData()}>Osveži podatke</button
                    >
                </div>
                <LoaderSmall visible={is_rerendering_invoice} />

                <div>
                    <button
                        class="w3-button-link"
                        on:click={() => openIssueManualInvoice()}
                        >Izdaj račun</button
                    >
                </div>
                <table class="w3-table-all w3-hoverable">
                    <tr>
                        <th>Id</th>
                        <th>Status</th>
                        <th>Datum</th>
                        <th style="min-width: 120px">Račun</th>
                        <th>Znesek</th>
                        <th>DDV</th>
                        <th>&nbsp;</th>
                    </tr>
                    {#each invoices as invoice}
                        <tr>
                            <td>
                                {invoice.uuid}
                            </td>
                            <td>
                                <span
                                    class="w3-tag w3-round-large {CssHelpers.getCssClassForInvoice(
                                        invoice.finalized,
                                        invoice.external_id2,
                                    )}"
                                    >{CssHelpers.getTagTitleForInvoice(
                                        invoice.finalized,
                                        invoice.external_id2,
                                    )}</span
                                >
                            </td>
                            <td class="w3-right-align">
                                {niceDate(invoice.invoice_date)}
                            </td>
                            <td>
                                <button
                                    on:click={() =>
                                        openInvoice(invoice.external_id1)}
                                    class="w3-button-link"
                                    >{invoice.invoice_number}</button
                                >
                            </td>
                            <td class="w3-right-align">
                                {niceNumber(invoice.amount, 2)}
                            </td>
                            <td class="w3-right-align">
                                {niceNumber(invoice.amount_tax, 2)}
                            </td>
                            <td>
                                {#if invoice.external_id2 != null}
                                    <button
                                        on:click={() =>
                                            rerenderInvoice(invoice)}
                                        class="w3-button-link"
                                        >Osveži račun</button
                                    >
                                {/if}
                            </td>
                        </tr>
                    {/each}
                </table>
            {/if}
        {/if}
        {#if active_panel == "payments"}
            {#if payments == null}
                <Loader visible={true} />
            {:else}
                <div class="w3-right">
                    <button
                        class="w3-button-link"
                        on:click={() => refreshData()}>Osveži podatke</button
                    >
                </div>
                <table class="w3-table-all w3-hoverable">
                    <tr>
                        <th>Id</th>
                        <th>Datum</th>
                        <th>Znesek</th>
                        <th>Opis</th>
                        <th>Vnešeno</th>
                    </tr>
                    {#each payments as payment}
                        <tr>
                            <td> {payment.id} </td>
                            <td> {niceDate(payment.date)} </td>
                            <td class="w3-right-align">
                                {niceNumber(payment.amount, 2)}
                            </td>
                            <td> {payment.description} </td>
                            <td> {niceDate(payment.date_entered)} </td>
                        </tr>
                    {/each}
                </table>
            {/if}
        {/if}
    </div>
</div>
