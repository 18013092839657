<script lang="ts">
    import { onMount } from "svelte";
    import type { IServices } from "./code/services";

    import type { IInvoiceData } from "./code/backend/admin.generated.types";
    import { niceDate, niceNumber, UrlHelper, CssHelpers } from "./code/utils";
    import JobStatusPanel from "./JobStatusPanel.svelte";
    import Loader from "./Loader.svelte";
    import LoaderSmall from "./LoaderSmall.svelte";

    export let services: IServices;
    export const page_params: any = {};

    let invoices: IInvoiceData2[] = [];
    let is_loading: boolean = false;
    let is_rerendering_invoice: boolean = false;

    let job_uuid: string = "";
    let job_title: string = "";

    let month_items: { month: string; offset: number }[] = [];
    let selected_month_item_offset: number = 0;
    let show_download_panel: boolean = false;
    let show_downloading_loader: boolean = false;

    interface IInvoiceData2 extends IInvoiceData {
        amount_formatted: string;
        amount_tax_formatted: string;
        invoice_date_formatted: string;
    }

    function transform(x: IInvoiceData): IInvoiceData2 {
        return {
            ...x,
            amount_formatted: niceNumber(x.amount, 2),
            amount_tax_formatted: niceNumber(x.amount_tax, 2),
            invoice_date_formatted: niceDate(x.invoice_date),
        };
    }

    async function load(): Promise<void> {
        is_loading = true;
        invoices = (await services.backend.admin().getInvoices({})).invoices
            .map((x) => transform(x))
            .sort((a, b) => {
                // sort by date, then by id (descending)
                if (a.invoice_date != b.invoice_date) {
                    return b.invoice_date - a.invoice_date;
                }
                return (b.invoice_number ?? "").localeCompare(
                    a.invoice_number ?? "",
                );
            });
        is_loading = false;
    }

    async function openInvoice(external_id1: string): Promise<void> {
        const invoice = await services.backend
            .reservations()
            .downloadInvoice({ id: external_id1 });
        UrlHelper.openFileForToken(invoice.token);
    }

    async function rerenderInvoice(invoice: IInvoiceData): Promise<void> {
        is_rerendering_invoice = true;
        try {
            const already_payed = confirm("Ali je račun že plačan?");
            await services.backend
                .admin()
                .rerenderSingleInvoice({ id: invoice.uuid, already_payed });
            await load();
            is_rerendering_invoice = false;
        } catch (e) {
            is_rerendering_invoice = false;
            alert(e.message);
        }
    }

    async function deleteInvoice(invoice: IInvoiceData): Promise<void> {
        is_rerendering_invoice = true;
        try {
            await services.backend.admin().deleteInvoice({ id: invoice.uuid });
            await load();
            is_rerendering_invoice = false;
        } catch (e) {
            is_rerendering_invoice = false;
            alert(e.message);
        }
    }

    function prepareMonths(): void {
        /** Month names */
        const month_names = [
            "Januar",
            "Februar",
            "Marec",
            "April",
            "Maj",
            "Junij",
            "Julij",
            "Avgust",
            "September",
            "Oktober",
            "November",
            "December",
        ];
        /** Zero-index-based month */
        let curr_month = new Date().getMonth();
        let curr_year = new Date().getFullYear();
        let tmp_month_items = month_items.slice(0, 0);
        let offset = 0;
        for (let i = 0; i < 12; i++) {
            tmp_month_items.push({
                month: month_names[curr_month] + " " + curr_year,
                offset,
            });
            curr_month--;
            if (curr_month < 0) {
                curr_month = 11;
                curr_year--;
            }
            offset--;
        }
        month_items = tmp_month_items;
        selected_month_item_offset = 0;
    }
    onMount(async () => {
        prepareMonths();
        await load();
    });

    async function onGenerateNew(): Promise<void> {
        const res = await services.backend.admin().prepareNewInvoices({});
        job_uuid = res.job_uuid;
        job_title = "Generiranje faktur";
    }

    async function onRenderMissing(): Promise<void> {
        const res = await services.backend.admin().renderMissingInvoices({});
        job_uuid = res.job_uuid;
        job_title = "Render manjkajočih faktur";
    }

    async function onFinalizePending(): Promise<void> {
        // const this_year = new Date().getFullYear();
        // const next_invoice1 = await services.backend
        //     .admin()
        //     .getNextInvoiceNumberForYear({ year: this_year });
        // const next_invoice2 = await services.backend
        //     .admin()
        //     .getNextInvoiceNumberForYear({ year: this_year - 1 });

        // const ok = confirm(
        //     `Naslednji račun za letos je ${next_invoice1.next_invoice_number}, za lani ${next_invoice2.next_invoice_number}.`
        // );
        // if (!ok) {
        //     return;
        // }

        const num_s = prompt(
            "Vnesi prvo številko računa, ki bo sedaj izdan (brez letnice, samo številka):",
        );
        if (num_s == null) {
            return;
        }
        if (!/^\d+$/gm.test(num_s)) {
            alert("Napaka: vnesena številka ni število.");
            return;
        }

        const res = await services.backend.admin().finalizeInvoices({
            num: parseInt(num_s, 10),
        });
        job_uuid = res.job_uuid;
        job_title = "Finaliziranje faktur";
    }

    async function onDownloadCsv2(type: "pre-vat" | "for-vat"): Promise<void> {
        await onDownloadCsv(selected_month_item_offset, type);
    }
    async function onDownloadZip2(): Promise<void> {
        await onDownloadZip(selected_month_item_offset);
    }
    async function onDownloadCsv(
        month_offset: number,
        type: "pre-vat" | "for-vat",
    ): Promise<void> {
        show_downloading_loader = true;
        try {
            const res = await services.backend
                .admin()
                .downloadCsvOfInvoices({ month_offset, type });
            UrlHelper.openFileForToken(res.token);
        } finally {
            show_downloading_loader = false;
        }
    }
    async function onDownloadZip(month_offset: number): Promise<void> {
        show_downloading_loader = true;
        try {
            const res = await services.backend
                .admin()
                .downloadBatchOfInvoices({ month_offset });
            UrlHelper.openFileForToken(res.token);
        } finally {
            show_downloading_loader = false;
        }
    }
</script>

<div class="w3-container">
    <div class="w3-row">
        <button class="w3-button-link w3-right" on:click={() => load()}>
            Osveži
        </button>
        <button class="w3-button-link" on:click={() => onGenerateNew()}>
            Generiraj nove
        </button>
        <button class="w3-button-link" on:click={() => onFinalizePending()}>
            Finaliziraj
        </button>
        <button class="w3-button-link" on:click={() => onRenderMissing()}>
            Renderiraj manjkajoče
        </button>
        &bullet;
        <!-- <button class="w3-button-link" on:click={() => onDownloadCsv(0)}>
            Zadnji podatki
        </button>
        <button class="w3-button-link" on:click={() => onDownloadCsv(-1)}>
            Predzadnji podatki
        </button>
        &bullet;
        <button class="w3-button-link" on:click={() => onDownloadZip(0)}>
            Zadnji ZIP
        </button>
        <button class="w3-button-link" on:click={() => onDownloadZip(-1)}>
            Predzadnji ZIP
        </button> -->
        <button
            class="w3-button-link"
            on:click={() => {
                show_download_panel = true;
            }}
        >
            Prenesi datoteke
        </button>
    </div>
    {#if show_download_panel}
        <div class="w3-row">
            Mesec:
            <select
                bind:value={selected_month_item_offset}
                class="w3-select w3-border w3-round"
            >
                {#each month_items as month_item}
                    <option value={month_item.offset}>
                        {month_item.month}
                    </option>
                {/each}
            </select>
            <button
                class="w3-button-link"
                on:click={() => onDownloadCsv2("pre-vat")}
            >
                Prenesi podatke
            </button>
            <button
                class="w3-button-link"
                on:click={() => onDownloadCsv2("for-vat")}
            >
                Prenesi podatke novi
            </button>
            <button class="w3-button-link" on:click={() => onDownloadZip2()}>
                Prenesi ZIP
            </button>
            <LoaderSmall visible={show_downloading_loader} />
        </div>
    {/if}
    <div class="w3-row">
        <div class="w3-col m12 l12 w3-padding-large w3-center">
            <JobStatusPanel {services} uuid={job_uuid} title={job_title} />
            <div class="w3-center">
                <Loader visible={is_loading && is_rerendering_invoice} />
            </div>
            {#if !is_loading}
                <table class="w3-table-all w3-hoverable">
                    <tr>
                        <th>Id</th>
                        <th>Status</th>
                        <th>Datum</th>
                        <th style="min-width: 120px">Račun</th>
                        <th>Znesek</th>
                        <th>DDV</th>
                        <th>Uporabnik</th>
                        <th>Naziv</th>
                        <th>&nbsp;</th>
                    </tr>
                    {#each invoices as invoice}
                        <tr>
                            <td>
                                {invoice.uuid}
                            </td>
                            <td>
                                <span
                                    class="w3-tag w3-round-large {CssHelpers.getCssClassForInvoice(
                                        invoice.finalized,
                                        invoice.external_id2,
                                    )}"
                                    >{CssHelpers.getTagTitleForInvoice(
                                        invoice.finalized,
                                        invoice.external_id2,
                                    )}</span
                                >
                            </td>
                            <td class="w3-right-align">
                                {invoice.invoice_date_formatted}
                            </td>
                            <td>
                                <button
                                    on:click={() =>
                                        openInvoice(invoice.external_id1)}
                                    class="w3-button-link"
                                    >{invoice.invoice_number}</button
                                >
                            </td>
                            <td class="w3-right-align">
                                {invoice.amount_formatted}
                            </td>
                            <td class="w3-right-align">
                                {invoice.amount_tax_formatted}
                            </td>
                            <td>
                                <a
                                    href={UrlHelper.getOpenUserUrl(
                                        invoice.user_uuid,
                                    )}
                                >
                                    {invoice.username}
                                </a>
                            </td>
                            <td>
                                {invoice.user_title}
                            </td>
                            <td>
                                {#if invoice.external_id2 != null}
                                    <button
                                        on:click={() =>
                                            rerenderInvoice(invoice)}
                                        class="w3-button-link"
                                        >Osveži račun</button
                                    >
                                {/if}
                                {#if invoice.external_id2 === null}
                                    <button
                                        on:click={() => deleteInvoice(invoice)}
                                        class="w3-button-link"
                                        >Zbriši račun</button
                                    >
                                {/if}
                            </td>
                        </tr>
                    {/each}
                </table>
            {/if}
        </div>
    </div>
</div>
