<script lang="ts">
    import { onMount } from "svelte";
    import type {
        IInvoiceData,
        IReservationMine,
    } from "./code/backend/reservations.generated.types";
    import type { IServices } from "./code/services";
    import {
        addMonth,
        getStartOfMonth,
        TIME_RANGES,
        niceDate,
        niceTimeNoSec,
        niceNumber,
        UrlHelper,
    } from "./code/utils";
    import Loader from "./Loader.svelte";

    export let services: IServices;
    export let page_params: any = {};

    type pageName = "this_month" | "prev_month" | "future" | "invoices";
    let selected_page: pageName = page_params.subpage ?? "invoices";
    let reservations_current: IReservationMine[] = [];
    let reservations_prev: IReservationMine[] = [];
    let reservations_future: IReservationMine[] = [];
    let invoices: IInvoiceData[] = [];
    let is_loading: boolean = false;
    let sum_prev: number = 0;
    let amt_prev: number = 0;
    let sum_curr: number = 0;
    let amt_curr: number = 0;

    function setPage(page: pageName): void {
        selected_page = page;
        UrlHelper.openMyReservationsSubpage(page);
    }

    async function openInvoice(external_id: string): Promise<void> {
        const invoice = await services.backend
            .reservations()
            .downloadInvoice({ id: external_id });
        UrlHelper.openFileForToken(invoice.token);
    }

    onMount(async () => {
        const som1 = getStartOfMonth(new Date());
        const som0 = addMonth(som1, -1);
        const som2 = addMonth(som1, 1);

        is_loading = true;
        const res1 = await services.backend.reservations().getMyReservations({
            from: som1.getTime(),
            to: som2.getTime(),
        });
        reservations_current = res1.reservations;
        sum_curr = reservations_current
            .map(x => (x.end - x.start) / TIME_RANGES.HOUR)
            .reduce((a, b) => a + b, 0);
        amt_curr = reservations_current
            .map(x => x.price)
            .reduce((a, b) => a + b, 0);
        const res2 = await services.backend.reservations().getMyReservations({
            from: som0.getTime(),
            to: som1.getTime(),
        });
        reservations_prev = res2.reservations;
        sum_prev = reservations_prev
            .map(x => (x.end - x.start) / TIME_RANGES.HOUR)
            .reduce((a, b) => a + b, 0);
        amt_prev = reservations_prev
            .map(x => x.price)
            .reduce((a, b) => a + b, 0);
        const res3 = await services.backend.reservations().getMyReservations({
            from: som2.getTime(),
            to: 2 * som2.getTime(),
        });
        reservations_future = res3.reservations;

        const res4 = await services.backend.reservations().getMyInvoices({});
        invoices = res4.invoices;
        is_loading = false;
    });
</script>

<div class="w3-row">
    <div class="w3-col s12 m3 l2">
        <div class="w3-panel">
            <h3>Moje rezervacije in računi</h3>
        </div>
        <div class="w3-padding w3-hide-large w3-hide-medium">
            <select
                class="w3-select w3-border w3-round"
                bind:value={selected_page}
            >
                <option value="invoices">Računi</option>
                <option value="prev_month">Prejšnji mesec</option>
                <option value="this_month">Tekoči mesec</option>
                <option value="future">Prihodnji meseci</option>
            </select>
        </div>
        <div class="w3-hide-small">
            <button
                class="w3-button w3-hover-theme {selected_page === 'invoices'
                    ? 'w3-theme-d3'
                    : ''} width-100-perc"
                on:click={() => setPage("invoices")}>Računi</button
            >
            <button
                class="w3-button w3-hover-theme {selected_page === 'prev_month'
                    ? 'w3-theme-d3'
                    : ''} width-100-perc"
                on:click={() => setPage("prev_month")}>Prejšnji mesec</button
            >
            <button
                class="w3-button w3-hover-theme {selected_page === 'this_month'
                    ? 'w3-theme-d3'
                    : ''} width-100-perc"
                on:click={() => setPage("this_month")}>Tekoči mesec</button
            >
            <button
                class="w3-button w3-hover-theme {selected_page === 'future'
                    ? 'w3-theme-d3'
                    : ''} width-100-perc"
                on:click={() => setPage("future")}>Prihodnji meseci</button
            >
        </div>
    </div>

    <div class="w3-col s12 m9 l10 w3-padding-large">
        <div class="w3-center">
            <Loader visible={is_loading} />
        </div>
        {#if !is_loading}
            {#if selected_page == "invoices"}
                <table class="w3-table-all w3-hoverable">
                    <tr>
                        <th class="w3-right-align">Datum</th>
                        <th class="w3-right-align">Številka računa</th>
                        <th class="w3-right-align">Znesek</th>
                        <th>Povezava</th>
                    </tr>
                    {#each invoices as invoice, i}
                        <tr>
                            <td class="w3-right-align"
                                >{niceDate(invoice.ts)}</td
                            >
                            <td class="w3-right-align"
                                >{invoice.invoice_number != "UNKNOWN" ? invoice.invoice_number : "-"}</td
                            >
                            <td class="w3-right-align"
                                >{niceNumber(invoice.amount, 2)}</td
                            >
                            <td>
                                <!-- <a href={UrlHelper.createInvoiceUrl(invoice.uuid)}
                                    >Prenesi</a
                                > -->
                                {#if invoice.invoice_number != "UNKNOWN"}
                                <button
                                    on:click={() =>
                                        openInvoice(invoice.external_id1)}
                                    class="w3-button-link"
                                    >{invoice.invoice_number}</button
                                >
                                {/if}
                            </td>
                        </tr>
                    {/each}
                </table>
            {/if}
            {#if selected_page == "this_month"}
                <div>
                    <span>
                        Skupaj: {niceNumber(sum_curr, 2)} h
                    </span>
                    <span>
                        Skupni znesek: {niceNumber(amt_curr, 2)} €
                    </span>
                </div>
                <table class="w3-table-all w3-hoverable">
                    <tr>
                        <th class="w3-right-align">Dan</th>
                        <th class="w3-right-align">Ura</th>
                        <th class="w3-right-align">Trajanje (h)</th>
                        <th class="w3-right-align">Cena</th>
                        <th>&nbsp;</th>
                        <th class="w3-center">Opis</th>
                    </tr>
                    {#each reservations_current as res, i}
                        <tr>
                            <td class="w3-right-align">{niceDate(res.start)}</td
                            >
                            <td class="w3-right-align"
                                >{niceTimeNoSec(res.start)} - {niceTimeNoSec(
                                    res.end
                                )}</td
                            >
                            <td class="w3-right-align"
                                >{niceNumber(
                                    (res.end - res.start) / TIME_RANGES.HOUR,
                                    2
                                )}</td
                            >
                            <td class="w3-right-align"
                                >{niceNumber(res.price, 2)}</td
                            >
                            <td>
                                {#if res.is_group}
                                    <span class="w3-tag w3-round w3-small"
                                        >Skupina</span
                                    >
                                {/if}
                            </td>
                            <td>{res.text}</td>
                        </tr>
                    {/each}
                </table>
            {/if}
            {#if selected_page == "prev_month"}
            <div>
                <span>
                    Skupaj: {niceNumber(sum_prev, 2)} h
                </span>
                <span>
                    Skupni znesek: {niceNumber(amt_prev, 2)} €
                </span>
            </div>
            <table class="w3-table-all w3-hoverable">
                    <tr>
                        <th class="w3-right-align">Dan</th>
                        <th class="w3-right-align">Ura</th>
                        <th class="w3-right-align">Trajanje (h)</th>
                        <th class="w3-right-align">Cena</th>
                        <th>&nbsp;</th>
                        <th class="w3-center">Opis</th>
                    </tr>
                    {#each reservations_prev as res, i}
                        <tr>
                            <td class="w3-right-align">{niceDate(res.start)}</td
                            >
                            <td class="w3-right-align"
                                >{niceTimeNoSec(res.start)} - {niceTimeNoSec(
                                    res.end
                                )}</td
                            >
                            <td class="w3-right-align"
                                >{niceNumber(
                                    (res.end - res.start) / TIME_RANGES.HOUR,
                                    2
                                )}</td
                            >
                            <td class="w3-right-align"
                                >{niceNumber(res.price, 2)}</td
                            >
                            <td>
                                {#if res.is_group}
                                    <span class="w3-tag w3-round w3-small"
                                        >Skupina</span
                                    >
                                {/if}
                            </td>
                            <td>{res.text}</td>
                        </tr>
                    {/each}
                </table>
            {/if}
            {#if selected_page == "future"}
                <table class="w3-table-all w3-hoverable">
                    <tr>
                        <th class="w3-right-align">Dan</th>
                        <th class="w3-right-align">Ura</th>
                        <th class="w3-right-align">Trajanje (h)</th>
                        <th class="w3-right-align">Cena</th>
                        <th>&nbsp;</th>
                        <th class="w3-center">Opis</th>
                    </tr>
                    {#each reservations_future as res, i}
                        <tr>
                            <td class="w3-right-align">{niceDate(res.start)}</td
                            >
                            <td class="w3-right-align"
                                >{niceTimeNoSec(res.start)} - {niceTimeNoSec(
                                    res.end
                                )}</td
                            >
                            <td class="w3-right-align"
                                >{niceNumber(
                                    (res.end - res.start) / TIME_RANGES.HOUR,
                                    2
                                )}</td
                            >
                            <td class="w3-right-align"
                                >{niceNumber(res.price, 2)}</td
                            >
                            <td>
                                {#if res.is_group}
                                    <span class="w3-tag w3-round w3-small"
                                        >Skupina</span
                                    >
                                {/if}
                            </td>
                            <td>{res.text}</td>
                        </tr>
                    {/each}
                </table>
            {/if}
        {/if}
    </div>
</div>
