<script lang="ts">

    import { onMount } from "svelte";
    import type { IServices } from "./code/services";

    export let services: IServices;
    export const page_params: any = {};

    onMount(() => {
        console.log("Services initialized", services != undefined);
        console.log("Page params", page_params);
    });
</script>

<h1>GDPR - Osebni podatki in njihova uporaba</h1>
<h2>Katere osebne podatke hranimo</h2>
<p>Podjetje <strong>NajemPisarne.si</strong> shranjuje naslednje podatke o strankah:</p>
<ul>
<li>Ime in priimek kontaktne osebe</li>
<li>Email</li>
<li>Naslov (*) (ulica in številka, pošta in številka)</li>
<li>Davčna številka (*)</li>
</ul>
<p>(*) samo za pravne osebe</p>
<p>Trenutno shranjene podatke si lahko vedno ogledate na strani "Moji podatki", ki je vidna prijavljenim uporabnikom.</p>
<h2>Za kaj jih uporabljamo</h2>
<p>Podatki se uporabljajo izrecno samo za sledeče dejavnosti:</p>
<ul>
<li>Evidenca najemov</li>
<li>Izdajanje računov</li>
<li>Analiza najemov za identifikacijo:<ul>
<li>uporabnikov, ki so najemali pisarne v času, ko je bila v pisarnah prijavljena škoda ali kak drug incident</li>
<li>neaktivnih uporabnikov</li>
<li>uporabnikov, ki prekomerno brišejo rezervacije</li>
</ul>
</li>
</ul>
<h2>Dostop s strani tretjih oseb</h2>
<ul>
<li>Dostop do podatkov v podatkovni bazi <strong>NI</strong> omogočen nobeni tretji osebi, razen inšpekcijam in ostalim uradnim osebam po službeni dolžnosti, kot to določajo ustrezni zakoni in predpisi.</li>
<li>Podatke o izdanih računih prejme računovodski servis.</li>
<li>Podatki se <strong>NE</strong> pošiljajo nobeni drugi zunanji fizični ali pravni osebi.</li>
</ul>
<h2>Postopki za skladnost z GDPR</h2>
<p>Uporabnik lahko kontaktira podjetje preko elektronske pošte na naslov <strong>info@najempisarne.si</strong>
(v nadaljevanju &quot;kontaktni e-naslov&quot;). Kontakt je naveden tudi na spletni strani, kjer uporabniki vnašajo najeme pisarn.</p>
<h3>Seznanitev s podatki</h3>
<p>Spletni portal omogoča vpogled v vse osebne podatke, ki jih sistem hrani o uporabniku.</p>
<h3>Popravek podatkov</h3>
<p>Uporabnik lahko popravek podatkov zahteva preko elektronskega sporočila, poslanega na kontaktni e-naslov.
Administrator sistema ročno popravi podatke v evidenci.</p>
<h3>Omejitev podatkov</h3>
<p>Uporabnik lahko omejitev podatkov zahteva preko elektronskega sporočila, poslanega na kontaktni e-naslov.
Administrator sistema ročno popravi/odstrani podatke v evidenci.</p>
<h3>Izbris podatkov</h3>
<p>Uporabnik lahko izbris podatkov zahteva preko elektronskega sporočila, poslanega na kontaktni e-naslov.
Administrator sistema ročno anonimizira podatke v evidenci. Določeni podatki morajo v sistemu ostati zaradi
zagotavljanja računovodske sledljivosti in zakonske skladnosti.</p>
<h3>Prenos podatkov</h3>
<p>Uporabnik lahko izvoz podatkov zahteva preko elektronskega sporočila, poslanega na kontaktni e-naslov.
Administrator sistema ročno izvozi podatke iz evidence v datoteke v besedilni obliki, jih skompresira in pošlje uporabniku na njegov elektronski naslov.</p>

<style>
    p {
        text-align: justify;
    }
    </style>
