<script lang="ts">
    import { onMount } from "svelte";
    import type { IServices } from "./code/services";

    import type {
        IGetPaymentsReq,
        IPaymentData,
        IUserBalanceInfo,
    } from "./code/backend/admin.generated.types";
    import { niceDate, niceNumber, NumberParserSi, UrlHelper } from "./code/utils";
    import Loader from "./Loader.svelte";

    export let services: IServices;
    export const page_params: any = {};

    let payments: IPaymentData2[] = [];
    let users: IUserBalanceInfo[] = [];
    let users_matches: IUserBalanceInfo[] = [];
    let is_loading: boolean = false;
    let is_inserting: boolean = false;
    let filter_str: string = "";

    let insert_date: string = "";
    let insert_amount: string = "";
    let insert_user: string = "";
    let insert_description: string = "";
    //let parser: NumberParser = new NumberParser("si-SL");
    let parser = new NumberParserSi();

    interface IPaymentData2 extends IPaymentData {
        amount_formatted: string;
        date_formatted: string;
        date_entered_formatted: string;
    }

    function transform(x: IPaymentData): IPaymentData2 {
        return {
            ...x,
            amount_formatted: niceNumber(x.amount, 2),
            date_formatted: niceDate(x.date),
            date_entered_formatted: niceDate(x.date_entered),
        };
    }

    onMount(async () => {
        await load();
    });

    async function load(): Promise<void> {
        is_loading = true;
        let req: IGetPaymentsReq = {};
        if (filter_str.length > 0) {
            req.filter = filter_str;
        }
        const res = await services.backend.admin().getPayments(req);
        payments = res.payments.map((x) => transform(x));
        is_loading = false;
        // load users in the background
        setTimeout(async () => {
            users = (await services.backend.admin().getUserBalances({}))
                .balances;
        }, 0);
    }

    function startInsert() {
        insert_amount = "0";
        insert_description = "";
        insert_user = "";
        insert_date = new Date().toISOString().substring(0, 10);
        is_inserting = true;
    }
    async function saveData(): Promise<void> {
        await services.backend.admin().insertNewPayment({
            amount: parser.parse(insert_amount),
            date: Date.parse(insert_date),
            description: insert_description,
            username: insert_user,
        });
        is_inserting = false;
        await load();
    }
    async function cancelPayment(id: number): Promise<void> {
        if (!confirm("Ali naj storniram plačilo " + id + "?")) {
            return;
        }
        try {
            is_loading = true;
            await services.backend.admin().cancelPayment({ id });
        } catch (err) {
            alert("Prišlo je od napake: " + err.message);
        }
        await load();
    }
    function clearData() {
        is_inserting = false;
    }
    function handleKeyPress() {
        if (insert_user.length > 2) {
            users_matches = users.filter(
                (x) => x.username.indexOf(insert_user) >= 0
            );
        } else if (insert_user.length == 0) {
            const amt = parser.parse(insert_amount);
            users_matches = users.filter((x) => amt != 0 && x.balance == amt);
        }
    }
</script>

<div class="w3-container">
    <div class="w3-row">
        {#if !is_inserting}
            <button class="w3-button-link" on:click={() => startInsert()}>
                Vnesi novo plačilo
            </button>
        {/if}
        <input
            class="w3-right w3-margin"
            type="text"
            placeholder="Filter"
            bind:value={filter_str}
            on:keyup={() => load()}
        />
    </div>
    <div class="w3-row">
        <div class="w3-col m12 l12 w3-padding-large w3-center">
            <div class="w3-center">
                <Loader visible={is_loading} />
            </div>
            {#if is_inserting}
                <form class="w3-container">
                    <div class="w3-panel">
                        <label for="inDate">Datum</label>
                        <input
                            id="inDate"
                            class="w3-input w3-border w3-round"
                            type="date"
                            bind:value={insert_date}
                        />
                        {insert_date}
                    </div>
                    <div class="w3-panel">
                        <label for="inAmount">Znesek (z decimalno vejico)</label>
                        <input
                            id="inAmount"
                            class="w3-input w3-border w3-round"
                            type="text"
                            bind:value={insert_amount}
                            on:keyup={() => handleKeyPress()}
                        />
                    </div>
                    <div class="w3-panel">
                        <label for="inUser">Uporabnik</label>
                        <input
                            id="inUser"
                            class="w3-input w3-border w3-round"
                            type="text"
                            bind:value={insert_user}
                            on:keyup={() => handleKeyPress()}
                        />
                        <div>
                            {#each users_matches as match}
                                <span
                                    class="cursor-pointer w3-tag w3-margin"
                                    on:click={() =>
                                        (insert_user = match.username)}
                                    >{match.username}</span
                                >
                            {/each}
                        </div>
                    </div>
                    <div class="w3-panel">
                        <label for="inUser">Opis</label>
                        <input
                            id="inUser"
                            class="w3-input w3-border w3-round"
                            type="text"
                            bind:value={insert_description}
                        />
                    </div>
                </form>
                <div class="w3-panel w3-right-align">
                    <button
                        class="w3-btn w3-theme-action w3-round-xlarge"
                        on:click={() => saveData()}>Shrani</button
                    >
                    <button
                        class="w3-btn w3-theme-light w3-round-xlarge"
                        on:click={() => clearData()}>Prekini</button
                    >
                </div>
            {/if}
            {#if !is_loading}
                <table class="w3-table-all w3-hoverable">
                    <tr>
                        <th>Id</th>
                        <th>Datum</th>
                        <th>Znesek</th>
                        <th>Uporabnik</th>
                        <th>Ime</th>
                        <th>Opis</th>
                        <th>Vnešeno</th>
                        <th>&nbsp;</th>
                    </tr>
                    {#each payments as payment}
                        <tr>
                            <td> {payment.id} </td>
                            <td> {payment.date_formatted} </td>
                            <td class="w3-right-align"> {payment.amount_formatted} </td>
                            <td>
                                <a
                                    href={UrlHelper.getOpenUserUrl(
                                        payment.user_uuid
                                    )}
                                >
                                    {payment.username}
                                </a>
                            </td>
                            <td> {payment.user_title} </td>
                            <td> {payment.description} </td>
                            <td> {payment.date_entered_formatted} </td>
                            <td>
                                <button
                                    class="w3-button-link"
                                    on:click={() => cancelPayment(payment.id)}
                                    >Storniraj</button
                                >
                            </td>
                        </tr>
                    {/each}
                </table>
            {/if}
        </div>
    </div>
</div>
