<script lang="ts">
    import { onMount } from "svelte";

    import Header from "./Header.svelte";
    import Footer from "./Footer.svelte";

    import Home from "./Home.svelte";
    import Login from "./Login.svelte";
    import Occupancy from "./Occupancy.svelte";
    import MyReservations from "./MyReservations.svelte";
    import MyData from "./MyData.svelte";
    import Help from "./Help.svelte";
    import Gdpr from "./Gdpr.svelte";
    import Administration from "./Administration.svelte";
    import Users from "./Users.svelte";
    import User from "./User.svelte";
    import UserDash from "./UserDashboard.svelte";
    import Invoices from "./Invoices.svelte";
    import Payments from "./Payments.svelte";
    import LatestChanges from "./LatestChanges.svelte";
    import Balances from "./Balances.svelte";
    import Month from "./Month.svelte";
    import ForgotPassword1 from "./ForgotPassword1.svelte";
    import ForgotPassword2 from "./ForgotPassword2.svelte";

    import {
        is_admin, is_power, is_logged_in, enable_wide_gui,
        initStores
     } from "./code/stores";
    import { initServices } from "./code/services";
    import { UrlHelper } from "./code/utils";

    import Search from "./Search.svelte";
    import ReservationEdit from "./ReservationEdit.svelte";
    import UserDashboard from "./UserDashboard.svelte";
    import Registration from "./Registration.svelte";
    import Registrations from "./Registrations.svelte";
    import Messages from "./Messages.svelte";

    const services = initServices();
    initStores();

    ///////////////////////////////////////////////////////////////

    let component: any;
    let page_params: any;
    let use_wide_screen: boolean = false;
    let allow_wide_screen: boolean = $enable_wide_gui;
    enable_wide_gui.subscribe((val) => {
        allow_wide_screen = val;
    });

    ///////////////////////////////////////////////////////////////

    interface Route {
        path: string;
        redirectTo?: string;
        component?: any;
        needs_login?: boolean;
        needs_admin?: boolean;
        needs_admin_or_power?: boolean;
        wide_screen?: boolean;
    }

    const routes: Route[] = [
        { path: "", redirectTo: "/home" },
        { path: "home", component: Home, wide_screen: true },
        { path: "occupancy", component: Occupancy },
        { path: "login", component: Login },
        { path: "help", component: Help },
        { path: "gdpr", component: Gdpr },
        { path: "forgot", component: ForgotPassword1 },
        { path: "forgot2", component: ForgotPassword2 },
        { path: "registration", component: Registration },
        {
            path: "registrations",
            component: Registrations,
            needs_admin: true,
            wide_screen: true,
        },

        { path: "my_data", component: MyData, needs_login: true },
        { path: "messages", component: Messages, needs_login: true },
        {
            path: "my_reservations",
            component: MyReservations,
            needs_login: true,
            wide_screen: true,
        },

        {
            path: "edit_reservation",
            component: ReservationEdit,
            needs_login: true,
        },

        {
            path: "admin",
            component: Administration,
            needs_admin_or_power: true,
            wide_screen: true,
        },
        { path: "users", component: Users, needs_admin: true },
        {
            path: "user",
            component: UserDashboard,
            needs_admin_or_power: true,
            wide_screen: true,
        },
        { path: "user-edit", component: User, needs_admin_or_power: true },
        {
            path: "invoices",
            component: Invoices,
            needs_admin: true,
            wide_screen: true,
        },
        { path: "payments", component: Payments, needs_admin: true },
        {
            path: "latest_changes",
            component: LatestChanges,
            needs_admin: true,
            wide_screen: true,
        },
        { path: "balances", component: Balances, needs_admin: true },
        { path: "month", component: Month, needs_admin: true },
        { path: "search", component: Search, needs_admin_or_power: true },
    ];

    function getPage(path: string): Route | null {
        const hit = routes.find((x) => x.path == path);
        if (hit == undefined) {
            console.log("page for path not found:", path);
            return null;
        }

        if (hit.redirectTo != undefined) {
            console.log(`redirecting page from ${path} to ${hit.redirectTo}`);
            return getPage(hit.redirectTo);
        }
        if (hit.needs_login && $is_logged_in == false) {
            console.log(`page ${path} requires login, but not logged in`);
            return getPage("");
        }
        if (hit.needs_admin && $is_admin == false) {
            console.log(`page ${path} requires admin`);
            return getPage("");
        }
        if (hit.needs_admin_or_power && $is_admin == false && $is_power == false) {
            console.log(`page ${path} requires admin or power`);
            return getPage("");
        }
        return hit;
    }

    /////////////////////////////////////////
    async function hashchange() {
        // simple router
        const data = UrlHelper.getUrlParams();
        const path = data.page ?? "";
        const route = getPage(path);
        component = route?.component;
        page_params = data;
        use_wide_screen = allow_wide_screen && (route?.wide_screen ?? false);
        if (component == null) {
            UrlHelper.openHome();
        }
        if (route != null) {
            window.gtag("event", "page_view", {
                page_title: document.title + " - " + route.path,
                page_location: location.href,
                page_path: "/#" + route.path
            });
        }
    }

    onMount(() => {
        hashchange();
    });
</script>

<svelte:window on:hashchange={hashchange} />

<Header {services} />
<main class:wide={use_wide_screen}>
    <svelte:component this={component} {services} {page_params} />
</main>
<Footer />

<style>
    main {
        position: relative;
        max-width: 1000px;
        margin: 0 auto;
        min-height: 101vh;
        /* padding: 1em; */
    }
    main.wide {
        max-width: 1900px;
    }

    main :global(.meta) {
        color: #999;
        font-size: 12px;
        margin: 0 0 1em 0;
    }

    main :global(a) {
        color: rgb(0, 0, 150);
    }
</style>
