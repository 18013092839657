<script lang="ts">
    import { onMount, beforeUpdate } from "svelte";
    import type { IServices } from "./code/services";
    import { user_data } from "./code/stores";

    import type {
        IPricelist,
        ISingleUserSpecialPrice,
        IUserBasicData,
    } from "./code/backend/admin.generated.types";
    import { USER_ROLES } from "./code/consts.generated";
    import { CssHelpers, UrlHelper } from "./code/utils";
    import Loader from "./Loader.svelte";
    import SpecialPriceChooser from "./SpecialPriceChooser.svelte";
    import type { IRoomData } from "./code/backend/nonauthenticated.generated.types";

    export let services: IServices;
    export const page_params: any = {};

    let user_uuid: string = "";
    let user: IUserBasicData | null = null;
    let is_new: boolean = true;
    let is_loading: boolean = false;
    let is_saving: boolean = false;
    let new_user_uuid: string = "";
    let is_admin = $user_data.is_admin;
    let is_power = $user_data.is_power;

    let is_error: boolean = false;
    let error_msg: string = "";
    let is_success: boolean = false;
    let special_prices: ISingleUserSpecialPrice[] = [];
    let pricelists: IPricelist[] = [];
    let rooms: IRoomData[] = [];

    let roles_all: string[] = Object.keys(USER_ROLES)
        .sort()
        .filter((x) => x != USER_ROLES.Admin);
    let roles_rooms: string[] = roles_all.filter((x) => x.startsWith("Room"));
    let roles_system: string[] = roles_all.filter((x) => !x.startsWith("Room"));

    class RoomAccess {
        room: IRoomData;
        role_name: string;
        has_access: boolean;
        special_price_id: string;
    }
    let room_access: RoomAccess[] = [];

    let role_membership: string[] = [];

    async function load() {
        try {
            is_error = false;
            const res3 = await services.backend
                .nonauthenticated()
                .getOffices({});
            rooms = res3.rooms;
            const room_access_tmp: RoomAccess[] = rooms.map<RoomAccess>(
                (x) => ({
                    has_access: false,
                    role_name: x.role_name,
                    room: x,
                    special_price_id: "-1",
                }),
            );
            const res2 = await services.backend.admin().getPricelists({});
            pricelists = res2.pricelists;
            if (is_new) {
                user = {
                    active: true,
                    address: "",
                    discount_factor: 1,
                    email: "",
                    gsm: "",
                    is_admin: false,
                    is_vat: false,
                    roles: [],
                    status: "Ok",
                    tax_id: "",
                    title: "",
                    town: "",
                    user_uuid,
                    username: "",
                    zip: "",
                };
            } else {
                is_loading = true;
                new_user_uuid = user_uuid;
                const { data } = await services.backend
                    .admin()
                    .getSingleUser({ user_uuid });
                user = data;
                is_loading = false;
                for (const ra of room_access_tmp) {
                    for (const rm of data.roles) {
                        if (ra.role_name == rm) {
                            ra.has_access = true;
                            break;
                        }
                    }
                }
                // remove roles connected to rooms
                role_membership = data.roles.filter(
                    (x) => !room_access_tmp.some((y) => y.role_name == x)
                );
                const res = await services.backend
                    .admin()
                    .getSingleUserSpecialPrices({ user_uuid });
                special_prices = res.special_prices;
                for (const ra of room_access_tmp) {
                    for (const sp of special_prices) {
                        if (sp.room_id == ra.room.id) {
                            ra.special_price_id = "" + sp.id_pricelist;
                        }
                    }
                }
            }
            room_access = room_access_tmp;

            if (is_power) {
                if (is_new) {
                    role_membership.push(USER_ROLES.Normal);
                } else {
                    if (user.is_admin) {
                        user = null;
                        throw new Error("Urejanje podatkov ni možno")
                    }
                }
                // TODO
            }
        } catch (err) {
            is_error = true;
            error_msg = err.message;
        }
    }
    onMount(async () => {
        user_uuid = UrlHelper.getUrlParams().user_uuid ?? "";
        is_new = user_uuid == "new";
        // await load();
    });
    beforeUpdate(async () => {
        const params = UrlHelper.getUrlParams();
        const tmp_user_uuid = params.user_uuid ?? "new";
        if (user_uuid != tmp_user_uuid) {
            user_uuid = tmp_user_uuid;
            is_new = user_uuid == "new";
            await load();
        }
    });
    async function saveData(): Promise<void> {
        try {
            is_error = false;
            is_saving = true;
            error_msg = "";
            if (!validateData()) {
                return;
            }
            let user_uuid: string | null = user?.user_uuid;
            // clone roles
            const roles = role_membership.slice(0);
            // add room-related roles
            room_access.forEach((x) => {
                if (x.has_access) {
                    roles.push(x.role_name);
                }
            });
            if (is_new) {
                const res = await services.backend.admin().insertSingleUser({
                    active: user.active,
                    address: user.address,
                    discount_factor: user.discount_factor,
                    email: user.email,
                    gsm: user.gsm,
                    is_admin: user.is_admin,
                    is_vat: user.is_vat,
                    roles,
                    tax_id: user.tax_id,
                    title: user.title,
                    town: user.town,
                    username: user.username,
                    zip: user.zip,
                });
                user_uuid = res.uuid;
                new_user_uuid = res.uuid;
            } else {
                await services.backend.admin().updateSingleUser({
                    active: user.active,
                    address: user.address,
                    discount_factor: user.discount_factor,
                    email: user.email,
                    gsm: user.gsm,
                    is_admin: user.is_admin,
                    is_vat: user.is_vat,
                    roles,
                    status: user.status,
                    tax_id: user.tax_id,
                    title: user.title,
                    town: user.town,
                    user_uuid: user.user_uuid,
                    username: user.username,
                    zip: user.zip,
                });
            }
            if (user_uuid != null) {
                // store/remove special prices
                for (const ra of room_access) {
                    const target = parseInt(ra.special_price_id);
                    if (ra.has_access && target >= 0) {
                        await services.backend
                            .admin()
                            .addSingleUserSpecialPrice({
                                id_pricelist: target,
                                room_id: ra.room.id,
                                user_uuid,
                            });
                    } else {
                        await services.backend
                            .admin()
                            .removeSingleUserSpecialPrice({
                                room_id: ra.room.id,
                                user_uuid,
                            });
                    }
                }
            }
            is_success = true;
        } catch (err) {
            is_error = true;
            error_msg = err.message;
        }
        is_saving = false;
    }
    function validateData(): boolean {
        const reportError = (msg: string) => {
            error_msg = msg;
            is_error = true;
            return false;
        };
        user.username == (user.username ?? "").trim();
        user.title == (user.title ?? "").trim();
        user.address == (user.address ?? "").trim();
        user.town == (user.town ?? "").trim();
        user.zip == (user.zip ?? "").trim();
        user.email == (user.email ?? "").trim();
        user.tax_id == (user.tax_id ?? "").trim();
        user.is_vat == (user.is_vat === true ?? false);

        if (user.username.length == 0) {
            return reportError("Uporabniško ime ne sme biti prazno.");
        }
        if (user.username.indexOf(" ") >= 0) {
            return reportError("Uporabniško ime ne sme imeti presledkov.");
        }
        if (user.username != user.username.toLowerCase()) {
            return reportError("Uporabniško ime mora imeti samo male črke");
        }

        if (user.title.length == 0) {
            return reportError("Naziv ne sme biti prazen.");
        }
        const email_regex = /^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/gm;
        if (!email_regex.test(user.email)) {
            return reportError("Email ni v pravi obliki: " + user.email);
        }

        return true;
    }
    function toggleRole(role: string): void {
        if (role_membership.includes(role)) {
            role_membership = role_membership.filter((x) => x != role);
        } else {
            role_membership.push(role);
        }
    }
</script>

<div class="w3-container">
    <div class="w3-row">
        {#if is_success}
            <div class="w3-panel w3-pale-green w3-border">
                <h3>Podatki uspešno shranjeni</h3>
                <p>
                    <a href={UrlHelper.getOpenUserUrl(new_user_uuid)}
                        >Odpri podatke uporabnika</a
                    >
                </p>
            </div>
        {/if}
        {#if !is_success}
            {#if is_error}
                <div class="w3-panel w3-pale-yellow w3-border">
                    <h3>Opozorilo:</h3>
                    <p>{error_msg}</p>
                </div>
            {/if}
            <div class="w3-center">
                <Loader visible={!is_error && is_loading} />
            </div>
            <div class="w3-centered">
                <Loader visible={!is_error && is_saving} />
            </div>
            {#if (!is_error || is_new) && !is_loading && user != null}
                <div class="w3-container">
                    <h2>Uporabnik {is_new ? " (novi)" : user.username}</h2>
                </div>

                {#if !is_new}
                    <div class="w3-padding">
                        Status:
                        <span
                            class={"w3-tag w3-round " +
                                CssHelpers.getCssClassForUserStatus(
                                    user.status,
                                )}
                            >{user.status}
                        </span>
                    </div>
                {/if}

                <form class="w3-container">
                    {#if !is_new}
                        <div class="w3-panel">
                            <label for="inId">ID</label>
                            <input
                                id="inId"
                                disabled
                                class="w3-input w3-border w3-round"
                                type="text"
                                bind:value={user.user_uuid}
                            />
                        </div>

                        <div class="w3-panel">
                            <label for="inUsername">Uporabniško ime</label>
                            <input
                                id="inUsername"
                                disabled
                                class="w3-input w3-border w3-round"
                                type="text"
                                bind:value={user.username}
                            />
                        </div>
                    {/if}
                    {#if is_new}
                        <div class="w3-panel">
                            <label for="inUsername2">Uporabniško ime</label>
                            <input
                                id="inUsername2"
                                class="w3-input w3-border w3-round"
                                type="text"
                                bind:value={user.username}
                            />
                        </div>
                    {/if}

                    <div class="w3-panel">
                        <label for="inTitle">Naziv</label>
                        <input
                            id="inTitle"
                            class="w3-input w3-border w3-round"
                            type="text"
                            bind:value={user.title}
                        />
                    </div>

                    <div class="w3-panel">
                        <label for="inEmail">Email</label>
                        <input
                            id="inEmail"
                            class="w3-input w3-border w3-round"
                            type="email"
                            bind:value={user.email}
                        />
                    </div>

                    <div class="w3-panel">
                        <label for="inEmail">GSM</label>
                        <input
                            id="inEmail"
                            class="w3-input w3-border w3-round"
                            type="email"
                            bind:value={user.gsm}
                        />
                    </div>

                    <div class="w3-panel">
                        <label for="inTaxId">Davčna št.</label>
                        <input
                            id="inTaxId"
                            class="w3-input w3-border w3-round"
                            type="text"
                            bind:value={user.tax_id}
                        />
                    </div>

                    <div class="w3-panel">
                        <input
                            id="inIsVat"
                            class="w3-check"
                            type="checkbox"
                            bind:checked={user.is_vat}
                        />
                        <label for="inIsVat">Davčni zavezanec</label>
                    </div>

                    <div class="w3-panel">
                        <label for="inAddress">Ulica</label>
                        <input
                            id="inAddress"
                            class="w3-input w3-border w3-round"
                            type="text"
                            bind:value={user.address}
                        />
                    </div>

                    <div class="w3-panel">
                        <label for="inZip">Pošta</label>
                        <input
                            id="inZip"
                            class="w3-input w3-border w3-round"
                            type="text"
                            bind:value={user.zip}
                        />
                    </div>

                    <div class="w3-panel">
                        <label for="inTown">Mesto</label>
                        <input
                            id="inTown"
                            class="w3-input w3-border w3-round"
                            type="text"
                            bind:value={user.town}
                        />
                    </div>

                    <div class="w3-panel">
                        <label for="inDiscount">Faktor popusta</label>
                        <input
                            id="inDiscount"
                            class="w3-input w3-border w3-round"
                            type="number"
                            disabled={is_power}
                            bind:value={user.discount_factor}
                            min="0"
                            max="1"
                        />
                    </div>

                    <div class="w3-panel">
                        <input
                            id="inActive"
                            class="w3-check"
                            type="checkbox"
                            bind:checked={user.active}
                        />
                        <label for="inActive">Aktiven</label>
                    </div>
                    {#if is_admin}
                        <div class="w3-panel">
                            <input
                                id="inAdmin"
                                class="w3-check"
                                type="checkbox"
                                disabled
                                bind:checked={user.is_admin}
                            />
                            <label for="inAdmin">Admin</label>
                        </div>
                    {/if}

                    <h3>Dostop do pisarn</h3>

                    {#each room_access as ra}
                        <div class="w3-panel">
                            <input
                                id={"in" + ra.room.role_name}
                                class="w3-check"
                                type="checkbox"
                                bind:checked={ra.has_access}
                                on:click={() => {
                                    ra.has_access != ra.has_access;
                                }}
                            />
                            <label for={"in" + ra.room.role_name}
                                >{ra.room.role_name.replace("Room", "")}</label
                            >

                            <SpecialPriceChooser
                                {pricelists}
                                bind:selected_id={ra.special_price_id}
                            />
                        </div>
                    {/each}

                    {#if is_admin}
                        <h3>Ostalo</h3>

                        {#each roles_system as role}
                            <div class="w3-panel">
                                <input
                                    id={"in" + role}
                                    class="w3-check"
                                    type="checkbox"
                                    checked={role_membership.includes(role)}
                                    on:click={() => toggleRole(role)}
                                />
                                <label for={"in" + role}>{role}</label>
                            </div>
                        {/each}
                    {/if}
                </form>
                <div class="w3-panel w3-right-align">
                    <button
                        class="w3-btn w3-theme-action w3-round-xlarge"
                        on:click={() => saveData()}>Shrani</button
                    >
                </div>
            {/if}
        {/if}
    </div>
</div>
