<script lang="ts">
    import { onMount } from "svelte";
    import type { IServices } from "./code/services";
    import { UrlHelper } from "./code/utils";

    // import type {
    //     IGetPaymentsReq,
    //     IPaymentData,
    //     IUserBalanceInfo,
    // } from "./code/backend/admin.generated.types";
    // import { niceDate, niceNumber, NumberParser, UrlHelper } from "./code/utils";
    // import Loader from "./Loader.svelte";

    export let services: IServices;
    export const page_params: any = {};

    onMount(async () => {
        await load();
    });

    async function load(): Promise<void> {
    }

    async function downloadDemoInvoice(): Promise<void> {
        const { token } = await services.backend.admin().getDummyInvoice({});
        UrlHelper.openFileForToken(token);
    }
</script>

<div class="w3-container">
    <div class="w3-row">
        <button class="w3-button-link" on:click={() => downloadDemoInvoice()}>
            Prenesi demo obvestilo o računu
        </button>
    </div>
</div>
