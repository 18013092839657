<script lang="ts">
    import Users from "./Users.svelte";
    import Invoices from "./Invoices.svelte";
    import Payments from "./Payments.svelte";
    import LatestChanges from "./LatestChanges.svelte";
    import Balances from "./Balances.svelte";
    import Month from "./Month.svelte";
    import Search from "./Search.svelte";
    import SysHealth from "./SysHealth.svelte";

    import type { IServices } from "./code/services";
    import { UrlHelper } from "./code/utils";
    import UsersDeletionRatios from "./UsersDeletionRatios.svelte";
    import Registrations from "./Registrations.svelte";
    import MessagesAdmin from "./MessagesAdmin.svelte";
    import Year from "./Year.svelte";
    import Messages from "./Messages.svelte";
    import {
        is_admin as is_admin_store,
        is_power as is_power_store
    } from "./code/stores";

    export let services: IServices;
    export let page_params: any = {};

    let inner_params = page_params;

    let is_admin = $is_admin_store;
    let is_power = $is_power_store;
    is_admin_store.subscribe((val) => {
        is_admin = val;
    });
    is_power_store.subscribe((val) => {
        is_power = val;
    });

    type pageName =
        | "registrations"
        | "users"
        | "users-deletions"
        | "invoices"
        | "payments"
        | "month"
        | "year"
        | "last_changes"
        | "balances"
        | "messages-admin"
        | "sys-health"
        | "search";
    let selected_page: pageName =
        page_params.subpage ?? (is_admin ? "month" : "users");

    function setPage(page: pageName): void {
        selected_page = page;
        UrlHelper.openAdminSubpage(page);
    }
</script>

<div class="w3-row">
    <div class="w3-col s12 m3 l2">
        <div class="w3-panel">
            <h3>Administracija</h3>
        </div>
        <div class="w3-padding w3-hide-large w3-hide-medium">
            <select
                class="w3-select w3-border w3-round"
                bind:value={selected_page}
            >
                {#if is_admin}
                    <option value="invoices">Fakture</option>
                    <option value="balances">Stanja</option>
                {/if}
                <option value="last_changes">Zadnje spremembe</option>
                {#if is_admin}
                    <option value="month">Mesec</option>
                    <option value="year">Leto</option>
                    <option value="payments">Plačila</option>
                {/if}
                <option value="search">Iskanje</option>
                <option value="users">Uporabniki</option>
                {#if is_admin}
                    <!-- <option value="registrations">Registracije</option> -->
                    <option value="messages-admin">Sporočila</option>
                    <option value="users-deletions">Statistika brisanja</option>
                    <option value="sys-health">Stanje sistema</option>
                {/if}
            </select>
        </div>
        <div class="w3-hide-small">
            {#if is_admin}
                <button
                    class="w3-button w3-hover-theme {selected_page ===
                    'invoices'
                        ? 'w3-theme-d3'
                        : ''} width-100-perc"
                    on:click={() => setPage("invoices")}>Fakture</button
                >
                <button
                    class="w3-button w3-hover-theme {selected_page ===
                    'balances'
                        ? 'w3-theme-d3'
                        : ''} width-100-perc"
                    on:click={() => setPage("balances")}>Stanja</button
                >
            {/if}
            <button
                class="w3-button w3-hover-theme {selected_page ===
                'last_changes'
                    ? 'w3-theme-d3'
                    : ''} width-100-perc"
                on:click={() => setPage("last_changes")}
                >Zadnje spremembe</button
            >
            {#if is_admin}
                <button
                    class="w3-button w3-hover-theme {selected_page === 'month'
                        ? 'w3-theme-d3'
                        : ''} width-100-perc"
                    on:click={() => setPage("month")}>Mesec</button
                >
                <button
                    class="w3-button w3-hover-theme {selected_page === 'year'
                        ? 'w3-theme-d3'
                        : ''} width-100-perc"
                    on:click={() => setPage("year")}>Leto</button
                >
                <button
                    class="w3-button w3-hover-theme {selected_page ===
                    'payments'
                        ? 'w3-theme-d3'
                        : ''} width-100-perc"
                    on:click={() => setPage("payments")}>Plačila</button
                >
            {/if}
            <button
                class="w3-button w3-hover-theme {selected_page === 'search'
                    ? 'w3-theme-d3'
                    : ''} width-100-perc"
                on:click={() => setPage("search")}>Iskanje</button
            >
            <button
                class="w3-button w3-hover-theme {selected_page === 'users'
                    ? 'w3-theme-d3'
                    : ''} width-100-perc"
                on:click={() => setPage("users")}>Uporabniki</button
            >
            <!-- <button
                class="w3-button w3-hover-theme {selected_page === 'registrations'
                    ? 'w3-theme-d3'
                    : ''} width-100-perc"
                on:click={() => setPage("registrations")}>Registracije</button
            > -->
            {#if is_admin}
                <button
                    class="w3-button w3-hover-theme {selected_page ===
                    'messages-admin'
                        ? 'w3-theme-d3'
                        : ''} width-100-perc"
                    on:click={() => setPage("messages-admin")}>Sporočila</button
                >
                <button
                    class="w3-button w3-hover-theme {selected_page ===
                    'users-deletions'
                        ? 'w3-theme-d3'
                        : ''} width-100-perc"
                    on:click={() => setPage("users-deletions")}
                    >Statistika brisanja</button
                >
                <button
                    class="w3-button w3-hover-theme {selected_page ===
                    'sys-health'
                        ? 'w3-theme-d3'
                        : ''} width-100-perc"
                    on:click={() => setPage("sys-health")}
                    >Stanje sistema</button
                >
            {/if}
        </div>
    </div>

    <div class="w3-col s12 m9 l10 w3-padding-large">
        {#if selected_page == "invoices"}
            <Invoices {services} page_params={inner_params} />
        {/if}
        {#if selected_page == "month"}
            <Month {services} page_params={inner_params} />
        {/if}
        {#if selected_page == "year"}
            <Year {services} page_params={inner_params} />
        {/if}
        {#if selected_page == "balances"}
            <Balances {services} page_params={inner_params} />
        {/if}
        {#if selected_page == "last_changes"}
            <LatestChanges {services} page_params={inner_params} />
        {/if}
        {#if selected_page == "payments"}
            <Payments {services} page_params={inner_params} />
        {/if}
        {#if selected_page == "search"}
            <Search {services} page_params={inner_params} />
        {/if}
        {#if selected_page == "users"}
            <Users {services} page_params={inner_params} />
        {/if}
        {#if selected_page == "registrations"}
            <Registrations {services} page_params={inner_params} />
        {/if}
        {#if selected_page == "messages-admin"}
            <MessagesAdmin {services} page_params={inner_params} />
        {/if}
        {#if selected_page == "users-deletions"}
            <UsersDeletionRatios {services} page_params={inner_params} />
        {/if}
        {#if selected_page == "sys-health"}
            <SysHealth {services} page_params={inner_params} />
        {/if}
    </div>
</div>
